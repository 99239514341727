import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';
import { UserRoutePrefixContextProps } from '../Interfaces/UserRoutePrefixContextInterface';

const UserRoutePrefixContextUi = React.lazy(() => import('User/UserRoutePrefixContext').catch(errorHandling));

const UserRoutePrefixContext: React.FC<UserRoutePrefixContextProps> = ({ basePrefix, children }) => (
    <ErrorBoundary fallback={<div></div>}>
        <Suspense fallback={<div></div>}>
            <UserRoutePrefixContextUi basePrefix={basePrefix}>{children}</UserRoutePrefixContextUi>
        </Suspense>
    </ErrorBoundary>
);

export default UserRoutePrefixContext;
