import { Link } from 'react-router-dom';
import community from '@assets/HomeNotLogged/community.svg';
import arc from '@assets/HomeNotLogged/arc.svg';
import hope from '@assets/HomeNotLogged/hope.svg';
import { Card, CardNumber, CardsContainer, CardSmallText, CardText, IntroContainer, MainTitle } from './FiguresStyle';
import { useSharedTranslation } from 'Translation/useSharedTranslation';

const Figures = () => {
    const { t } = useSharedTranslation();
    const cardData = [
        {
            imgSrc: arc,
            imgAlt: 'Logo',
            number: '+250',
            text: 'base.menuNotLogged.emrysFigure.cardTitleOne',
            link: '/Nos-enseignes-partenaires',
        },
        {
            imgSrc: community,
            imgAlt: 'Logo',
            number: '+60 K',
            text: 'base.menuNotLogged.emrysFigure.cardTitleTwo',
            link: '/Avantages',
        },
        {
            imgSrc: hope,
            imgAlt: 'Logo',
            number: '+4 000',
            text: 'base.menuNotLogged.emrysFigure.cardTitleThree',
            smallText: 'base.menuNotLogged.emrysFigure.subCardTitleThree',
            link: '/associations',
        },
    ];
    return (
        <IntroContainer>
            <MainTitle>{t('base.menuNotLogged.emrysFigure.mainTitle')}</MainTitle>
            <CardsContainer>
                {cardData.map(({ imgSrc, imgAlt, number, text, link, smallText }, index) => (
                    <Card key={index}>
                        {!smallText ?
                            <Link to={link}>
                                <img
                                    src={imgSrc}
                                    alt={imgAlt}
                                    width='141'
                                    height='78'
                                />
                                <CardNumber>{number}</CardNumber>
                                <CardText>{t(text)}</CardText>
                            </Link>
                        :   <>
                                <Link to={link}>
                                    <img
                                        src={imgSrc}
                                        alt={imgAlt}
                                        width='141'
                                        height='78'
                                    />
                                    <CardNumber>{number}</CardNumber>
                                    <CardText>{t(text)}</CardText>
                                    {smallText && <CardSmallText>{t(smallText)}</CardSmallText>}
                                </Link>
                            </>
                        }
                    </Card>
                ))}
            </CardsContainer>
        </IntroContainer>
    );
};

export default Figures;
