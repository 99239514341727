import styled from 'styled-components';

export const ShopSubMenuContainer = styled.div`
    position: absolute !important;
    z-index: 1;
    visibility: hidden;
    width: 100%;
    min-height: 333px;
    transition:
        opacity 0.3s,
        visibility 0.3s;
    opacity: 0;
    padding: ${props => props.theme.globalSize.mobileSize2} 0;

    background-color: ${props => props.theme.colors.white};

    ${props => props.theme.devices.fullhdRealWidth} {
        padding-left: 5%;
        padding-right: 5%;
    }

    &.with-fading {
        visibility: visible;
        opacity: 1;
    }

    .tabs {
        ul {
            border-bottom-color: transparent;
            > li {
                box-sizing: border-box;
                ${props => props.theme.devices.fullhd} {
                    > a {
                        ${props => props.theme.devices.fullhdRealWidth} {
                            font-weight: 700;
                        }
                    }
                }
                ${props => props.theme.devices.desktopOnly} {
                    > a {
                        padding-right: 5px;
                        padding-left: 5px;
                    }
                }
            }
        }
    }
`;
