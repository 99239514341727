import React from 'react';
import giftcard from '@assets/Menu/giftcard.svg';
import cachebackimage from '@assets/Menu/cachebackimage.svg';
import licencesimage from '@assets/Menu/licencesimage.svg';
import goodiesimage from '@assets/Menu/goodiesimage.svg';
import formationimage from '@assets/Menu/formationimage.svg';
import marquesimage from '@assets/Menu/marquesimage.svg';
import licencesimagemenu from '@assets/Menu/licencesimagemenu.svg';
import formationimagemenu from '@assets/Menu/formationimagemenu.svg';
import associationsimage from '@assets/Menu/associationsimage.svg';
import bandeauemrysnutrition from '@assets/Menu/bandeauemrysnutrition.svg';
import unsplash from '@assets/Menu/unsplash.svg';
import EmrysEnergyCard from '@assets/HomepageEmrysBrands/Cards/EmrysEnergyCard.svg';
import EmrysMobileCard from '@assets/HomepageEmrysBrands/Cards/EmrysMobileCard.svg';
import EmrysCarsCard from '@assets/HomepageEmrysBrands/Cards/EmrysCarsCard.svg';
import EmrysNutritionCard from '@assets/HomepageEmrysBrands/Cards/EmrysNutritionCard.svg';
import EmrysPayCard from '@assets/HomepageEmrysBrands/Cards/EmrysPayCard.svg';
import EmrysEnergyLogo from '@assets/HomepageEmrysBrands/Logo/EmrysEnergyLogo.svg';
import EmrysMobileLogo from '@assets/HomepageEmrysBrands/Logo/EmrysMobileLogo.svg';
import EmrysCarsLogo from '@assets/HomepageEmrysBrands/Logo/EmrysCarsLogo.svg';
import EmrysNutritionLogo from '@assets/HomepageEmrysBrands/Logo/EmrysNutritionLogo.svg';
import EmrysPayLogo from '@assets/HomepageEmrysBrands/Logo/EmrysPayLogo.svg';

import PictoSAV from '@icons/PictoSAV.svg';
import PictoLivraisonphysique from '@icons/PictoLivraisonphysique.svg';
import PictoPaiementsécurisé from '@icons/PictoPaiementsécurisé.svg';
import PictoMobilePaiementsécurisé from '@icons/PictoMobilePaiementsécurisé.svg';
import PictoMobileLivraisonphysique from '@icons/PictoMobileLivraisonphysique.svg';
import casquemobile from '@icons/casquemobile.svg';

import PictoRetourcolis from '@icons/PictoRetourcolis.svg';

import { useRoutePrefix } from '../Context/RoutePrefixContext';
import WrappedGiftCardSubMenu from '../Components/Pages/Logged/WrappedGiftCardSubMenu';
import { BrandItem } from '../Interfaces/ConstantInterface';
import { useSharedTranslation } from 'Translation/useSharedTranslation';
import { productPrefix } from 'Products/ProductPrefix';
import { userPrefix } from 'User/UserPrefix';

export const useMenuParams = () => {
    const prefix = useRoutePrefix();
    const { t } = useSharedTranslation();

    const MENU_EMRYS_PARAMS = {
        title: 'Emrys La <br/> Carte',
        links: [
            {
                label: 'Boutique',
                to: `${prefix}${productPrefix}/shop`,
                arrow: true,
                subMenu: [
                    {
                        label: 'Cartes cadeaux',
                        image: giftcard,
                        text: 'Retrouver les meilleures offres du moment !',
                        subSubMenu: [
                            {
                                label: 'Catégories',
                                subsubSubMenu: [
                                    { name: 'Tout voir', to: `${prefix}${productPrefix}/shop` },
                                    React.createElement(WrappedGiftCardSubMenu, { basePrefix: prefix }),
                                ],
                            },
                            {
                                label: 'Pays',
                                subsubSubMenu: [
                                    { name: 'France', to: `${prefix}${productPrefix}/taxons/FR` },
                                    { name: 'Belgique', to: `${prefix}${productPrefix}/taxons/BE` },
                                ],
                            },
                        ],
                    },
                    {
                        label: 'Offres de cashback',
                        image: cachebackimage,
                        text: 'Retrouver les meilleures offres du moment !',
                        subSubMenu: [
                            {
                                label: 'Catégories',
                                subsubSubMenu: [
                                    { name: 'France', to: `${prefix}` },
                                    { name: 'Belgique', to: `${prefix}` },
                                ],
                            },
                        ],
                    },
                    {
                        label: 'Licences',
                        image: licencesimage,
                        text: 'Retrouver les meilleures offres du moment !',
                        subSubMenu: [
                            {
                                label: 'Catégories',
                                subsubSubMenu: [
                                    { name: 'Individuelles', to: `${prefix}` },
                                    { name: 'Packs', to: `${prefix}` },
                                ],
                            },
                        ],
                    },
                    {
                        label: 'Goodies & Produits Emrys',
                        image: goodiesimage,
                        text: 'Retrouver les meilleures offres du moment !',
                        subSubMenu: [
                            {
                                label: 'Catégories',
                                subsubSubMenu: [
                                    { name: 'Goodies Emrys', to: `${prefix}` },
                                    { name: 'Magazine', to: `${prefix}` },
                                ],
                            },
                        ],
                    },
                    {
                        label: 'Formations & Events',
                        image: formationimage,
                        text: 'Retrouver les meilleures offres du moment !',
                        subSubMenu: [
                            {
                                label: 'Thématiques',
                                subsubSubMenu: [
                                    { name: 'Les clés de la réussite', to: `${prefix}` },
                                    { name: 'Emrys Prime', to: `${prefix}` },
                                    { name: 'Events', to: `${prefix}` },
                                    { name: 'Livres', to: `${prefix}` },
                                ],
                            },
                        ],
                    },
                    {
                        label: 'Marques Emrys',
                        image: marquesimage,
                        text: 'Retrouver les meilleures offres du moment !',
                        subSubMenu: [
                            {
                                label: 'Catégories',
                                subsubSubMenu: [
                                    { name: 'Emrys Cars', to: `${prefix}/emrys-brands` },
                                    { name: 'Emrys Energie', to: `${prefix}/emrys-brands` },
                                    { name: 'Emrys Mobile', to: `${prefix}/emrys-brands` },
                                    { name: 'Emrys Nutrition', to: `${prefix}/emrys-brands/emrys-nutrition` },
                                    { name: 'Emrys Pay', to: `${prefix}/emrys-brands` },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                label: 'Licences',
                arrow: true,
                to: `${prefix}`,
                subMenu: [
                    {
                        label: '',
                        image: licencesimagemenu,
                        text: 'Retrouver les meilleures offres du moment !',
                        desc: 'Lorem ipsum dolor sit amet consectetur. Mauris amet et tristique viverra leo sit. Sed eu pretium est mi iaculis. At eu in sollicitudin ultricies pretium mi vulputate. Velit risus faucibus cursus proin tristique sed tristique mattis velit. Metus nulla malesuada.',
                        subSubMenu: [
                            {
                                label: 'Licences individuelles',
                                subsubSubMenu: [
                                    { name: 'Licence Acheteur Enchanté', to: `${prefix}` },
                                    { name: 'Licence Enchanteur', to: `${prefix}` },
                                ],
                            },
                            {
                                label: 'Packs de licences',
                                subsubSubMenu: [
                                    { name: 'Pack licences - Bronze', to: `${prefix}` },
                                    { name: 'Pack licences - Argent', to: `${prefix}` },
                                    { name: 'Pack licence - Or', to: `${prefix}` },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                label: 'Formations',
                arrow: true,
                to: `${prefix}`,
                subMenu: [
                    {
                        label: '',
                        image: formationimagemenu,
                        text: 'Retrouver les meilleures offres du moment !',
                        subSubMenu: [
                            {
                                label: 'Les formations',
                                subsubSubMenu: [
                                    { name: 'Les clés de la réussite', to: `${prefix}` },
                                    { name: 'Emrys Prime', to: `${prefix}` },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                label: 'Associations',
                arrow: true,
                to: `${prefix}`,
                subMenu: [
                    {
                        label: '',
                        image: associationsimage,
                        text: 'Retrouver les meilleures offres du moment !',
                        subSubMenu: [
                            {
                                label: 'Catégories',
                                subsubSubMenu: [
                                    { name: 'Emrys et les assos', to: `${prefix}` },
                                    { name: 'Soutenir une association', to: `${prefix}` },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                label: 'Documentation',
                arrow: false,
                to: `${prefix}`,
                subMenu: [],
            },
        ],
    };

    const MENU_BRANDS_PARAMS = {
        title: 'Marques Emrys',
        links: [
            { label: 'Emrys Cars', to: `${prefix}/emrys-brands`, arrow: false, subMenu: [] },
            {
                label: 'Emrys Energie',
                arrow: true,
                to: `${prefix}/emrys-brands`,
                subMenu: [
                    {
                        label: '',
                        image: bandeauemrysnutrition,
                        text: `Jusqu’à 15% d’économies sur l’année et ensemble, évitons les coupures d'électricité !`,
                        subSubMenu: [
                            {
                                label: 'Produits',
                                subsubSubMenu: [
                                    { name: 'Boitier Narco', to: `${prefix}/emrys-brands` },
                                    { name: 'Kit solaire', to: `${prefix}/emrys-brands` },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                label: 'Emrys Mobile',
                arrow: true,
                to: `${prefix}/emrys-brands/emrys-mobile`,
                subMenu: [
                    {
                        label: '',
                        image: unsplash,
                        text: `Votre forfait à partir de 10,99€/mois avec 10% de remise et sans engagement !`,
                        subSubMenu: [
                            {
                                label: 'Services',
                                subsubSubMenu: [
                                    { name: 'Mon abonnement', to: `${prefix}/emrys-brands` },
                                    { name: 'Souscription', to: `${prefix}/emrys-brands/emrys-mobile` },
                                ],
                            },
                        ],
                    },
                ],
            },

            { label: 'Emrys Nutrition', to: `${prefix}/emrys-brands/emrys-nutrition`, arrow: false, subMenu: [] },
            { label: 'Emrys Pay', to: `${prefix}/emrys-brands`, arrow: false, subMenu: [] },
        ],
    };

    const ACCOUNT_MENU_PARAMS = {
        title: 'Account Menu',
        links: [
            { label: 'Mon profil', to: `${prefix}/home/my-profile`, arrow: false, subMenu: [] },
            { label: 'Mes souscriptions', to: `${prefix}/home/my-subscriptions`, arrow: false, subMenu: [] },
            { label: 'Mes commandes', to: `${prefix}${userPrefix}/my-orders`, arrow: false, subMenu: [] },
            {
                label: 'Mes avantages',
                to: ``,
                arrow: true,
                subMenu: [
                    {
                        label: '',
                        image: '',
                        text: '',
                        subSubMenu: [
                            {
                                label: '',
                                subsubSubMenu: [
                                    { name: 'Mon Crédit d’Achat', to: `${prefix}/home/my-benefits/my-purchasecredit` },
                                    { name: 'Mes Emrys Coins', to: `${prefix}/home/my-benefits/my-emryscoins` },
                                    { name: 'Mon arbre de fidélité', to: `${prefix}/home/my-benefits/my-fidelity-tree` },
                                    { name: 'Mes primes carrières', to: `${prefix}/home/my-benefits/my-career-bonuses` },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                label: 'Mon équipe',
                to: `${prefix}`,
                arrow: true,
                subMenu: [
                    {
                        label: '',
                        image: '',
                        text: '',
                        subSubMenu: [
                            {
                                label: '',
                                subsubSubMenu: [
                                    { name: 'Mon stock de licences', to: `${prefix}/home/my-team/my-licences-stock` },
                                    { name: 'Mon réseau', to: `${prefix}/home/my-team/my-network` },
                                    { name: 'Parrainage', to: `${prefix}/home/my-team/sponsorship` },
                                    { name: 'Activité de mon équipe', to: `${prefix}/home/my-team/my-teams-activity` },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    };

    const BRANDATA: BrandItem[] = [
        {
            id: '1',
            name: 'Emrys Energie',
            image: EmrysEnergyCard,
            logo: EmrysEnergyLogo,
            padding: '86px 114px',
            path: `${prefix}/emrys-energy`,
        },
        { id: '2', name: 'Emrys Mobile', image: EmrysMobileCard, logo: EmrysMobileLogo, padding: '58px 48px', path: `${prefix}/emrys-mobile` },
        {
            id: '3',
            name: 'Emrys Cars',
            image: EmrysCarsCard,
            logo: EmrysCarsLogo,
            padding: '58px 48px',
            path: `${prefix}/emrys-cars`,
        },
        {
            id: '4',
            name: 'Emrys Nutrition',
            image: EmrysNutritionCard,
            logo: EmrysNutritionLogo,
            padding: '55px 93px 56px 92px',
            path: `${prefix}/emrys-brands/emrys-nutrition`,
        },
        {
            id: '5',
            name: 'Emrys Pay',
            image: EmrysPayCard,
            logo: EmrysPayLogo,
            padding: '70px 82.24px 70px 83px',
            path: `${prefix}/emrys-pay`,
        },
    ];

    const CARDSDATA = [
        {
            icon: PictoSAV,
            boldText: t('base.emrysmobile.cardsdatahelp'),
            text: t('base.emrysmobile.cardsdatahelpdesc'),
            clickable: false,
            link: '',
        },
        {
            icon: PictoPaiementsécurisé,
            boldText: t('base.emrysmobile.cardsdatapayment'),
            text: t('base.emrysmobile.cardsdatapaymentdesc'),
            clickable: false,
            link: '',
        },
        {
            icon: PictoLivraisonphysique,
            boldText: t('base.emrysmobile.cardsdatadelivery'),
            text: t('base.emrysmobile.cardsdatadeliverydesc'),
            clickable: false,
            link: '',
        },
        {
            icon: PictoRetourcolis,
            boldText: t('base.emrysmobile.cardsdatareturns'),
            text: t('base.emrysmobile.cardsdatareturnsdesc'),
            clickable: false,
            link: '',
        },
    ];

    const CARDSMOBILEDATA = [
        {
            icon: PictoMobilePaiementsécurisé,
            boldText: t('base.emrysmobile.cardsdatapayment'),
            text: t('base.emrysmobile.cardsdatapaymentdesc'),
            clickable: false,
            link: '',
        },
        {
            icon: PictoMobileLivraisonphysique,
            boldText: t('base.emrysmobile.cardsdatadelivery'),
            text: t('base.emrysmobile.cardsdatadeliverymobiledesc'),
            clickable: false,
            link: '',
        },
        {
            icon: casquemobile,
            boldText: t('base.emrysmobile.cardsdatahelp'),
            text: t('base.emrysmobile.cardsdatahelpdesc'),
            clickable: false,
            link: '',
        },
    ];
    return { MENU_EMRYS_PARAMS, MENU_BRANDS_PARAMS, ACCOUNT_MENU_PARAMS, BRANDATA, CARDSDATA, CARDSMOBILEDATA };
};
