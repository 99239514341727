import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';

const UserRoutesUi = React.lazy(() => import('User/UserRoutes').catch(errorHandling));

const UserRoutes = () => (
    <ErrorBoundary fallback={<div></div>}>
        <Suspense fallback={<div></div>}>
            <UserRoutesUi />
        </Suspense>
    </ErrorBoundary>
);

export default UserRoutes;
