import React, { useState } from 'react';
import eyeIcon from '@assets/icons/eyeIcon.svg';
import eyeIconClosed from '@assets/icons/eyeIconClosed.svg';
import iconError from '@assets/icons/iconError.svg';
import {
    BreadcrumbsContainer,
    ContainerLoggin,
    CustomBox,
    Title,
    CustomInput,
    ErrorMessage,
    CustomInputWrapper,
    EyeIcon,
    CustomLink,
} from './LoginStyle';

import { authService } from '../../../../Services/Services';
import { useRoutePrefix } from '../../../../Context/RoutePrefixContext';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Breadcrumbs from '../../../../LoadModule/Breadcrumbs';
import { useDeviceDetect } from '../../../../LoadModule/DeviceDetectLoader';
import { useSharedTranslation } from 'Translation/useSharedTranslation';
const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const { t } = useSharedTranslation();
    const prefix = useRoutePrefix();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { isMobile } = useDeviceDetect();

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleEmailChange = (e: { target: { value: React.SetStateAction<string> } }) => {
        setEmail(e.target.value);
        if (e.target.value === '') {
            setEmailError('');
        } else if (emailError && e.target.value) {
            setEmailError('');
        }
    };

    const handlePasswordChange = (e: { target: { value: React.SetStateAction<string> } }) => {
        setPassword(e.target.value);
        if (e.target.value === '') {
            setPasswordError('');
        } else if (passwordError && e.target.value) {
            setPasswordError('');
        }
    };

    const handleSubmit = () => {
        if (email.length === 0) {
            setEmailError(t('base.loginpage.emailempty'));
        } else if (!validateEmail(email)) {
            setEmailError(t('base.loginpage.loginemailerror'));
        } else if (password.length === 0) {
            setPasswordError(t('base.loginpage.passwordempty'));
        } else if (!validatePassword(password)) {
            setPasswordError(t('base.loginpage.passworderror'));
        } else {
            setIsLoading(true);

            authService.getToken(email, password).then(isAuthenticated => {
                setIsLoading(false);
                if (isAuthenticated) {
                    navigate(`${prefix}`);
                } else {
                    setPasswordError(t('base.loginpage.emailorpassworderror'));
                }
            });
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const validateEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const validatePassword = (password: string) => {
        return password.length > 0;
    };

    const breadcrumbItems = [
        { name: t('base.avantage.breadcrumbs.home'), url: '/' },
        { name: t('base.loginpage.login'), url: '/public/login' },
    ];
    const mobileBreadcrumbs = isMobile ? [breadcrumbItems[breadcrumbItems.length - 1]] : breadcrumbItems;

    return (
        <>
            <BreadcrumbsContainer className='is-flex is-justify-content-start'>
                <Breadcrumbs
                    items={mobileBreadcrumbs}
                    showEllipsis={false}
                    backText={isMobile ? undefined : t('base.avantage.breadcrumbs.return')}
                ></Breadcrumbs>
            </BreadcrumbsContainer>

            <ContainerLoggin>
                <CustomBox className='box'>
                    <Title className='mt-3-desktop'>{t('base.loginpage.login')}</Title>
                    <div>
                        <CustomInput
                            type='text'
                            placeholder={t('base.loginpage.email')}
                            value={email}
                            onChange={handleEmailChange}
                            onKeyDown={handleKeyDown}
                            $hasError={!!emailError || !!passwordError}
                        />
                        {emailError && (
                            <ErrorMessage>
                                <img
                                    src={iconError}
                                    alt='iconError'
                                />
                                <div>{emailError}</div>
                            </ErrorMessage>
                        )}
                    </div>
                    <div>
                        <CustomInputWrapper>
                            <CustomInput
                                type={passwordVisible ? 'text' : 'password'}
                                placeholder={t('base.loginpage.password')}
                                value={password}
                                onChange={handlePasswordChange}
                                onKeyDown={handleKeyDown}
                                $hasError={!!passwordError}
                            />
                            {password && (
                                <EyeIcon
                                    src={passwordVisible ? eyeIcon : eyeIconClosed}
                                    alt='toggle password visibility'
                                    onClick={togglePasswordVisibility}
                                />
                            )}
                        </CustomInputWrapper>
                        {passwordError && (
                            <ErrorMessage>
                                <img
                                    src={iconError}
                                    alt='iconError'
                                />
                                <div> {passwordError}</div>
                            </ErrorMessage>
                        )}
                    </div>
                    <button
                        type='submit'
                        onClick={handleSubmit}
                        disabled={!email || !password}
                        className={classNames('button is-primary', { 'is-loading': isLoading })}
                    >
                        {t('base.loginpage.connection')}
                    </button>
                    <CustomLink to='forgot-password'>{t('base.loginpage.forgotpassword')}</CustomLink>
                </CustomBox>
            </ContainerLoggin>
        </>
    );
};

export default Login;
