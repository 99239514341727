import styled from 'styled-components';

export const PageTitle = styled.div`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size2};
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: ${props => props.theme.colors.primary};
    border: 2px solid ${props => props.theme.colors.primary};

    margin: 0px 30px 0px 30px;
    border-radius: 10px;
    ${props => props.theme.devices.mobile} {
        text-align: center;
        font-size: ${props => props.theme.globalSize.mobileSize2};
    }
`;

export const Container = styled.div``;
