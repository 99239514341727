import styled from 'styled-components';

export const Container = styled.div`
    overflow-x: hidden;
    position: sticky;

    ${props => props.theme.devices.mobile} {
        max-width: 100vw;
    }
`;
