import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';
import { ButtonProps } from '../Interfaces/ButtonInterface';

const ButtonUi = React.lazy(() => import('Ui/Buttons').catch(errorHandling));

const Button: React.FC<ButtonProps> = props => (
    <ErrorBoundary fallback={<div></div>}>
        <Suspense fallback={<div></div>}>
            <ButtonUi {...props} />
        </Suspense>
    </ErrorBoundary>
);

export default Button;
