import Consommateur from '@assets/Advantages/Consommateur.svg';
import Coins from '@assets/Advantages/Coins.svg';
import {
    BigContainer,
    BreadcrumbsContainer,
    MiddleContainer,
    Header,
    Box,
    Title,
    Article,
    FirstImage,
    InnerContainer,
    Subtitle,
    Content,
    ButtonContainer,
    ButtonLink,
    Container,
    SideTitle,
    ThirdContainer,
    SecondImageContainer,
    SecondImage,
    PrimaryBand,
    InnerText,
} from './AdvantagesStyle.tsx';
import Breadcrumbs from '../../../../LoadModule/Breadcrumbs.tsx';
import { useSharedTranslation } from 'Translation/useSharedTranslation';
import { useDeviceDetect } from '../../../../LoadModule/DeviceDetectLoader.tsx';

const Advantages = () => {
    const { t } = useSharedTranslation();
    const { isMobile } = useDeviceDetect();

    const breadcrumbItems = [
        { name: t('base.avantage.breadcrumbs.home'), url: '/' },
        { name: t('base.avantage.page'), url: '/Avantages' },
    ];

    const flexCenterColumnClass = 'is-flex is-align-items-center is-flex-direction-column';
    const youtubeLink = 'https://youtu.be/KBKXJk1-vUA?si=iJvCC1HEK9OHTC7a';
    const mobileBreadcrumbs = isMobile ? [breadcrumbItems[breadcrumbItems.length - 1]] : breadcrumbItems;

    return (
        <BigContainer className={flexCenterColumnClass}>
            <BreadcrumbsContainer className='is-flex is-justify-content-start'>
                <Breadcrumbs
                    items={mobileBreadcrumbs}
                    showEllipsis={false}
                    backText={isMobile ? undefined : t('base.avantage.breadcrumbs.return')}
                ></Breadcrumbs>
            </BreadcrumbsContainer>

            <MiddleContainer>
                <Header className='is-flex is-align-items-start'>{t('base.avantage.page')}</Header>
                <Box className={`box ${flexCenterColumnClass}`}>
                    <Title>{t('base.avantage.firstbox.title')}</Title>
                    <Article className='media'>
                        <div className='media-left'>
                            <FirstImage className='image'>
                                <img
                                    src={Consommateur}
                                    alt='Image'
                                />
                            </FirstImage>
                        </div>
                        <div className='media-content'>
                            <div className='content'>
                                <InnerContainer>
                                    <Subtitle className='block'>{t('base.avantage.firstbox.subtitle')}</Subtitle>
                                    <Content className='block'>{t('base.avantage.firstbox.firstcontext')}</Content>
                                    <Content className='block'>{t('base.avantage.firstbox.secondcontext')}</Content>
                                </InnerContainer>
                                <ButtonContainer>
                                    <ButtonLink
                                        href={youtubeLink}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        {t('base.avantage.button')}
                                    </ButtonLink>
                                </ButtonContainer>
                            </div>
                        </div>
                    </Article>
                </Box>

                <Container className='is-flex is-align-items-center is-justify-content-center'>
                    <Box className={`box ${flexCenterColumnClass}`}>
                        <div className='media-content'>
                            <div className='content'>
                                <div>
                                    <Subtitle
                                        className='block'
                                        id='second-subtitle'
                                    >
                                        {t('base.avantage.secondbox.subtitle')}
                                    </Subtitle>

                                    <Content className='block'>{t('base.avantage.secondbox.firstcontext')}</Content>
                                    {isMobile ?
                                        <>
                                            <Content className='block'>{t('base.avantage.secondbox.secondcontext')}</Content>
                                            <Content className='block'>
                                                {t('base.avantage.secondbox.thirdcontext')} <b>{t('base.avantage.secondbox.bold.secondcontext')}</b>
                                            </Content>
                                        </>
                                    :   <Content className='block'>
                                            {t('base.avantage.secondbox.secondcontext')} {t('base.avantage.secondbox.thirdcontext')}{' '}
                                            <b>{t('base.avantage.secondbox.bold.secondcontext')}</b>
                                        </Content>
                                    }
                                </div>
                            </div>
                        </div>
                    </Box>
                    <SideTitle>{t('base.avantage.bigsidetitle')}</SideTitle>
                </Container>

                <ThirdContainer className='is-flex is-justify-content-start'>
                    <SecondImageContainer className='media-left'>
                        <SecondImage className='image'>
                            <img
                                src={Coins}
                                alt='Image'
                            />
                        </SecondImage>
                    </SecondImageContainer>
                    <PrimaryBand>
                        <InnerText className='is-flex is-justify-content-center is-flex-direction-column'>
                            {t('base.avantage.primaryband')}{' '}
                        </InnerText>
                    </PrimaryBand>
                </ThirdContainer>
            </MiddleContainer>
        </BigContainer>
    );
};

export default Advantages;
