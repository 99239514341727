import Mobile from '@assets/HomepageEmrysBrands/Mobile.svg';

import {
    BannerContainer,
    Container,
    FirstImage,
    Box,
    Subtitle,
    Content,
    SecondContainer,
    SecondContainerTitle,
    ImageCards,
    Logo,
} from './HomepageEmrysBrandsStyle.tsx';
import { useBanners } from '../../../../Hooks/Banners.ts';
import { useMenuParams } from '../../../../Hooks/Constant.ts';
import Banner from '../../../../LoadModule/Banner.tsx';
import Loader from '../../../../LoadModule/Loader.tsx';
import { IncomingBanner } from '../../../../Interfaces/BannerInterface.tsx';
import { useSharedTranslation } from 'Translation/useSharedTranslation';

const HomepageEmrysBrands = () => {
    const { BRANDATA } = useMenuParams();
    const { banners, isLoading: bannerIsLoading } = useBanners('brand');

    const displayBanner = () => {
        if (bannerIsLoading) {
            return <Loader />;
        }

        return (
            <div>
                <Banner imageData={banners as unknown as IncomingBanner[]} />
            </div>
        );
    };

    const { t } = useSharedTranslation();

    return (
        <>
            <div>{displayBanner()}</div>
            <BannerContainer>
                <Container className='is-flex is-align-items-center is-justify-content-center'>
                    <div>
                        <FirstImage className='image'>
                            <img
                                src={Mobile}
                                alt='Image'
                            />
                        </FirstImage>
                    </div>
                    <Box className='box'>
                        <div className='media-content'>
                            <div className='content'>
                                <div>
                                    <Subtitle
                                        className='block'
                                        id='second-subtitle'
                                    >
                                        {t('base.homepageEmrysBrands.box.title')}
                                    </Subtitle>

                                    <Content className='block'>{t('base.homepageEmrysBrands.box.context')}</Content>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Container>
                <SecondContainer>
                    <SecondContainerTitle>{t('base.homepageEmrysBrands.subtitle')}</SecondContainerTitle>
                    {BRANDATA.map(brand => (
                        <ImageCards
                            to={brand.path || '/'}
                            key={brand.id}
                        >
                            <img
                                src={brand.image}
                                alt={brand.name}
                                className='images'
                            />
                            <Logo
                                src={brand.logo}
                                alt={brand.name}
                                className='logo'
                                $padding={brand.padding}
                            />
                            <div className='overlay'></div>
                        </ImageCards>
                    ))}
                </SecondContainer>
            </BannerContainer>
        </>
    );
};

export default HomepageEmrysBrands;
