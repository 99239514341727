import Cooperative from '../Cooperative/Cooperative.tsx';
import React from 'react';
import { useBanners } from '../../../../Hooks/Banners.ts';
import { useDeviceDetect } from '../../../../LoadModule/DeviceDetectLoader.tsx';
import { useMenuParams } from '../../../../Hooks/Constant.ts';
import { Guarantee } from '../Guarantee/Guarantee.tsx';
import CarouselsLoader from '../../../../LoadModule/CarouselsLoader.tsx';
import Banner from '../../../../LoadModule/Banner.tsx';
import Loader from '../../../../LoadModule/Loader.tsx';
import { IncomingBanner } from '../../../../Interfaces/BannerInterface.tsx';

const HomepageEmrysLaCarte: React.FC = () => {
    const { banners, isLoading: bannerIsLoading } = useBanners('home');
    const { CARDSDATA } = useMenuParams();
    const { isMobile } = useDeviceDetect();
    const displayBanner = () => {
        if (bannerIsLoading) {
            return <Loader />;
        }

        if (!Array.isArray(banners)) {
            return <div>No banners available.</div>;
        }

        return (
            <div className='banner'>
                <Banner imageData={banners as unknown as IncomingBanner[]} />
            </div>
        );
    };

    return (
        <div>
            {displayBanner()}
            <CarouselsLoader />
            <Cooperative isMobile={isMobile} />
            <Guarantee object={CARDSDATA} />
        </div>
    );
};

export default HomepageEmrysLaCarte;
