import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';
import { CartPreviewProps } from '../Interfaces/CartPreviewInterface';

const CartPreviewUi = React.lazy(() => import('Cart/CartPreview').catch(errorHandling));

const CartPreview: React.FC<CartPreviewProps> = ({ backUrlShop, backUrlCart }) => {
    return (
        <ErrorBoundary fallback={<div></div>}>
            <Suspense fallback={<div></div>}>
                <CartPreviewUi
                    backUrlShop={backUrlShop}
                    backUrlCart={backUrlCart}
                />
            </Suspense>
        </ErrorBoundary>
    );
};

export default CartPreview;
