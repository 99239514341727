import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { ImageType, TitleProps } from './FooterInterface';

export const FooterContainer = styled.footer<{ $position: string }>`
    padding: 3rem 0.5rem 0.7rem 1.5rem !important;
    position: ${({ $position }) => $position}!important;
    left: 0;
    bottom: 0;
    width: 100%;
    top: 120px;
    ${props => props.theme.devices.mobile} {
        padding: 2rem !important;
    }
`;

export const Container = styled.div`
    margin: 10px;
    padding: 0px;
    max-width: 14% !important;
    ${props => props.theme.devices.mobile} {
        border-bottom: 2px solid ${props => props.theme.colors.secondary};
        width: 100% !important;
        max-width: 90% !important;
    }
    ${props => props.theme.devices.tablet} {
        border-bottom: 2px solid ${props => props.theme.colors.secondary};
        width: 100% !important;
        max-width: 90% !important;
    }
`;

export const CollapsibleContent = styled.div<{ $isOpen: boolean }>`
    overflow: hidden;
    transition:
        max-height 0.9s ease-in-out,
        opacity 0.9s ease-in-out;
    max-height: ${({ $isOpen }) => ($isOpen ? '500px' : '0')};
    opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
`;

export const ContainerLogo = styled.div`
    margin: -10px;
    padding: 0px;
    height: 250px;
    max-width: 12% !important;
    ${props => props.theme.devices.mobile} {
        flex-direction: row !important;
        max-width: 100% !important;
        margin: 0px;
        gap: ${props => props.theme.globalSize.size4};
        height: auto;
    }
    ${props => props.theme.devices.tablet} {
        flex-direction: row !important;
        max-width: 100% !important;
        margin: 0px;
        gap: ${props => props.theme.globalSize.size4};
        height: auto;
    }
`;

export const MiddleContainer = styled.div`
    margin: 0px;
    gap: 1rem;
    padding: 5px;
    width: 100% !important;
    ${props => props.theme.devices.mobile} {
        flex-direction: row !important;
        margin: 0 0 10px -8px;
        width: 100% !important;
    }
    ${props => props.theme.devices.tablet} {
        flex-direction: row !important;
        margin: 0 0 10px -8px;
        width: 100% !important;
    }
`;

export const ListContainer = styled.div`
    ${props => props.theme.devices.mobile} {
        padding: 0px 10px 5px 10px;
    }
    ${props => props.theme.devices.tablet} {
        padding: 0px 10px 5px 10px;
    }
`;

export const LinkItem = styled(Link)`
    text-decoration: none;
    margin-bottom: 0.6rem;
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    &:hover {
        color: ${props => props.theme.colors.white};
        text-decoration: underline;
    }
`;

export const InnerSocialContainer = styled.div<{ $isLoggedIn: boolean }>`
    gap: 0.9rem;
    margin-left: -20px;
    padding: 0px;
    width: ${({ $isLoggedIn }) => ($isLoggedIn ? '100%' : '190px')} !important;
    ${props => props.theme.devices.mobile} {
        justify-content: start !important;
        margin-left: 0px;
        max-width: 100% !important;
    }
    ${props => props.theme.devices.tablet} {
        justify-content: start !important;
        margin-left: 0px;
        max-width: 100% !important;
    }
`;

export const InnerPaymentContainer = styled.div`
    padding: 0px;
    width: 100% !important;
    max-width: 200px !important;
    cursor: default !important;
    ${props => props.theme.devices.mobile} {
        justify-content: start !important;
        margin-left: 10px;
        max-width: 100% !important;
    }
    ${props => props.theme.devices.tablet} {
        justify-content: start !important;
        margin-left: 10px;
        max-width: 100% !important;
    }
`;

export const Title = styled.div<TitleProps>`
    margin-bottom: ${props => props.theme.globalSize.size4} !important;
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size5} !important;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.08px;
    ${props => props.theme.devices.mobile} {
        font-family: ${props => props.theme.fonts.familyPrimary};
        font-size: ${props => props.theme.globalSize.size6} !important;
        padding: ${props =>
            props.$isSocialMedia ? '2px 0px'
            : props.$isAppStore ? '2px 0px'
            : '12px 0px'} !important;
    }
    ${props => props.theme.devices.tablet} {
        font-family: ${props => props.theme.fonts.familyPrimary};
        padding: ${props =>
            props.$isSocialMedia ? '2px 0px'
            : props.$isAppStore ? '2px 0px'
            : '12px 0px'} !important;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${props => props.theme.devices.mobile} {
        padding: 0px 10px;
        cursor: pointer;
    }
    ${props => props.theme.devices.tablet} {
        padding: 0px 10px;
        cursor: pointer;
    }
`;

export const Arrow = styled.div<{ $isOpen: boolean }>`
    display: none;
    cursor: pointer;
    width: 8px;
    margin-bottom: ${props => props.theme.globalSize.size4};
    height: 8px;
    border: solid ${props => props.theme.colors.white};
    border-width: 0 2px 2px 0;
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(-135deg)' : 'rotate(45deg)')};

    ${props => props.theme.devices.mobile} {
        display: inline-block;
    }
    ${props => props.theme.devices.tablet} {
        display: inline-block;
    }
`;

export const StyledImage = styled.img<{ type: ImageType }>`
    ${({ type }) => {
        switch (type) {
            case 'payment':
                return css`
                    width: 45px;
                    height: 28.421px;
                    border-radius: 5px;
                    margin: 0 10px 10px 0;
                    cursor: default !important;
                    background: white;
                    padding: 2px;
                `;
            case 'socialMedia':
                return css`
                    width: 2.4rem;
                    height: 2.4rem;
                `;
            case 'appStores':
                return css`
                    width: 135px;
                    height: 40px;
                `;
            default:
                return;
        }
    }}
`;
