import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
    flex-direction: column;
    display: flex;
    gap: 30px;
    padding-top: 20px;
`;

export const CardContent = styled.div<{ $isgapvalue: boolean }>`
    gap: ${props => (props.$isgapvalue ? '46px' : '1%')}!important;
    ${props => props.theme.devices.mobile} {
        gap: 0 !important;
        row-gap: 50px !important;
    }
    div {
        gap: 10px;

        ${props => props.theme.devices.mobile} {
            display: grid;
            justify-content: center;
            padding: 1px;
        }
    }
`;

export const TitleWrapper = styled.div`
    width: 100%;
    text-align: left;
    padding-left: 42px;
`;

export const TitleText = styled.div`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size3};
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-align: left;
    color: ${props => props.theme.colors.primary};
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.size4};
    }
`;

export const TitleCard = styled.div<{ $isprimary: boolean }>`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size3};
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    color: ${props => (props.$isprimary ? props.theme.colors.GreyDark : props.theme.colors.primary)};
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.size7};
        line-height: 80%;
    }
`;

export const TextDecrip = styled.div`
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.size6};
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-align: center;
    overflow: hidden;
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.mobileSize6};
        font-weight: 400;
        line-height: 16.8px;
        letter-spacing: 0.06px;
        white-space: pre-line;
    }
`;

export const ImgIcon = styled.img`
    ${props => props.theme.devices.mobile} {
        width: 15px;
        height: 15px;
    }
`;

export const CardContainer = styled.div`
    padding: 0.9rem;
    ${props => props.theme.devices.desktop} {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        box-shadow: 0px 6px 17px 0px #4d4d4d4d;
        width: 325px;
        height: 178px;
        padding: 30px;
        border-radius: 9px;
        justify-content: center;
        &:hover {
            box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
        }
    }

    ${props => props.theme.devices.mobile} {
        width: 45%;
        max-width: 100%;
        margin: auto;
        box-sizing: border-box;
    }
`;

export const StyledLink = styled(Link)`
    color: ${props => props.theme.colors.GreyDark};
    text-decoration: none;

    &:hover {
        color: ${props => props.theme.colors.GreyDark};
    }
`;
