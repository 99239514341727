import { useQuery } from '@tanstack/react-query';
import { bannerService } from '../Services/Services';

export const useBanners = (location: string) => {
    const { data: banners, isLoading } = useQuery({
        queryKey: ['banners', location],
        queryFn: async () => await bannerService.getAllBanners(location),
        select: data => data,
        staleTime: 60000,
    });

    return { banners, isLoading };
};
