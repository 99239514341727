import Axios, { AxiosInstance, AxiosError } from 'axios';
import setupAxiosInterceptors from '../Services/axiosInterceptors';

type SessionToken = {
    customer: string;
    token: string;
    expiredAt: string;
};

class ApiClient {
    private client: AxiosInstance;

    protected createAxiosClient() {
        const headers = {
            'Content-type': 'application/json',
            Authorization: '',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Max-Age': 600,
            Accept: 'application/json',
        };

        const tokenStorage = sessionStorage.getItem('token');

        if (tokenStorage) {
            const sessionToken = JSON.parse(tokenStorage) as SessionToken;
            headers['Authorization'] = `Bearer ${sessionToken.token}`;
        }

        return Axios.create({
            baseURL: `${import.meta.env.VITE_API_BASE_URL}shop`,
            responseType: 'json',
            headers: headers,
        });
    }

    constructor() {
        this.client = this.createAxiosClient();
        this.client.interceptors.request.use(config => {
            const tokenStorage = sessionStorage.getItem('token');
            if (tokenStorage) {
                const sessionToken = JSON.parse(tokenStorage) as SessionToken;
                config.headers['Authorization'] = `Bearer ${sessionToken.token}`;
            }
            return config;
        });

        setupAxiosInterceptors(this.client);
    }

    async get(path: string, params: object = {}) {
        try {
            const response = await this.client.get(path, params);
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async post(path: string, params: object = {}) {
        try {
            const result = await this.client.post(path, params);
            if (result instanceof AxiosError) {
                return result?.response?.data;
            }

            return result.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async put(path: string, params: object = {}) {
        try {
            const result = await this.client.put(path, params);
            if (result instanceof AxiosError) {
                return result?.response?.data;
            }

            return result.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async delete(path: string) {
        try {
            const response = await this.client.delete(path);
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async patch(path: string, params: object = {}) {
        try {
            const response = await this.client.patch(path, params);
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
}

export default new ApiClient();
