import { RoutePrefixProvider } from '../../../Context/RoutePrefixContext';
import GiftCardSubMenuLoader from '../../../LoadModule/GiftCardSubMenuLoader';

const WrappedGiftCardSubMenu = ({ basePrefix }: { basePrefix: string }) => {
    return (
        <RoutePrefixProvider>
            <GiftCardSubMenuLoader basePrefix={basePrefix} />
        </RoutePrefixProvider>
    );
};

export default WrappedGiftCardSubMenu;
