import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LogoProps } from '../../../../Interfaces/HomepageEmrysBrandsStyleInterface';

export const BannerContainer = styled.div`
    width: 100%;
    overflow: hidden;
    position: relative;
    bottom: 30px;

    ${props => props.theme.devices.mobile} {
        top: 110px;
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13%;
    flex-direction: row !important;
    margin: 0% 5% 5% 5%;
    padding-top: 8%;
    ${props => props.theme.devices.mobile} {
        flex-direction: column !important;
        margin: 0;
    }
    ${props => props.theme.devices.tablet} {
        flex-direction: column !important;
        margin: 0;
    }
`;

export const FirstImage = styled.figure`
    width: 634px;
    height: 428px;
    display: flex;
    flex-shrink: 0;
    ${props => props.theme.devices.mobile} {
        width: auto;
        height: auto;
    }
    ${props => props.theme.devices.tablet} {
        width: auto;
        height: auto;
    }
`;

export const Box = styled.div`
    display: flex;
    align-items: center;
    width: 456px;
    height: 328px;
    background: ${props => props.theme.colors.primary}!important;
    gap: 25px;
    border-radius: 7.5px;
    box-shadow: 0px 6px 17px 0px rgba(77, 77, 77, 0.3) !important;
    padding: 28.5px 38px 53.5px 38px;
    ${props => props.theme.devices.mobile} {
        padding: 28.5px 14px 44.5px 13px;
        width: 382px;
        height: 323px;
    }
    ${props => props.theme.devices.tablet} {
        padding: 28.5px 14px 44.5px 13px;
    }
`;

export const Subtitle = styled.div`
    color: ${props => props.theme.colors.white};
    text-align: center;
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size2};
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.size3};
        padding: 3% 20% 3% 20%;
    }
    ${props => props.theme.devices.tablet} {
        font-size: ${props => props.theme.globalSize.size3};
    }
`;

export const Content = styled.div`
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.size5};
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.size6};
        line-height: 24px;
        padding: 0px 17px 0px 17px;
    }
    ${props => props.theme.devices.tablet} {
        font-size: ${props => props.theme.globalSize.size6};
    }
`;

export const SecondContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row !important;
    margin-bottom: 5%;
    ${props => props.theme.devices.mobile} {
        flex-direction: column !important;
        margin: 5% 2%;
    }
    ${props => props.theme.devices.tablet} {
        flex-direction: column !important;
        margin: 5% 2%;
    }
`;

export const SecondContainerTitle = styled.div`
    color: ${props => props.theme.colors.primary};
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size3};
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 100%;
    padding: 10px 30px;
    margin: 0 35px;
    ${props => props.theme.devices.mobile} {
        margin: 0;
    }
    ${props => props.theme.devices.tablet} {
        margin: 0;
    }
`;

export const Logo = styled.img.attrs<LogoProps>(props => ({
    style: {
        padding: props.$padding,
    },
}))<LogoProps>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: none;
    opacity: 1;
    z-index: 2;
`;

export const ImageCards = styled(Link)`
    position: relative;
    margin: 4%;
    width: 25%;
    height: auto;
    overflow: hidden;
    border-radius: 7.5px;
    ${props => props.theme.devices.mobile} {
        width: auto;
    }
    ${props => props.theme.devices.tablet} {
        width: auto;
    }
    .images {
        width: 100%;
        height: 100%;
        display: block;
        transition: transform 0.3s ease;
        object-fit: cover;
        border-radius: 7.5px;
    }
    .overlay {
        position: absolute;
        top: 0;
        cursor: pointer;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(87, 87, 86, 0.55);
        transition: opacity 0.3s ease;
        opacity: 1;
        z-index: 1;
        border-radius: 7.5px;
    }

    &:hover .overlay {
        opacity: 0;
    }

    &:hover img {
        transform: scale(1.1);
    }

    &:hover .logo {
        transform: translate(-50%, -50%) scale(1.1);
    }
`;

export const SlidingBar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    .bar {
        height: 4px;
        background-color: grey;
        margin: 0 5px;
        transition: background-color 0.3s ease;
    }

    .active {
        background-color: cyan;
    }
`;
