import React, { createContext } from 'react';
import CartRoutePrefixContext from '../LoadModule/CartRoutePrefixContext';
import { RoutePrefixProviderCartProps } from '../Interfaces/RoutePrefixContextCartInterface';

const RoutePrefixContext = createContext<string | undefined>(undefined);

export const RoutePrefixProviderCart: React.FC<RoutePrefixProviderCartProps> = ({ children }) => {
    const prefix = import.meta.env.VITE_BASE_LOGGED_ROUTE || '';

    return (
        <RoutePrefixContext.Provider value={prefix}>
            <CartRoutePrefixContext basePrefix={prefix}>{children}</CartRoutePrefixContext>
        </RoutePrefixContext.Provider>
    );
};
