import React from 'react';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';

const CookieConsentUi = React.lazy(() => import('Ui/CookieConsent').catch(errorHandling));

const CookieConsent = () => (
    <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<div></div>}>
            <CookieConsentUi />
        </Suspense>
    </ErrorBoundary>
);

export default CookieConsent;
