import ApiClient from '../Api/ApiClient';

interface Banner {
    id: string;
    mediaUrl: {
        [key in MediaUrlKeys]: string;
    };
    target: '_blank' | '_self';
    url?: string | null;
}

type MediaUrlKeys = 'sylius_large' | 'sylius_medium' | 'sylius_small' | 'sylius_shop_banner_desktop' | 'sylius_shop_banner_mobile';

export default class BannerService {
    private apiClient = ApiClient;
    private prefixApi = 'banners';

    async getAllBanners(location: string): Promise<Banner[]> {
        const params = {
            params: {
                locationName: location,
            },
        };

        return this.apiClient.get(`/${this.prefixApi}`, params);
    }
}
