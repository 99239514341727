import React from 'react';
import GuidlineDesktop from '../Layout/GuidLine/GuidlineDesktop.tsx';
import GuidLineMobile from '../Layout/GuidLine/GuidLineMobile.tsx';
import classNames from 'classnames';
import { GuidlineStyled, HelpStar } from './GuidlineStyle.tsx';
import { useDeviceDetect } from '../../../../LoadModule/DeviceDetectLoader.tsx';
import { useSharedTranslation } from 'Translation/useSharedTranslation';

const Guidline: React.FC = () => {
    const { t } = useSharedTranslation();
    const { isMobile } = useDeviceDetect();

    return (
        <GuidlineStyled>
            <section id='home-explain'>
                <div className='columns'>
                    <div className={classNames('column', { 'pl-6 pr-4': !isMobile })}>
                        <h2 className={'is-uppercase'}>{t('base.menuNotLogged.emrysGuidline.mainTitle')}</h2>
                        {isMobile ?
                            <GuidLineMobile />
                        :   <GuidlineDesktop />}
                        <HelpStar>*Points de fidélité</HelpStar>
                    </div>
                </div>
            </section>
        </GuidlineStyled>
    );
};

export default Guidline;
