import React from 'react';
import { Outlet } from 'react-router-dom';
import { useDeviceDetect } from '../LoadModule/DeviceDetectLoader.tsx';
import Footer from '../Components/Pages/Footer/Footer.tsx';
import NavbarNotLoggedmobile from '../Components/Pages/Unlogged/NavbarNotLogged/NavbarNotLoggedMobile.tsx';
import NavBarNotLogged from '../Components/Pages/Unlogged/NavbarNotLogged/NavbarNotLogged.tsx';

const UnloggedRoute = (): React.ReactElement => {
    const { isMobile } = useDeviceDetect();

    const displayNavBar = () => (isMobile ? <NavbarNotLoggedmobile /> : <NavBarNotLogged />);

    return (
        <>
            {displayNavBar()} <Outlet /> <Footer />
        </>
    );
};

export default UnloggedRoute;
