import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';

const LazyAssociationMenu = React.lazy(() => import('Products/AssociationMenu').catch(errorHandling));

const AssociationMenuLoader = () => (
    <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<div></div>}>
            <LazyAssociationMenu />
        </Suspense>
    </ErrorBoundary>
);

export default AssociationMenuLoader;
