import React, { useEffect } from 'react';
import sponsorscard from '@assets/HomeNotLogged/cardsponsor.svg';
import sponsorscardmobile from '@assets/HomeNotLogged/sponsoredcardmobile.png';
import scrollButton from '@assets/HomeNotLogged/scrollButton.svg';
import classNames from 'classnames';
import {
    ContentSection,
    ImageSection,
    Image,
    Text,
    IntroContainer,
    ScrollButton,
    TextContainer,
    Title,
    TitleContainer,
    WhiteFade,
} from './EmrysIntroStyle.tsx';
import { ScrollButtonSvgProps } from '../../../../Interfaces/EmrysIntroInterface.tsx';
import { useSharedTranslation } from 'Translation/useSharedTranslation';
import { useDeviceDetect } from '../../../../LoadModule/DeviceDetectLoader.tsx';

const ScrollButtonSvg = ({ icon, onClick }: ScrollButtonSvgProps) => {
    return (
        <ScrollButton onClick={onClick}>
            <img
                src={icon}
                alt='Scroll Button'
            />
        </ScrollButton>
    );
};

const EmrysIntro: React.FC = () => {
    const { t } = useSharedTranslation();
    const { isMobile } = useDeviceDetect();

    const scrollToEmrysIntro = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
    };

    const login = () => {
        const url = new URL(`${import.meta.env.VITE_API_EMRYS_PROD}/authorize`);
        url.searchParams.append('response_type', 'code');
        url.searchParams.append('client_id', import.meta.env.VITE_CLIENT_ID);
        url.searchParams.append('scope', import.meta.env.VITE_SCOPE);

        window.location.href = url.href;
    };

    const textKeys = [
        'base.menuNotLogged.emrysInto.emrysCard',
        'base.menuNotLogged.emrysInto.cooperative',
        'base.menuNotLogged.emrysInto.membership',
    ];

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                login();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <IntroContainer>
            <ContentSection>
                <TitleContainer>
                    <Title>{t('base.menuNotLogged.emrysInto.title')}</Title>
                </TitleContainer>
                <TextContainer>
                    {textKeys.map((tkey, index) => (
                        <Text key={index}>{t(tkey)}</Text>
                    ))}
                    <button
                        onClick={login}
                        className={classNames('has-text-white button is-primary custom-button')}
                    >
                        {t('base.menuNotLogged.emrysInto.login')}
                    </button>
                </TextContainer>
            </ContentSection>
            <ImageSection>
                <Image
                    src={isMobile ? sponsorscardmobile : sponsorscard}
                    alt={!isMobile ? sponsorscard : sponsorscardmobile}
                />
                {!isMobile ?
                    <WhiteFade />
                :   <></>}
            </ImageSection>
            <ScrollButtonSvg
                onClick={scrollToEmrysIntro}
                icon={scrollButton}
            />
        </IntroContainer>
    );
};

export default EmrysIntro;
