import styled, { css } from 'styled-components';

export const BigContainer = styled.div`
    gap: 64px;
    margin-top: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${props => props.theme.devices.mobile} {
        gap: 0;
    }
    ${props => props.theme.devices.tablet} {
        gap: 0;
    }
`;

export const baseTextStyles = css`
    color: ${props => props.theme.colors.primary};
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size2};
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
`;

export const Header = styled.div`
    width: 100%;
    padding: 7px 0px 7px 40px;
    margin-bottom: 5%;
    gap: ${props => props.theme.globalSize.size1};
    border-radius: 10px;
    border: 2px solid ${props => props.theme.colors.primary};
    ${baseTextStyles};
    ${props => props.theme.devices.mobile} {
        padding: 7px 0;
        margin: 32px 0;
        justify-content: center;
        font-size: ${props => props.theme.globalSize.size4};
    }
    ${props => props.theme.devices.tablet} {
        padding: 7px 0;
        margin: 32px 0;
        justify-content: center;
        font-size: ${props => props.theme.globalSize.size4};
    }
`;
export const Box = styled.div`
    display: flex;
    align-item: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 25px;
    border-radius: 7px;
    background: ${props => props.theme.colors.white};
    box-shadow: 0px 6px 17px 0px rgba(77, 77, 77, 0.3) !important;
    padding: 3% 3% 1.7% 3%;
    ${props => props.theme.devices.mobile} {
        gap: 55px;
        padding: 24px 14px;
    }
    ${props => props.theme.devices.tablet} {
        gap: 55px;
        padding: 24px 14px;
    }
`;

export const MiddleContainer = styled.figure`
    width: 90%;
    gap: 32px;
`;

export const FirstImage = styled.figure`
    width: 110%;
    height: 318px;
    display: flex;
    flex-shrink: 0;
`;

export const Title = styled.div`
    ${baseTextStyles};
    text-align: center;
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.size3};
        width: 100%;
        padding-top: 10px;
    }
    ${props => props.theme.devices.tablet} {
        font-size: ${props => props.theme.globalSize.size3};
        width: 100%;
        padding-top: 10px;
    }
`;

export const Subtitle = styled.div`
    color: ${props => props.theme.colors.primary};
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size3};
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    &#second-subtitle {
        font-size: 20px;
    }
`;

export const Content = styled.div`
    width: 100%;
    color: ${props => props.theme.colors.greyDark};
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.size6};
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
`;
export const SideTitle = styled.div`
    width: 23%;
    height: auto;
    flex-shrink: 0;
    ${baseTextStyles};
    ${props => props.theme.devices.mobile} {
        width: 100%;
        padding: 33px;
    }
    ${props => props.theme.devices.tablet} {
        width: 100%;
        padding: 33px;
    }
`;
export const Container = styled.div`
    gap: 8%;
    flex-direction: row !important;
    ${props => props.theme.devices.mobile} {
        flex-direction: column !important;
    }
    ${props => props.theme.devices.tablet} {
        flex-direction: column !important;
    }
`;
export const ThirdContainer = styled.div`
    width: 100%;
    height: 10%;
    position: relative;
    ${props => props.theme.devices.mobile} {
        flex-direction: column;
        align-items: start;
        top: 30px;
    }
    ${props => props.theme.devices.tablet} {
        flex-direction: column;
        align-items: start;
        top: 30px;
    }
`;

export const PrimaryBand = styled.div`
    width: 89%;
    height: 161px;
    flex-shrink: 0;
    padding: 0 60px 0 199px;
    border-radius: 10px;
    background: ${props => props.theme.colors.primary};
    position: absolute;
    top: 155px;
    left: 200px;
    z-index: 0;
    ${props => props.theme.devices.mobile} {
        width: 100%;
        z-index: 0;
        position: relative;
        top: 20%;
        left: 0;
        padding: 40px 47px 51px 47px;
        height: 247px;
        margin-bottom: 35vh;
    }
    ${props => props.theme.devices.tablet} {
        width: 100%;
        z-index: 0;
        position: relative;
        top: 20%;
        left: 0;
        padding: 40px 47px 51px 47px;
        height: 247px;
        margin-bottom: 35vh;
    }
`;
export const InnerText = styled.div`
    width: 100%;
    height: 161px;
    flex-shrink: 0;
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size3};
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.size4};
        text-align: center;
    }
    ${props => props.theme.devices.tablet} {
        font-size: ${props => props.theme.globalSize.size4};
        text-align: center;
    }
`;
export const SecondImage = styled.figure`
    width: 399px;
    height: 467px;
    flex-shrink: 0;
    z-index: 10;
    ${props => props.theme.devices.mobile} {
        width: 222.129px;
        height: 339px;
        flex-shrink: 0;
        z-index: 1;
        position: absolute;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 127px;
        left: -35px;
    }
    ${props => props.theme.devices.tablet} {
        width: 222.129px;
        height: 339px;
        flex-shrink: 0;
        z-index: 1;
        position: absolute;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 127px;
        left: -35px;
    }
`;
export const Article = styled.article`
    width: 100%;
    align-items: center;
    ${props => props.theme.devices.mobile} {
        flex-direction: column;
        gap: 42px;
    }
    ${props => props.theme.devices.tablet} {
        flex-direction: column;
        gap: 42px;
    }
`;
export const InnerContainer = styled.div`
    ${props => props.theme.devices.mobile} {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    ${props => props.theme.devices.tablet} {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
`;
export const ButtonContainer = styled.div`
    ${props => props.theme.devices.mobile} {
        align-self: center;
        padding-top: 35px;
    }
    ${props => props.theme.devices.tablet} {
        align-self: center;
        padding-top: 35px;
    }
`;
export const SecondImageContainer = styled.div`
    ${props => props.theme.devices.mobile} {
        position: absolute;
        top: 10%;
    }
    ${props => props.theme.devices.tablet} {
        position: absolute;
        top: 10%;
    }
`;

export const BreadcrumbsContainer = styled.div`
    width: 90%;
`;

export const ButtonLink = styled.a`
    margin-top: 55px;
    display: inline-flex;
    padding: 8px 24px;
    border-radius: 6px;
    background: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.size7};
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.07px;
    text-decoration-line: underline;
    &:hover {
        color: ${props => props.theme.colors.white};
        background: #9e71fe;
    }
    ${props => props.theme.devices.mobile} {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 13px 24px;
        margin: 40px 20% 0 20%;
    }
    ${props => props.theme.devices.tablet} {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 13px 24px;
        margin: 40px 20% 0 20%;
    }
`;
