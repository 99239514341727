import { Routes, Route } from 'react-router-dom';
import LoggedRoute from './LoggedRoute/LoggedRoute.tsx';
import HomepageEmrysLaCarte from '../Components/Pages/Logged/HomepageEmrysLaCarte/HomepageEmrysLaCarte.tsx';
import MyTeams from '../Components/Pages/Logged/MyTeam/MyTeams.tsx';
import Sponsorship from '../Components/Pages/Logged/Sponsorship/Sponsorship.tsx';
import Associations from '../Components/Pages/Logged/Associations/Associations.tsx';
import SupportingAssociation from '../Components/Pages/Logged/SupportingAssociation/SupportingAssociation.tsx';
import Account from '../Components/Pages/Logged/Account/Account.tsx';
import MyProfile from '../Components/Pages/Logged/MyProfile/MyProfile.tsx';
import BecomeShareHolder from '../Components/Pages/Logged/BecomeShareHolder/BecomeShareHolder.tsx';
import EmrysCars from '../Components/Pages/Logged/EmrysCars/EmrysCars.tsx';
import EmrysNutrition from '../Components/Pages/Logged/EmrysNutrition/EmrysNutrition.tsx';
import EmrysPay from '../Components/Pages/Logged/EmrysPay/EmrysPay.tsx';
import MyEmrysCoins from '../Components/Pages/Logged/MyEmrysCoins/MyEmrysCoins.tsx';
import MyCareerBonuses from '../Components/Pages/Logged/MyCareerBonuses/MyCareerBonuses.tsx';
import MyNetwork from '../Components/Pages/Logged/MyNetwork/MyNetwork.tsx';
import MyTeamsActivity from '../Components/Pages/Logged/MyTeamsActivity/MyTeamsActivity.tsx';
import MySubscriptions from '../Components/Pages/Logged/MySubscriptions/MySubscriptions.tsx';
import MyPurchaseCredit from '../Components/Pages/Logged/MyPurchaseCredit/MyPurchaseCredit.tsx';
import MyFidelityTree from '../Components/Pages/Logged/MyFidelityTree/MyFidelityTree.tsx';
import MyLicenseStock from '../Components/Pages/Logged/MyLicenseStock/MyLicenseStock.tsx';
import React from 'react';

import EmrysMobile from '../Components/Pages/Logged/EmrysMobile/EmrysMobile.tsx';
import HomepageEmrysBrands from '../Components/Pages/Logged/HomepageEmrysBrands/HomepageEmrysBrands.tsx';
import ProductRouterLoader from '../LoadModule/ProductRouterLoader.tsx';
import CartStateProviderLoader from '../LoadModule/CartStateProviderLoader.tsx';
import CartRouterLoader from '../LoadModule/CartRouterLoader.tsx';
import UserRoutes from '../LoadModule/UserRoutes.tsx';
import { productPrefix } from 'Products/ProductPrefix';
import { cartPrefix } from 'Cart/CartPrefix';
import { userPrefix } from 'User/UserPrefix';

const LoggedRouter: React.FC = () => {
    return (
        <>
            <CartStateProviderLoader>
                <Routes>
                    <Route
                        path='/'
                        element={<LoggedRoute />}
                    >
                        <Route
                            path='/'
                            element={<HomepageEmrysLaCarte />}
                        />
                        <Route
                            path={'/emrys-brands/emrys-mobile'}
                            element={<EmrysMobile />}
                        />
                        <Route
                            path='/emrys-brands'
                            element={<HomepageEmrysBrands />}
                        >
                            <Route
                                path={'emrys-cars'}
                                element={<EmrysCars />}
                            />
                            <Route
                                path='emrys-nutrition'
                                element={<EmrysNutrition />}
                            />
                            <Route
                                path={'emrys-pay'}
                                element={<EmrysPay />}
                            />
                        </Route>
                        <Route
                            path={`/${productPrefix}/*`}
                            element={<ProductRouterLoader />}
                        />

                        <Route
                            path={`${cartPrefix}/*`}
                            element={<CartRouterLoader />}
                        />

                        <Route
                            path={`${userPrefix}/*`}
                            element={<UserRoutes />}
                        />

                        <Route
                            path='/myteams'
                            element={<MyTeams />}
                        >
                            <Route
                                path='sponsorship'
                                element={<Sponsorship />}
                            />
                            <Route
                                path='supportingassociation'
                                element={<SupportingAssociation />}
                            />
                        </Route>

                        <Route
                            path='/account'
                            element={<Account />}
                        >
                            <Route
                                path='myprofile'
                                element={<MyProfile />}
                            >
                                <Route
                                    path='becomeshareholder'
                                    element={<BecomeShareHolder />}
                                />
                            </Route>
                        </Route>

                        <Route
                            path='/associations'
                            element={<Associations />}
                        />

                        <Route path='/home'>
                            <Route
                                path='my-profile'
                                element={<MyProfile />}
                            />

                            <Route
                                path='my-subscriptions'
                                element={<MySubscriptions />}
                            />

                            <Route path='my-benefits'>
                                <Route
                                    path='my-purchasecredit'
                                    element={<MyPurchaseCredit />}
                                />
                                <Route
                                    path='my-emryscoins'
                                    element={<MyEmrysCoins />}
                                />
                                <Route
                                    path='my-fidelity-tree'
                                    element={<MyFidelityTree />}
                                />
                                <Route
                                    path='my-career-bonuses'
                                    element={<MyCareerBonuses />}
                                />
                            </Route>

                            <Route path='my-team'>
                                <Route
                                    path='my-licences-stock'
                                    element={<MyLicenseStock />}
                                />
                                <Route
                                    path='my-network'
                                    element={<MyNetwork />}
                                />
                                <Route
                                    path='sponsorship'
                                    element={<Sponsorship />}
                                />
                                <Route
                                    path='my-teams-activity'
                                    element={<MyTeamsActivity />}
                                />
                            </Route>
                        </Route>
                    </Route>
                </Routes>
            </CartStateProviderLoader>
        </>
    );
};

export default LoggedRouter;
