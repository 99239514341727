import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import { Suspense } from 'react';
import UnloggedRouter from './UnloggedRouter.tsx';
import LoggedRouter from './LoggedRouter.tsx';
import { useRoutePrefix } from '../Context/RoutePrefixContext.tsx';

export default function Router() {
    const prefix = useRoutePrefix();

    return (
        <ErrorBoundary fallback={<></>}>
            <Suspense fallback={<></>}>
                <Routes>
                    <Route
                        path='/*'
                        element={<UnloggedRouter />}
                    />
                    <Route
                        path={`${prefix}/*`}
                        element={<LoggedRouter />}
                    />
                </Routes>
            </Suspense>
        </ErrorBoundary>
    );
}
