import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const NavbarWrapper = styled.div`
    box-shadow: 0px 4px 14px 0px #57575659;
    position: relative;
    z-index: 2;
`;

export const MenuContainer = styled.div`
    padding-top: 0px;
    padding-bottom: 15px;
    position: relative;
    z-index: 2;
`;

export const NavBarBrandStyled = styled.div`
    justify-content: space-around;
    padding: 1%;
    ${props => props.theme.devices.desktop} {
        img.navbar-brand-logo {
            max-height: 52px !important;
            padding-right: 140px;
            padding-left: 65px;
        }
    }
`;

export const NavbarStyled = styled.nav`
    z-index: 3;
    display: flex;
    align-items: center; 
    justify-content: space-between; 
    padding-left: 6rem;
    padding-right: 6rem; 
    width: 100%;
        display: flex;
        justify-content: center; 
        flex-grow: 1; 
        .navbar-start,
        .navbar-end {
            display: flex;
            justify-content: space-around;
            flex: 2; 
            #cart-icon-container.navbar-item {
                cursor: pointer;
                span {
                    padding-left: 15px;
                }
            }
            .navbar-item {
                font-family: ${props => props.theme.fonts.familySecondary}, sans-serif;
                font-size: ${props => props.theme.globalSize.size5};
                font-weight: 700;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
            }
        }
    }
`;

export const SubNavbarStyled = styled.div`
    z-index: 1;
    display: flex;
    justify-content: center;
    height: 242px;
    background-color: white;
    position: fixed;
    width: 100%;
    margin-top: 5px;
    padding-top: 30px;
    padding-bottom: 60px;
`;

export const Overlay = styled.div`
    position: fixed;
    top: 332px;
    left: 0;
    width: 100%;
    height: calc(100% - 332px);
    background: #12121280;
    z-index: 5;
`;
export const StyledLink = styled(Link)`
    color: #575756;
    text-decoration: none;
`;

export const SubCategoryText = styled.div`
    font-family: ${props => props.theme.fonts.familySecondary}, sans-serif;
    font-size: ${props => props.theme.globalSize.size5};
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 24px;
`;
export const SubNavBarCategory = styled.div`
    align-items: center;
    flex: row;
    width: 20%;
`;
export const SubNavBarCategorySecond = styled.div`
    align-items: center;
    flex: row;
    width: 20%;
    .img {
        width: '271px',
        height: '133px' 
    }
`;
export const SubNavText = styled.div`
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.size6};
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: ${props => props.theme.colors.greyDark};
    width: 271px;
`;
export const LinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    height: 50px;
`;

export const NavBarEndStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
`;

export const NavBarMenuStyled = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    margin: 0 20px;
`;

export const NavbarBrandStyled = styled.div`
    justify-content: space-between;
    .icon {
        width: 32px;
        height: 32px;
        margin-top: 16px;
        i {
            font-size: 32px;
        }
    }

    .navbar-burger {
        margin-left: 0;
    }
    img.navbar-brand-logo {
        max-height: 40px;
        width: 45px;
        height: 36px;
    }
`;
export const HeaderText = styled.div`
    font-family: ${props => props.theme.fonts.familySecondary}, sans-serif;
    font-size: ${props => props.theme.globalSize.mobileSize2};
    font-weight: 700;
    line-height: 23.4px;
    letter-spacing: 0;
    text-align: left;
    padding: 20px;
`;

export const NavMenu = styled.div`
    position: fixed;
    top: 0;
    left: -85%;
    display: block;
    width: 85%;
    height: 100%;
    padding: 0;
    transition: left 0.2s;
    background-color: ${props => props.theme.colors.white};
    box-shadow: none;
    img {
        fill: ${props => props.theme.colors.primary};
        width: '20px';
        height: '20px';
    }
    &.is-active {
        left: 0;
    }
    .has-shadow {
        box-shadow: -5px 6px 10px 0px rgba(87, 87, 86, 0.2);
    }
    .header {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 64px;
        margin-bottom: 10px;
        background-color: ${props => props.theme.colors.white};

        &.is-sticky {
            position: fixed;
            z-index: 100;
            top: 0;
            width: 85%;
            transition: opacity 0.3s;
        }

        .icon {
            font-size: 24px;
            margin-top: 20px;
            margin-bottom: 20px;
            margin-left: 20px;
        }
        .icon-close {
            font-size: 24px;
            margin: 20px;
            color: ${props => props.theme.colors.primary};

            a {
                color: ${props => props.theme.colors.primary};
            }
        }
    }

    .body {
        position: relative;
        overflow-y: scroll;
        height: 100%;
        margin-top: 64px;
    }

    .has-underlined {
        box-shadow: none;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #d3d3d3;
            bottom: -4px;
            left: 0;
        }
    }
`;

export const Category = styled.div`
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.mobileSize5};
    font-weight: 700;
    line-height: ${props => props.theme.globalSize.mobileSize3};
    letter-spacing: 0em;
    text-align: left;
    margin-top: 25px;
`;
export const SubCategory = styled.div`
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: 12px;
    font-weight: 400;
    line-height: ${props => props.theme.globalSize.mobileSize5};
    letter-spacing: 0em;
    text-align: left;
    padding-top: 10px;
`;
export const NavItem = styled.div`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.mobileSize3};
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 10px;
`;

export const StyledLinkMobile = styled(Link)`
    color: ${props => props.theme.colors.greyDark};

    subCategory {
        font-family: ${props => props.theme.fonts.familyPrimary};
        font-size: 12px;
        font-weight: 400;
        line-height: ${props => props.theme.globalSize.mobileSize5};
        letter-spacing: 0em;
        text-align: left;
    }
    &:hover {
        color: ${props => props.theme.colors.greyDark};
    }
`;

export const StyledLinkModified = styled(StyledLinkMobile)`
    box-shadow: none;
    position: relative;
    color: ${props => props.theme.colors.greyDark};

    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #03f4fc;
        bottom: -4px;
        left: 0;
    }
`;

export const MenuIcon = styled.img`
    width: 16px;
    height: 12px;
    margin-top: 10px;
`;

export const IconWrapper = styled.span`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
`;
