import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ContainerLoggin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13%;
    flex-direction: row !important;
    margin: 0% 5% 5% 5%;
    padding-top: 5%;
    ${props => props.theme.devices.mobile} {
        flex-direction: column !important;
        margin: 0;
    }
    ${props => props.theme.devices.tablet} {
        flex-direction: column !important;
        margin: 0;
    }
`;

export const CustomBox = styled.div`
    width: 515px;
    height: max-content;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    position: relative;

    ${props => props.theme.devices.mobile} {
        width: 382px;
        height: max-content;
        padding: 32px 16px 32px 16px;
        gap: 40px;
        border-radius: 7.5px;
    }

    img {
        margin-top: 30px;
        ${props => props.theme.devices.mobile} {
            margin-top: -10px;
        }
    }

    div {
        gap: 30px;

        img {
            width: 69px;
            height: 32px;
            border-radius: 3px;
            border: 1px;
            cursor: pointer;

            &:hover {
                box-shadow: 0px 6px 17px 0px ${props => props.theme.colors.greyDark};
            }
        }
    }

    button {
        width: 348px;
        height: 40px;
        padding: 8px 24px 8px 24px;
        border: 0px;
        background: ${props => props.theme.colors.primary};

        font-family: ${props => props.theme.fonts.familyPrimary};
        font-size: ${props => props.theme.globalSize.size7};
        font-weight: 500;
        line-height: 18.2px;
        text-align: center;
        color: ${props => props.theme.colors.white};
        margin-top: 30px;
        cursor: pointer;
        border-radius: 6px;
        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            background: ${props => props.theme.colors.primarylight};
        }
        ${props => props.theme.devices.mobile} {
            margin-top: -10px;
        }
    }
`;

export const Title = styled.div`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size2};
    font-weight: 700;
    line-height: 36.46px;
    text-align: left;
    color: ${props => props.theme.colors.primary};

    text-transform: uppercase;
    margin-bottom: 30px;
    ${props => props.theme.devices.mobile} {
        margin-bottom: -2px;
        font-size: ${props => props.theme.globalSize.size3};
        line-height: 33px;
    }
`;

export const CustomInputWrapper = styled.div`
    position: relative;
    margin-top: 30px;
    ${props => props.theme.devices.mobile} {
        margin-top: -10px;
    }
`;

export const CustomInput = styled.input<{ $hasError: boolean }>`
    width: 348px;
    height: 40px;
    padding: 10px 20px;
    border-radius: 7.5px;

    border: 1px solid ${props => (props.$hasError ? props.theme.colors.danger : props.theme.colors.greyLight)};
    font-size: ${props => props.theme.globalSize.size6};
    &::placeholder {
        font-family: ${props => props.theme.fonts.familyPrimary};
        font-size: ${props => props.theme.globalSize.size6};
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: ${props => props.theme.colors.greyLight};
    }
    &:focus {
        box-shadow: 0px 0px 0px 2px #0063f740;
        outline: 1px solid #0063f7;
    }
    ${props => props.theme.devices.mobile} {
        width: 350px;
        height: 35px;
        padding: 10px 12px 10px 12px;
        border-radius: 4.5px;
        font-size: ${props => props.theme.globalSize.mobileSize6};
        border: 1px solid ${props => (props.$hasError ? props.theme.colors.danger : props.theme.colors.greyLight)};
        &::placeholder {
            font-size: ${props => props.theme.globalSize.size7};
            font-weight: 400;
            line-height: 18.2px;
        }
    }
`;

export const EyeIcon = styled.img`
    position: absolute;
    width: 16px !important;
    margin-top: 5px !important;
    right: 18px;
    &:hover {
        box-shadow: unset !important;
    }

    ${props => props.theme.devices.mobile} {
        margin-top: 0px !important;
    }
`;

export const CustomLink = styled(Link)`
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.mobileSize6};
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.005em;
    text-align: center;
    color: ${props => props.theme.colors.secondary};

    margin-top: 40px;
    &:hover {
        color: ${props => props.theme.colors.secondary};
        text-decoration: underline;
    }

    ${props => props.theme.devices.mobile} {
        margin-top: 0px;
    }
`;

export const ErrorMessage = styled.div`
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.mobileSize6};
    font-style: italic;
    font-weight: 400;
    line-height: 14.06px;
    text-align: left;
    color: ${props => props.theme.colors.danger};
    display: flex;
    align-items: flex-end;
    gap: 10px !important;
    img {
        width: 14px !important;
        height: 14px !important;
        margin-top: 10px !important;
    }
`;

export const BreadcrumbsContainer = styled.div`
    padding: 50px 0px 0px 80px;
    ${props => props.theme.devices.mobile} {
        padding: 30px 0px 30px 40px !important;
    }
`;
