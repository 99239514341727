import { Routes, Route } from 'react-router-dom';
import PublicRouter from './PublicRouter.tsx';
import UnloggedRoute from './UnloggedRoute.tsx';
import HomePageNotLogged from '../Components/Pages/Unlogged/HomePageNotLogged/HomePageNotLogged.tsx';
import { Suspense } from 'react';
import Login from '../Components/Pages/Unlogged/login/Login.tsx';

export default function UnloggedRouter() {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route
                    path={'/'}
                    element={<UnloggedRoute />}
                >
                    <Route
                        path={'/'}
                        element={<HomePageNotLogged />}
                    />
                    <Route
                        path={'/login'}
                        element={<Login />}
                    />
                    <Route
                        path='/public/*'
                        element={<PublicRouter />}
                    />
                </Route>
            </Routes>
        </Suspense>
    );
}
