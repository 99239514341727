import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../../../../Services/Services.ts';
import classNames from 'classnames';
import { useRoutePrefix } from '../../../../Context/RoutePrefixContext.tsx';
import {
    NavbarWrapper,
    MenuContainer,
    NavbarStyled,
    NavBarBrandStyled,
    StyledLink,
    NavBarMenuStyled,
    NavBarEndStyled,
    SubNavbarStyled,
    SubNavBarCategory,
    SubCategoryText,
    LinksContainer,
    SubNavBarCategorySecond,
    SubNavText,
    Overlay,
} from './NavbarNotLoggedStyle.tsx';

function NavbarNotLogged() {
    const [showSubNavbar, setShowSubNavbar] = useState(false);
    const prefix = useRoutePrefix();

    const handleMouseEnter = (navItem: string) => {
        navItem === 'Associations' ? setShowSubNavbar(true) : setShowSubNavbar(false);
    };
    const handleMouseLeave = () => setShowSubNavbar(false);
    const handleClick = () => setShowSubNavbar(!showSubNavbar);

    const navigate = useNavigate();

    const login = () => {
        if (authService.isLogged()) {
            navigate(`${prefix}`);
        }

        navigate(`public/login`);
    };

    return (
        <>
            <NavbarWrapper>
                <MenuContainer
                    id='shop-menu-container'
                    className={'is-primary '}
                >
                    <NavbarStyled
                        className='navbar pl-6'
                        role='navigation'
                        aria-label='main navigation'
                    >
                        <NavBarBrandStyled className={'navbar-brand'}>
                            <StyledLink
                                to={'/'}
                                className={'navbar-item'}
                            >
                                <img
                                    className='navbar-brand-logo'
                                    src='/logo.svg'
                                    alt={'logo_emrys'}
                                />
                            </StyledLink>
                        </NavBarBrandStyled>
                        <NavBarMenuStyled>
                            <div className='navbar-menu '>
                                <div className='navbar-start '>
                                    <StyledLink
                                        to={'/'}
                                        className={'navbar-item is-tab '}
                                        onMouseEnter={() => handleMouseEnter('Accueil')}
                                    >
                                        Accueil
                                    </StyledLink>

                                    <StyledLink
                                        to={'/public/Nos-enseignes-partenaires'}
                                        className='navbar-item heading-4 is-tab'
                                        onMouseEnter={() => handleMouseEnter('Nos enseignes partenaires')}
                                    >
                                        Nos enseignes partenaires
                                    </StyledLink>

                                    <StyledLink
                                        to={'/public/Avantages'}
                                        className='navbar-item heading-4 is-tab'
                                        onMouseEnter={() => handleMouseEnter('Avantages')}
                                    >
                                        Avantages
                                    </StyledLink>

                                    <StyledLink
                                        to={'#'}
                                        className='navbar-item heading-4 is-tab'
                                    >
                                        <div
                                            onMouseEnter={() => handleMouseEnter('Associations')}
                                            onClick={handleClick}
                                        >
                                            Associations
                                        </div>
                                    </StyledLink>

                                    <StyledLink
                                        to={'/public/Espace-presse'}
                                        className='navbar-item heading-4 is-tab'
                                        onMouseEnter={() => handleMouseEnter('Espace presse')}
                                    >
                                        Espace presse
                                    </StyledLink>

                                    <StyledLink
                                        to={'/public/Aide-Contact'}
                                        className='navbar-item heading-4 is-tab'
                                        onMouseEnter={() => handleMouseEnter('Aide & Contact')}
                                    >
                                        Aide & Contact
                                    </StyledLink>
                                </div>{' '}
                            </div>
                        </NavBarMenuStyled>
                        <NavBarEndStyled>
                            <div className='navbar-end ml-6'>
                                <div
                                    id='cart-icon-container'
                                    className='navbar-item heading-4 has-text-primary'
                                >
                                    <button
                                        className={classNames('button is-primary')}
                                        onClick={login}
                                    >
                                        S'identifier
                                    </button>
                                </div>
                            </div>
                        </NavBarEndStyled>
                    </NavbarStyled>
                </MenuContainer>
            </NavbarWrapper>
            {showSubNavbar && (
                <>
                    <SubNavbarStyled onMouseLeave={handleMouseLeave}>
                        <SubNavBarCategory>
                            <SubCategoryText>Catégories</SubCategoryText>
                            <LinksContainer>
                                <StyledLink to={'/Lien1'}>Emrys et les assos</StyledLink>
                                <StyledLink to={'/Lien2'}>Je suis une association</StyledLink>
                            </LinksContainer>
                        </SubNavBarCategory>
                        <SubNavBarCategorySecond>
                            <img src='/bandeau_emrys_nutrition.png'></img>
                            <SubNavText>Avec Emrys, soutenez l'association de votre choix!</SubNavText>
                        </SubNavBarCategorySecond>
                    </SubNavbarStyled>
                    <Overlay />
                </>
            )}
        </>
    );
}

export default NavbarNotLogged;
