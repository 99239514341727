import React from 'react';
import { useTheme } from 'styled-components';

const GuidlineNumber: React.FC = () => {
    const theme = useTheme();
    const numbers = [
        'M17.6296 676.805V648.965L12.2056 650.213V646.469L19.6456 643.205H22.9096V676.805H17.6296ZM31.5601 677.093C30.6001 677.093 29.8001 676.789 29.1601 676.181C28.5521 675.541 28.2481 674.789 28.2481 673.925C28.2481 673.029 28.5521 672.277 29.1601 671.669C29.8001 671.029 30.6001 670.709 31.5601 670.709C32.5201 670.709 33.3041 671.029 33.9121 671.669C34.5201 672.277 34.8241 673.029 34.8241 673.925C34.8241 674.789 34.5201 675.541 33.9121 676.181C33.3041 676.789 32.5201 677.093 31.5601 677.093Z',
        'M276.498 319.273V315.625C278.674 313.897 280.754 312.153 282.738 310.393C284.722 308.633 286.482 306.905 288.018 305.209C289.586 303.481 290.818 301.801 291.714 300.169C292.61 298.505 293.058 296.905 293.058 295.369C293.058 294.345 292.882 293.385 292.53 292.489C292.178 291.561 291.602 290.825 290.802 290.281C290.002 289.705 288.93 289.417 287.586 289.417C285.57 289.417 284.05 290.057 283.026 291.337C282.034 292.617 281.554 294.153 281.586 295.945H276.642C276.674 293.577 277.17 291.593 278.13 289.993C279.122 288.393 280.434 287.177 282.066 286.345C283.73 285.513 285.602 285.097 287.682 285.097C290.85 285.097 293.378 285.993 295.266 287.785C297.186 289.545 298.146 292.009 298.146 295.177C298.146 297.065 297.682 298.937 296.754 300.793C295.858 302.649 294.674 304.441 293.202 306.169C291.762 307.865 290.21 309.465 288.546 310.969C286.882 312.473 285.298 313.833 283.794 315.049H299.154V319.273H276.498ZM306.462 319.561C305.502 319.561 304.702 319.257 304.062 318.649C303.454 318.009 303.15 317.257 303.15 316.393C303.15 315.497 303.454 314.745 304.062 314.137C304.702 313.497 305.502 313.177 306.462 313.177C307.422 313.177 308.206 313.497 308.814 314.137C309.422 314.745 309.726 315.497 309.726 316.393C309.726 317.257 309.422 318.009 308.814 318.649C308.206 319.257 307.422 319.561 306.462 319.561Z',
        'M905.146 168.679V161.959H888.298V157.927L904.33 135.079H910.186V157.543H914.89V161.959H910.186V168.679H905.146ZM893.818 157.543H905.434V140.695L893.818 157.543ZM921.564 168.967C920.604 168.967 919.804 168.663 919.164 168.055C918.556 167.415 918.252 166.663 918.252 165.799C918.252 164.903 918.556 164.151 919.164 163.543C919.804 162.903 920.604 162.583 921.564 162.583C922.524 162.583 923.308 162.903 923.916 163.543C924.524 164.151 924.828 164.903 924.828 165.799C924.828 166.663 924.524 167.415 923.916 168.055C923.308 168.663 922.524 168.967 921.564 168.967Z',
        'M613.875 592.85C611.699 592.85 609.715 592.466 607.923 591.698C606.163 590.898 604.755 589.698 603.699 588.098C602.643 586.498 602.083 584.498 602.019 582.098H607.059C607.123 583.89 607.731 585.41 608.883 586.658C610.067 587.874 611.731 588.482 613.875 588.482C615.923 588.482 617.491 587.922 618.579 586.802C619.667 585.65 620.211 584.226 620.211 582.53C620.211 580.482 619.475 578.962 618.003 577.97C616.563 576.978 614.787 576.482 612.675 576.482H610.179V572.258H612.723C614.611 572.258 616.131 571.81 617.283 570.914C618.467 570.018 619.059 568.754 619.059 567.122C619.059 565.746 618.595 564.626 617.667 563.762C616.771 562.898 615.491 562.466 613.827 562.466C612.099 562.466 610.739 562.978 609.747 564.002C608.755 564.994 608.211 566.242 608.115 567.746H603.075C603.139 565.794 603.619 564.098 604.515 562.658C605.443 561.218 606.707 560.098 608.307 559.298C609.907 558.498 611.747 558.098 613.827 558.098C616.067 558.098 617.939 558.498 619.443 559.298C620.979 560.066 622.131 561.122 622.899 562.466C623.699 563.778 624.099 565.218 624.099 566.786C624.099 568.578 623.603 570.146 622.611 571.49C621.619 572.834 620.291 573.73 618.627 574.178C620.547 574.594 622.131 575.538 623.379 577.01C624.627 578.45 625.251 580.322 625.251 582.626C625.251 584.482 624.819 586.194 623.955 587.762C623.091 589.298 621.811 590.53 620.115 591.458C618.419 592.386 616.339 592.85 613.875 592.85ZM633.353 592.562C632.393 592.562 631.593 592.258 630.953 591.65C630.345 591.01 630.041 590.258 630.041 589.394C630.041 588.498 630.345 587.746 630.953 587.138C631.593 586.498 632.393 586.178 633.353 586.178C634.313 586.178 635.097 586.498 635.705 587.138C636.313 587.746 636.617 588.498 636.617 589.394C636.617 590.258 636.313 591.01 635.705 591.65C635.097 592.258 634.313 592.562 633.353 592.562Z',
        'M1227.69 451.475C1225.33 451.475 1223.28 451.059 1221.55 450.227C1219.82 449.363 1218.45 448.195 1217.42 446.723C1216.43 445.219 1215.82 443.523 1215.6 441.635H1220.59C1220.91 443.235 1221.71 444.563 1222.99 445.619C1224.27 446.643 1225.85 447.155 1227.74 447.155C1229.15 447.155 1230.37 446.819 1231.39 446.147C1232.41 445.443 1233.21 444.515 1233.79 443.363C1234.37 442.211 1234.65 440.915 1234.65 439.475C1234.65 437.203 1234.01 435.395 1232.73 434.051C1231.45 432.675 1229.84 431.987 1227.89 431.987C1226.22 431.987 1224.8 432.387 1223.61 433.187C1222.43 433.955 1221.57 434.931 1221.02 436.115H1216.17L1219.05 417.299H1237.2V421.763H1222.89L1221.17 430.739C1221.9 429.907 1222.91 429.219 1224.19 428.675C1225.5 428.099 1226.97 427.811 1228.61 427.811C1230.88 427.811 1232.83 428.339 1234.46 429.395C1236.09 430.419 1237.34 431.811 1238.21 433.571C1239.1 435.331 1239.55 437.283 1239.55 439.427C1239.55 441.667 1239.07 443.699 1238.11 445.523C1237.15 447.347 1235.77 448.803 1233.98 449.891C1232.22 450.947 1230.13 451.475 1227.69 451.475ZM1247.38 451.187C1246.42 451.187 1245.62 450.883 1244.98 450.275C1244.38 449.635 1244.07 448.883 1244.07 448.019C1244.07 447.123 1244.38 446.371 1244.98 445.763C1245.62 445.123 1246.42 444.803 1247.38 444.803C1248.34 444.803 1249.13 445.123 1249.74 445.763C1250.34 446.371 1250.65 447.123 1250.65 448.019C1250.65 448.883 1250.34 449.635 1249.74 450.275C1249.13 450.883 1248.34 451.187 1247.38 451.187Z',
        'M1448.73 121.578C1446.26 121.578 1444.18 121.114 1442.49 120.186C1440.79 119.258 1439.42 118.026 1438.36 116.49C1437.34 114.954 1436.6 113.226 1436.15 111.306C1435.7 109.386 1435.48 107.418 1435.48 105.402C1435.48 101.69 1435.99 98.442 1437.02 95.658C1438.07 92.874 1439.58 90.714 1441.53 89.178C1443.48 87.61 1445.85 86.826 1448.63 86.826C1450.84 86.826 1452.73 87.258 1454.3 88.122C1455.86 88.986 1457.08 90.138 1457.94 91.578C1458.84 93.018 1459.38 94.586 1459.58 96.282H1454.82C1454.54 94.618 1453.85 93.338 1452.76 92.442C1451.67 91.546 1450.28 91.098 1448.58 91.098C1446.25 91.098 1444.31 92.202 1442.78 94.41C1441.27 96.586 1440.47 99.946 1440.38 104.49C1441.14 103.082 1442.33 101.882 1443.93 100.89C1445.56 99.898 1447.43 99.402 1449.54 99.402C1451.43 99.402 1453.19 99.85 1454.82 100.746C1456.49 101.642 1457.83 102.906 1458.86 104.538C1459.91 106.138 1460.44 108.042 1460.44 110.25C1460.44 112.202 1459.96 114.042 1459 115.77C1458.04 117.498 1456.68 118.906 1454.92 119.994C1453.19 121.05 1451.13 121.578 1448.73 121.578ZM1448.44 117.162C1449.78 117.162 1450.98 116.874 1452.04 116.298C1453.1 115.722 1453.93 114.938 1454.54 113.946C1455.14 112.922 1455.45 111.77 1455.45 110.49C1455.45 108.442 1454.78 106.81 1453.43 105.594C1452.12 104.378 1450.44 103.77 1448.39 103.77C1447.05 103.77 1445.83 104.074 1444.74 104.682C1443.69 105.29 1442.86 106.106 1442.25 107.13C1441.64 108.122 1441.34 109.226 1441.34 110.442C1441.34 111.754 1441.64 112.922 1442.25 113.946C1442.89 114.938 1443.74 115.722 1444.79 116.298C1445.88 116.874 1447.1 117.162 1448.44 117.162ZM1466.81 121.29C1465.85 121.29 1465.05 120.986 1464.41 120.378C1463.8 119.738 1463.5 118.986 1463.5 118.122C1463.5 117.226 1463.8 116.474 1464.41 115.866C1465.05 115.226 1465.85 114.906 1466.81 114.906C1467.77 114.906 1468.55 115.226 1469.16 115.866C1469.77 116.474 1470.07 117.226 1470.07 118.122C1470.07 118.986 1469.77 119.738 1469.16 120.378C1468.55 120.986 1467.77 121.29 1466.81 121.29Z',
    ];

    return (
        <>
            {numbers.map((number, index) => (
                <path
                    d={number}
                    fill={theme.colors.white}
                    key={index}
                />
            ))}
        </>
    );
};

export default GuidlineNumber;
