import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';
import { CartRouterLoaderProps } from '../Interfaces/CartRouterLoaderInterface';

const LazyCartRouter = React.lazy(() => import('Cart/CartRouter').catch(errorHandling));

const CartRouterLoader: React.FC<CartRouterLoaderProps> = ({ children }) => (
    <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<div></div>}>
            <LazyCartRouter>{children}</LazyCartRouter>
        </Suspense>
    </ErrorBoundary>
);

export default CartRouterLoader;
