import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import './styles/index.scss';
import { ErrorBoundary } from 'react-error-boundary';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import client from 'Products/ApiClient';
import EmrysThemeProvider from './LoadModule/EmrysThemeProvider';
import CookieConsent from './LoadModule/CookieConsent';

const queryClient = new QueryClient();

library.add(fas);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <ErrorBoundary fallback={<></>}>
                        <CookieConsent />
                        <EmrysThemeProvider>
                            <App />
                            <ReactQueryDevtools initialIsOpen={false} />
                        </EmrysThemeProvider>
                    </ErrorBoundary>
                </BrowserRouter>
            </QueryClientProvider>
        </ApolloProvider>
    </React.StrictMode>,
);
