import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';
import { BreadcrumbsProps } from '../Interfaces/BreadcrumbsInterface';

const BreadcrumbsUi = React.lazy(() => import('Ui/Breadcrumbs').catch(errorHandling));

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items, showEllipsis, backText }) => {
    return (
        <ErrorBoundary fallback={<></>}>
            <Suspense fallback={<div></div>}>
                <BreadcrumbsUi
                    items={items}
                    showEllipsis={showEllipsis}
                    backText={backText}
                />
            </Suspense>
        </ErrorBoundary>
    );
};

export default Breadcrumbs;
