import React from 'react';
import iconemrys from '@assets/icons/iconemrys.svg';
import iconmobile from '@assets/icons/iconmobile.svg';
import iconphone from '@assets/icons/iconphone.svg';
import iconworld from '@assets/icons/iconworld.svg';
import cartesim from '@assets/icons/cartesim.svg';
import reseaux from '@assets/icons/reseaux.svg';
import { Overlay, PopupContainer, Blocpopup } from './PackMobileStyle';
import { PopupPackMobileProps } from '../../../../Interfaces/PackMobileInterface';
import Button from '../../../../LoadModule/Button';
import { useSharedTranslation } from 'Translation/useSharedTranslation';
import { useDeviceDetect } from '../../../../LoadModule/DeviceDetectLoader';

const PopupPackMobile: React.FC<PopupPackMobileProps> = ({ price, subPack, pack, onClose }) => {
    const { t } = useSharedTranslation();
    const { isMobile } = useDeviceDetect();
    return (
        <>
            <Overlay onClick={onClose} />
            <PopupContainer>
                <Blocpopup className='box'>
                    {isMobile && (
                        <div
                            className='closebutton'
                            onClick={onClose}
                        >
                            ×
                        </div>
                    )}

                    <div className='priceoffre'>
                        <div>
                            <div className='giga'>{pack}</div>

                            <div>
                                <span>{price.toString().replace('.', ',')}€</span>
                                <div className='duration'> /{t('base.emrysmobile.month')}</div>
                            </div>
                        </div>
                        {!isMobile && (
                            <div
                                className='closebutton'
                                onClick={onClose}
                            >
                                ×
                            </div>
                        )}
                    </div>

                    <hr />
                    {!isMobile && (
                        <Button
                            className='button'
                            color={'Grey'}
                            label={t('base.emrysmobile.secondTitle')}
                            size={'Small'}
                        />
                    )}

                    <div className='packdesc'>
                        <div>
                            <img
                                alt='iconemrys'
                                src={iconemrys}
                            />
                            <span>
                                <strong>{t('cart.emrysmobile.emryscoinspaying')} </strong>
                                {t('cart.emrysmobile.availableemrysstore')}
                            </span>
                        </div>
                        <div>
                            <img
                                alt='iconphone'
                                src={iconphone}
                            />
                            <span>
                                <strong>{t('cart.emrysmobile.unlimitedcalls')} </strong> {t('cart.emrysmobile.metropolitanfrance')}
                            </span>
                        </div>
                        <div>
                            <strong>4G</strong>
                            <span>
                                <strong>
                                    {t('cart.emrysmobile.mobileinternet')} {pack} {t('cart.emrysmobile.frommetropolitanfrance')}{' '}
                                </strong>{' '}
                                {t('cart.emrysmobile.benefit')} <br />
                                {t('cart.emrysmobile.package')}
                            </span>
                        </div>
                        <div>
                            <img
                                alt='iconworld'
                                src={iconworld}
                            />
                            <span>
                                <strong>{t('cart.emrysmobile.traveling')} </strong> {t('cart.emrysmobile.youbenefitfrom')} {subPack}{' '}
                                {t('cart.emrysmobile.packagedesc')}
                            </span>
                        </div>
                        <div>
                            <img
                                alt='reseaux'
                                src={reseaux}
                            />
                            <span>
                                <strong>{t('cart.emrysmobile.networkreliability')}</strong> {t('cart.emrysmobile.usetheinternet')}
                            </span>
                        </div>
                        <div>
                            <img
                                alt='cartesim'
                                src={cartesim}
                            />
                            <span>
                                <strong>{t('cart.emrysmobile.simcardesim')}</strong> {t('cart.emrysmobile.compatiblemobiles')}
                            </span>
                        </div>

                        {!isMobile && (
                            <div>
                                <img
                                    alt='iconmobile'
                                    src={iconmobile}
                                />
                                <span>
                                    <strong>{t('cart.emrysmobile.keepmobile')}</strong>
                                </span>
                            </div>
                        )}
                    </div>

                    <hr />
                    <div className={!isMobile ? 'mt-3' : 'mt-1 mb-1  is-justify-content-center'}>
                        <Button
                            className='button '
                            hover={true}
                            active={true}
                            color={'Blue'}
                            isFocused={true}
                            hasBorder={false}
                            label={t('base.emrysmobile.subscribe')}
                            size={isMobile ? 'Small' : 'Medium'}
                            onClick={onClose}
                        />
                    </div>
                </Blocpopup>
            </PopupContainer>
        </>
    );
};

export default PopupPackMobile;
