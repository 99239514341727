import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';
import { ProductRoutePrefixContextLoaderProps } from '../Interfaces/ProductRoutePrefixContextLoaderInterface';

const LazyProductRoutePrefixContext = React.lazy(() => import('Products/ProductRoutePrefixContext').catch(errorHandling));

const ProductRoutePrefixContextLoader: React.FC<ProductRoutePrefixContextLoaderProps> = ({ basePrefix, children }) => (
    <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<div></div>}>
            <LazyProductRoutePrefixContext basePrefix={basePrefix}>{children}</LazyProductRoutePrefixContext>
        </Suspense>
    </ErrorBoundary>
);

export default ProductRoutePrefixContextLoader;
