import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Title = styled.div`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.size3};
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: ${props => props.theme.colors.primary};
    padding: 0% 0% 5% 22px;
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.mobileSize2};
    }
`;

export const ScrollContainer = styled.div`
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    cursor: grab;
    padding: 1% 3% 5% 1.5%;
    position: relative;
    z-index: 0;
    gap: 15px;
    &::-webkit-scrollbar {
        display: none;
    }
    ${props => props.theme.devices.mobile} {
        gap: unset;
        padding: 5% 5% 10% 0%;
    }
`;

export const SectionBloc = styled(Link)`
    flex: 1;
    min-width: 480px;
    max-height: 500px;
    border-radius: 9px;
    box-shadow: 0px 6px 17px 0px #4d4d4d4d;

    ${props => props.theme.devices.desktop} {
        &:hover {
            transition: transform 0.5s ease;
            transform: scale(1.01);
        }
    }
    ${props => props.theme.devices.mobile} {
        min-width: 270px;
        max-height: 393px;
        &:hover {
        }
    }
`;

export const TextBloc = styled.div`
    padding: 55px 55px;
    ${props => props.theme.devices.mobile} {
        padding: 25px 25px;
    }
`;
export const StyledImage = styled.img`
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
    z-index: 1;
    ${props => props.theme.devices.mobile} {
        width: 270px !important;
        height: 171px !important;
    }
`;

export const ImageContainer = styled.div`
    position: relative;
`;

export const GradientLayer = styled.div`
    position: absolute;
    top: 2px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, transparent -18%, ${props => props.theme.colors.white});
`;

export const TitleSection = styled.div`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.mobileSize2};
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: ${props => props.theme.colors.primary};
    &:hover {
        color: ${props => props.theme.colors.primary};
    }

    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.mobileSize3};
        line-height: 23px;
        display: block;
        text-align: center;
    }
`;

export const Description = styled.div`
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.mobileSize4};
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: ${props => props.theme.colors.greyDark};
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.mobileSize5};
        font-weight: 400;
        line-height: ${props => props.theme.globalSize.mobileSize3};

        text-align: center;
    }
`;
