import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ContainerPageProps, CustomLinkProps, DropdownMenuProps, NavBarPrimaryMenuProps } from '../Interfaces/NavbarInterface';
export const NavbarStyled = styled.nav`
    .navbar-menu {
        .navbar-start,
        .navbar-end {
            #cart-icon-container.navbar-item {
                cursor: pointer;
                span {
                    padding-left: 15px;
                }
            }

            .navbar-item {
                margin: 12px;
            }
        }
    }
    ${props => props.theme.devices.mobile} {
        height: 80px;
        padding: 16px 24px;
    }
`;

export const MenuContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    ${props => props.theme.devices.mobile} {
        z-index: 15;
        position: fixed;
    }
`;

export const ContainerPage = styled.div<ContainerPageProps>`
    top: 0px;
    position: sticky;
    z-index: ${props => (props.$isSubMenuOpen ? 101 : 12)};
`;

export const NavBarPrimaryMenu = styled.div<NavBarPrimaryMenuProps>`
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: ${props => (props.$blue ? props.theme.colors.primary : props.theme.colors.white)};
    width: 50%;
    flex-direction: column;
    align-items: center;
`;

export const MenuItem = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const TopTitle = styled.span`
    font-weight: 700;
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.mobileSize2};
    line-height: 26.04px;
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.mobileSize4};
    }
`;
export const Icon = styled.img`
    margin-right: 10px;
    margin-bottom: -4px;
`;

export const CustomLink = styled(Link)<CustomLinkProps>`
    font-weight: 700;
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: ${props => props.theme.fonts.familySecondary};
    font-size: ${props => props.theme.globalSize.mobileSize2};
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.mobileSize4};
    }

    &:focus {
        outline: none;
        text-decoration: none !important;
    }
    color: ${props => (props.$blue ? props.theme.colors.primary : props.theme.colors.white)};
    text-decoration: none;
    cursor: pointer;
    &:hover {
        color: ${props => (props.$blue ? props.theme.colors.primary : props.theme.colors.white)};
    }
`;

export const DropdownMenu = styled.div<DropdownMenuProps>`
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    width: 100%;
    display: ${props => (props.$isOpen ? 'block' : 'none')};
`;
