import { useEffect } from 'react';

import ShopMenuLoader from '../../../../LoadModule/ShopMenuLoader.tsx';
import AssociationMenuLoader from '../../../../LoadModule/AssociationMenuLoader.tsx';
import EmrysCarsMenuLoader from '../../../../LoadModule/EmrysCarsMenuLoader.tsx';
import EmrysEnergyMenuLoader from '../../../../LoadModule/EmrysEnergyMenuLoader.tsx';

import { ShopMenuOverlay } from '../../../../GlobalStyle/Overlay.tsx';
import PrimaryMenu from '../Menu/PrimaryMenu/PrimaryMenu.tsx';
import { useExcludeRoute } from '../../../../Hooks/useExcludeRoute.tsx';
import { ContainerPage } from '../../../../GlobalStyle/Navbar.tsx';
import { useMenuContext } from '../../../../Context/MenuContext.tsx';
import { ShopSubMenuContainer } from './NavbarStyle.tsx';
import EmrysNutritionMenuLoader from '../../../../LoadModule/EmrysNutritionMenuLoader.tsx';
import TrainingMenuLoader from '../../../../LoadModule/TrainingMenuLoader.tsx';

function Navbar() {
    const { excludePaymentRoute } = useExcludeRoute();

    useEffect(() => {
        const shopMenuOverlayElement = document.getElementById('shop-menu-overlay');
        const navItems = document.querySelectorAll('.navbar-item[data-deploy]');

        const hideAllNavItems = () => {
            navItems.forEach(navItem => {
                navItem.classList.remove('is-active');
                const deploy = navItem.getAttribute('data-deploy');
                if (deploy) {
                    const target = document.querySelector(deploy);
                    target?.classList.add('is-hidden');
                }
            });
        };

        navItems.forEach(navItem => {
            navItem.addEventListener('mouseover', () => {
                const deploy = navItem.getAttribute('data-deploy');
                let target = null;
                let targetParent: HTMLElement | null | undefined = null;
                if (deploy) {
                    target = document.querySelector(deploy);
                    targetParent = target?.parentElement;
                }

                hideAllNavItems();

                navItem.classList.add('is-active');
                target?.classList.remove('is-hidden');
                targetParent?.classList.add('with-fading');
                if (!targetParent?.onmouseleave) {
                    targetParent?.addEventListener('mouseleave', () => {
                        targetParent?.classList.remove('with-fading');
                    });
                }

                shopMenuOverlayElement?.addEventListener('mouseenter', () => {
                    targetParent?.classList.remove('with-fading');
                });
            });
        });

        const yStickyHide = 100;
        document.querySelectorAll('.nav-menu > div.body').forEach(elBody => {
            const previousElement: HTMLElement = elBody.previousElementSibling as HTMLElement;
            const hasStickyHeader = previousElement && previousElement.classList.contains('is-sticky');

            if (!hasStickyHeader) {
                return;
            }

            const header = previousElement;
            elBody.addEventListener('scroll', el => {
                const target: HTMLElement = el.target as HTMLElement;
                target.style.marginTop = `${Math.max(0, header.clientHeight - target.scrollTop)}px`;
                header.style.opacity = target.scrollTop < yStickyHide ? '1' : '0';
            });
        });
    });

    const { isSubMenuOpen } = useMenuContext();

    return (
        <ContainerPage $isSubMenuOpen={isSubMenuOpen}>
            {excludePaymentRoute && <PrimaryMenu />}

            <ShopSubMenuContainer
                id='shop-sub-menu-container'
                className='container is-fluid is-hidden-touch'
            >
                <ShopMenuLoader />
                <AssociationMenuLoader />
                <EmrysCarsMenuLoader />
                <EmrysEnergyMenuLoader />
                <EmrysNutritionMenuLoader />
                <TrainingMenuLoader />

                <ShopMenuOverlay
                    id='shop-menu-overlay'
                    className='overlay-gradiant'
                ></ShopMenuOverlay>
            </ShopSubMenuContainer>
        </ContainerPage>
    );
}

export default Navbar;
