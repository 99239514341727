import { Routes, Route } from 'react-router-dom';
import Advantages from '../Components/Pages/Unlogged/Advantages/Advantages.tsx';
import HelpContact from '../Components/Pages/Unlogged/HelpContact/HelpContact.tsx';
import PressArea from '../Components/Pages/Unlogged/PressArea/PressArea.tsx';
import BrandsPartner from '../Components/Pages/Unlogged/BrandsPartner/BrandsPartner.tsx';
import EmrysetAssos from '../Components/Pages/Unlogged/EmrysetAssos/EmrysetAssos.tsx';
import JesuisAssociation from '../Components/Pages/Unlogged/IamAssociation/IamAssociation.tsx';
import Login from '../Components/Pages/Unlogged/login/Login.tsx';
import ForgotPassword from '../Components/Pages/Unlogged/login/ForgotPassword.tsx';

export default function PublicRouter() {
    return (
        <Routes>
            <Route
                path={'/Espace-presse'}
                element={<PressArea />}
            />
            <Route
                path={'/Aide-Contact'}
                element={<HelpContact />}
            />
            <Route
                path={'/Avantages'}
                element={<Advantages />}
            />
            <Route
                path={'/Nos-enseignes-partenaires'}
                element={<BrandsPartner />}
            />
            <Route
                path={'/Emrys-et-Assos'}
                element={<EmrysetAssos />}
            />
            <Route
                path={'/Je-suis-Association'}
                element={<JesuisAssociation />}
            />
            <Route
                path='/login'
                element={<Login />}
            />
            <Route
                path='/login/forgot-password'
                element={<ForgotPassword />}
            />
        </Routes>
    );
}
