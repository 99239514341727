import Sponsorship from '@assets/Section/Sponsorship.png';
import SupportingAssociation from '@assets/Section/SupportingAssociation.png';
import Becomeshareholder from '@assets/Section/Becomeshareholder.png';
import Section from '../Section/Section';
import { SectionTitle } from '../PackMobile/PackMobileStyle';
import { MapSection } from './MapSection';
import { useRoutePrefix } from '../../../../Context/RoutePrefixContext';
import { CooperativeProps } from '../../../../Interfaces/CooperativeInterface';

const Cooperative: React.FC<CooperativeProps> = ({ isMobile }) => {
    const prefix = useRoutePrefix();

    const sections = [
        {
            imageUrl: Sponsorship,
            title: 'Parrainer un ami',
            description: 'Le partage, ça rapporte ! Faites profiter des bons plans Emrys à votre entourage : parrainez-les !',
            to: `${prefix}` + '/myteams/sponsorship',
        },
        {
            imageUrl: SupportingAssociation,
            title: 'Sponsoring associatif',
            description: 'Contribuez au développement de la vie associative, grâce à notre programme de dons aux associations.',
            to: `${prefix}` + '/myteams/supportingassociation',
        },
        {
            imageUrl: Becomeshareholder,
            title: 'Devenir sociétaire',
            description:
                'Vous souhaitez vous investir davantage dans la coopérative? Devenez sociétaire pour vous approprier ses objectifs, soutenir ses convictions, et agir pour son avenir.',
            to: `${prefix}` + '/account/myprofile/becomeshareholder',
        },
    ];

    return (
        <div className={'cooperative'}>
            <SectionTitle title={'Faire avancer la coopérative'} />
            <MapSection>
                {sections.map((section, index) => (
                    <Section
                        key={index}
                        imageUrl={section.imageUrl}
                        title={section.title}
                        description={section.description}
                        to={section.to}
                        isMobile={isMobile}
                    />
                ))}
            </MapSection>
        </div>
    );
};

export default Cooperative;
