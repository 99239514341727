import { MenuContainer, NavBarPrimaryMenu, MenuItem, Icon, CustomLink, DropdownMenu, TopTitle } from '../../../../../GlobalStyle/Navbar';

import butterflyBlueIcon from '@assets/Menu/butterflyblueicon.svg';
import butterflyWhiteIcon from '@assets/Menu/butterflywhiteicon.svg';
import { useState } from 'react';
import { PrimaryMenuStyle } from '../MenuStyle';
import { useRoutePrefix } from '../../../../../Context/RoutePrefixContext';

export function PrimaryMenu() {
    const [isEmrysLaCarteOpen, setIsEmrysLaCarteOpen] = useState(true);
    const [isEmrysBrandsOpen, setIsEmrysBrandsOpen] = useState(false);
    const prefix = useRoutePrefix();

    const toggleEmrysLaCarteDropdown = () => {
        setIsEmrysLaCarteOpen(!isEmrysLaCarteOpen);
        setIsEmrysBrandsOpen(false);
    };

    const toggleEmrysBrandsDropdown = () => {
        setIsEmrysBrandsOpen(!isEmrysBrandsOpen);
        setIsEmrysLaCarteOpen(false);
    };

    return (
        <>
            <MenuContainer>
                <NavBarPrimaryMenu>
                    <MenuItem>
                        <CustomLink
                            to={`${prefix}`}
                            $blue={true}
                            onClick={toggleEmrysLaCarteDropdown}
                        >
                            <PrimaryMenuStyle>
                                <Icon
                                    src={butterflyBlueIcon}
                                    alt='Emrys La Carte'
                                />
                                <TopTitle> Emrys La Carte</TopTitle>
                            </PrimaryMenuStyle>
                        </CustomLink>

                        <DropdownMenu $isOpen={isEmrysLaCarteOpen} />
                    </MenuItem>
                </NavBarPrimaryMenu>
                <NavBarPrimaryMenu $blue={true}>
                    <MenuItem>
                        <CustomLink
                            to={`${prefix}/emrys-brands`}
                            onClick={toggleEmrysBrandsDropdown}
                            state={{ isEmrysBrands: true }}
                        >
                            <PrimaryMenuStyle>
                                <Icon
                                    src={butterflyWhiteIcon}
                                    alt='Marques Emrys'
                                />
                                <TopTitle> Marques Emrys</TopTitle>
                            </PrimaryMenuStyle>
                        </CustomLink>
                    </MenuItem>
                </NavBarPrimaryMenu>
            </MenuContainer>
        </>
    );
}

export default PrimaryMenu;
