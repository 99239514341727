import { GuaranteeProps } from '../../../../Interfaces/ConstantInterface';
import { CardContainer, CardContent, Container, ImgIcon, StyledLink, TextDecrip, TitleCard, TitleText, TitleWrapper } from './GuaranteeStyle';

export const Guarantee: React.FC<GuaranteeProps> = ({ object }) => {
    return (
        <Container className={'guarantee'}>
            <TitleWrapper>
                <TitleText>Nos garanties</TitleText>
            </TitleWrapper>
            <CardContent
                $isgapvalue={object.length < 4}
                className=' is-mobile is-multiline tile is-parent
                    is-flex-wrap-wrap
                    is-variable
                    is-4-desktop
                    is-8-widescreen
                    is-2-fullhd is-marginless is-align-items-center is-flex is-justify-content-center
            '
            >
                {object.map((card, index) => {
                    const CardContent = (
                        <>
                            <div className='row  is-align-items-center is-flex is-marginless'>
                                <div
                                    className='
                                is-one-third
                                is-5-mobile
                                is-flex
                                is-justify-content-flex-end
                                is-align-content-flex-end
                        '
                                >
                                    <ImgIcon
                                        src={card.icon}
                                        alt='SVG'
                                    />
                                </div>
                                <div
                                    className='
                                is-8-mobile
                                is-justify-content-flex-start
                                is-flex
                                is-align-content-flex-start'
                                >
                                    <TitleCard $isprimary={object.length < 4}>{card.boldText}</TitleCard>
                                </div>
                            </div>
                            <div className='row'>
                                <TextDecrip>{card.text}</TextDecrip>
                            </div>
                        </>
                    );

                    return (
                        <CardContainer
                            className=' is-2.6-desktop mx-2 rows'
                            key={index}
                        >
                            {card.clickable ?
                                <StyledLink to={card.link || '#'}>{CardContent}</StyledLink>
                            :   <>{CardContent}</>}
                        </CardContainer>
                    );
                })}
            </CardContent>
        </Container>
    );
};
