import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { authService } from '../../Services/Services.ts';
import Navbar from '../../Components/Pages/Logged/Navbar/Navbar.tsx';
import NavbarMobile from '../../Components/Pages/Logged/Navbar/NavbarMobile.tsx';
import MenuShared from '../../Components/Pages/Logged/Menu/MenuShared.tsx';
import Footer from '../../Components/Pages/Footer/Footer.tsx';
import { useExcludeRoute } from '../../Hooks/useExcludeRoute.tsx';
import { MenuProvider } from '../../Context/MenuContext.tsx';
import { useRoutePrefix } from '../../Context/RoutePrefixContext.tsx';
import { useMenuParams } from '../../Hooks/Constant.ts';
import butterfly from '@assets/Cart/butterfly.svg';
import CartPreview from '../../LoadModule/CartPreview.tsx';
import { Container } from './LoggedRouteStyle.tsx';
import { useDeviceDetect } from '../../LoadModule/DeviceDetectLoader.tsx';
import { productPrefix } from 'Products/ProductPrefix';
import { cartPrefix } from 'Cart/CartPrefix';

const LoggedRoute = (): React.ReactElement => {
    const isLogged: boolean = authService.isLogged();
    const location = useLocation();
    const prefix = useRoutePrefix();
    const { isMobile } = useDeviceDetect();

    const { MENU_EMRYS_PARAMS, MENU_BRANDS_PARAMS } = useMenuParams();

    const [menuParams, setMenuParams] = useState(MENU_EMRYS_PARAMS);
    const pathValue = location?.pathname.startsWith(`${prefix}/emrys-brands`);
    const { excludePaymentRoute } = useExcludeRoute();

    useEffect(() => {
        pathValue ? setMenuParams(MENU_BRANDS_PARAMS) : setMenuParams(MENU_EMRYS_PARAMS);
    }, [location, pathValue]);

    const displayNavBar = () => (isMobile ? <NavbarMobile /> : <Navbar />);
    const displayMenu = () => {
        return (
            <>
                <MenuProvider>
                    {displayNavBar()}

                    <MenuShared
                        title={menuParams.title}
                        links={menuParams.links}
                        blue={!!pathValue}
                        isMobile={isMobile}
                    />
                </MenuProvider>
            </>
        );
    };

    const displayCartPreviewComponent = () => {
        return (
            <CartPreview
                backUrlShop={`${prefix}${productPrefix}/shop`}
                backUrlCart={`${prefix}${cartPrefix}/review`}
            />
        );
    };

    const isPaymentRoute = location.pathname.includes('/myemrys/cart/payment/instructions');

    const containerProps = {
        color: isPaymentRoute ? '#F5F5F5' : 'initial',
        image: isPaymentRoute ? butterfly : 'none',
    };

    return isLogged ?
            <>
                {displayCartPreviewComponent()}
                <Container
                    className={'is-flex is-flex-direction-column'}
                    {...containerProps}
                >
                    {excludePaymentRoute && displayMenu()}
                    <div className={isMobile ? 'mt-6' : 'mt-4'}>
                        <Outlet />
                    </div>
                    <Footer />
                </Container>
            </>
        :   <Navigate
                to='/'
                replace
                state={{ from: location }}
            />;
};

export default LoggedRoute;
