import { useExcludeRoute } from '../../../../Hooks/useExcludeRoute.tsx';
import { PrimaryMenu } from '../Menu/PrimaryMenu/PrimaryMenu.tsx';

function NavbarMobile() {
    const { excludePaymentRoute } = useExcludeRoute();

    return <>{excludePaymentRoute && <PrimaryMenu />}</>;
}

export default NavbarMobile;
