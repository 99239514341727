import React, { createContext, useContext } from 'react';
import ProductRoutePrefixContextLoader from '../LoadModule/ProductRoutePrefixContextLoader.tsx';
import UserRoutePrefixContext from '../LoadModule/UserRoutePrefixContext.tsx';
import { RoutePrefixProviderProps } from '../Interfaces/RoutePrefixContextInterface.tsx';

const RoutePrefixContext = createContext<string | undefined>(undefined);

export const useRoutePrefix = () => {
    const context = useContext(RoutePrefixContext);
    if (context === undefined) {
        throw new Error('useRoutePrefix must be used within a RoutePrefixProvider');
    }
    return context;
};

export const RoutePrefixProvider: React.FC<RoutePrefixProviderProps> = ({ children }) => {
    const prefix = import.meta.env.VITE_BASE_LOGGED_ROUTE || '';

    return (
        <RoutePrefixContext.Provider value={prefix}>
            <ProductRoutePrefixContextLoader basePrefix={prefix}>
                <UserRoutePrefixContext basePrefix={prefix}>{children}</UserRoutePrefixContext>
            </ProductRoutePrefixContextLoader>
        </RoutePrefixContext.Provider>
    );
};
