import { useState, useCallback, useRef, useEffect } from 'react';
import {
    Logo,
    Container,
    Nav,
    IconContainer,
    CustomLink,
    MobileMenuButton,
    Menu,
    ContainerMobile,
    LeftIcon,
    MobileTitle,
    RightIcon,
    SubMenuStyle,
    UlMenu,
    Overlay,
    ArrowIcon,
    BoxMenu,
    BoxHeaderMenu,
    BoxHeaderContent,
    BoxContainer,
    SubMenuStickyStyled,
} from './MenuStyle.tsx';
import logo from '@assets/WhiteLogo.svg';
import bluelogo from '@assets/logo.svg';
import searchWhite from '@assets/Menu/searchWhite.svg';
import searchBlue from '@assets/Menu/searchBlue.svg';
import notification from '@assets/Menu/notification-bellWhite.svg';
import notificationBlue from '@assets/Menu/notification-bellBlue.svg';
import account from '@assets/Menu/AccountBlue.svg';
import MenuButtonIcon from '@assets/Menu/MenuButtonWhiteIcon.svg';
import MenuButtonBlueIcon from '@assets/Menu/MenuButtonIcon.svg';
import HomeIcon from '@assets/Menu/HomeIcon.svg';
import closeIcon from '@assets/Menu/closeIcon.svg';
import arrow from '@assets/Menu/arrow.svg';
import { Link } from 'react-router-dom';
import AccountMenu from './AccountMenu/AccountMenu.tsx';
import { useMenuContext } from '../../../../Context/MenuContext.tsx';
import { useRoutePrefix } from '../../../../Context/RoutePrefixContext.tsx';
import { EmptyObject, MenuSharedProps, SubMenuDisplayitProps, SubMenuItem } from '../../../../Interfaces/MenuInterface.tsx';
import renderSubMenu from './RenderSubMenu.tsx';
import CartIcon from '../../../../LoadModule/CartIcon.tsx';
import { useSharedTranslation } from 'Translation/useSharedTranslation';

function MenuShared({ title, links, blue = false, isMobile }: MenuSharedProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState<number | null>(null);
    const [isVisible, setIsVisible] = useState(false);
    const boxRef = useRef<HTMLDivElement>(null);
    const accountImgRef = useRef<HTMLImageElement>(null);
    const { t } = useSharedTranslation();
    const { isSubMenuOpen, setisSubMenuOpen } = useMenuContext();
    const prefix = useRoutePrefix();

    const toggleMenu = useCallback(() => {
        setIsMenuOpen(prevState => !prevState);
    }, []);

    const handleMouseEnter = useCallback((index: number, length?: number) => {
        setActiveSubMenu(index);
        if (index && length === 0) setisSubMenuOpen(false);
        length === 0 ? setIsMenuOpen(false) : setisSubMenuOpen(length !== undefined && length !== 0);
    }, []);

    const handleGoBackMenu = useCallback(() => {
        setActiveSubMenu(null);
    }, []);

    const handleCloseMenu = useCallback(() => {
        setisSubMenuOpen(false);
        setIsMenuOpen(false);
    }, []);

    const toggleBox = () => {
        setIsVisible(prevVisible => !prevVisible);
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                boxRef.current &&
                !boxRef.current.contains(event.target as Node) &&
                accountImgRef.current &&
                !accountImgRef.current.contains(event.target as Node)
            ) {
                setIsVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    let subMenuProps: SubMenuDisplayitProps | EmptyObject = {};

    if (activeSubMenu !== null) {
        subMenuProps =
            isMobile ?
                {
                    subMenu: links[activeSubMenu]?.subMenu as (string | SubMenuItem)[],
                    label: links[activeSubMenu]?.label,
                    blue: blue,
                    isMobile: true,
                    handleGoBackMenu: handleGoBackMenu,
                    handleCloseMenu: handleCloseMenu,
                    activeSubMenu: activeSubMenu,
                }
            :   {
                    subMenu: links[activeSubMenu]?.subMenu as (string | SubMenuItem)[],
                    activeSubMenu: activeSubMenu,
                    label: '',
                    blue: blue,
                    isMobile: false,
                    handleGoBackMenu: handleGoBackMenu,
                    handleCloseMenu: handleCloseMenu,
                };
    }

    return (
        <>
            <Container
                className={'sous-menu pl-6'}
                $blue={blue}
            >
                <Link to={`${prefix}`}>
                    <Logo
                        src={blue ? logo : bluelogo}
                        alt='Emrys Logo'
                        $isMobile={isMobile}
                    />
                </Link>
                {isMobile ?
                    <>
                        <MobileMenuButton onClick={toggleMenu}>
                            <img
                                src={blue ? MenuButtonIcon : MenuButtonBlueIcon}
                                alt='Menu'
                            />
                        </MobileMenuButton>
                        <Link to='/'>
                            <Logo
                                src={blue ? logo : bluelogo}
                                alt='Emrys Logo'
                                $isMobile={false}
                            />
                        </Link>
                    </>
                :   <Nav onMouseEnter={() => !isVisible && setisSubMenuOpen(true)}>
                        <ul>
                            {links.map((link, index) => (
                                <li
                                    key={index}
                                    onMouseEnter={() => !isVisible && handleMouseEnter(index, link.subMenu.length)}
                                >
                                    <CustomLink
                                        $blue={!blue}
                                        $activesubmenu={activeSubMenu === index}
                                        to={link.to}
                                        $issubmenuopen={isSubMenuOpen}
                                    >
                                        {link.label}
                                    </CustomLink>
                                </li>
                            ))}
                        </ul>
                    </Nav>
                }
                <IconContainer>
                    <img
                        src={blue ? searchWhite : searchBlue}
                        alt='searchWhite'
                    />
                    <img
                        src={blue ? notification : notificationBlue}
                        alt='Notification'
                    />
                    <BoxContainer>
                        <img
                            src={account}
                            alt='Account'
                            onClick={toggleBox}
                            ref={accountImgRef}
                        />
                        {isVisible && (
                            <BoxMenu
                                className='box'
                                ref={boxRef}
                            >
                                {isMobile && (
                                    <BoxHeaderMenu className='box'>
                                        <BoxHeaderContent>
                                            <img
                                                src={account}
                                                alt='Account'
                                            />
                                            <p> {t('base.menu.account')}</p>
                                        </BoxHeaderContent>

                                        <span>
                                            <img
                                                src={closeIcon}
                                                alt='closeIcon'
                                                onClick={toggleBox}
                                            />
                                        </span>
                                    </BoxHeaderMenu>
                                )}

                                <AccountMenu setIsVisible={setIsVisible} />
                            </BoxMenu>
                        )}
                        {isVisible && <Overlay $isOpen={isVisible} />}
                    </BoxContainer>

                    <CartIcon displayBlueIcon={!blue} />
                </IconContainer>
            </Container>

            {!isMobile && isSubMenuOpen && (
                <SubMenuStickyStyled>
                    <SubMenuStyle
                        onMouseLeave={() => {
                            setisSubMenuOpen(false);
                        }}
                    >
                        {renderSubMenu(subMenuProps as SubMenuDisplayitProps)}
                    </SubMenuStyle>
                </SubMenuStickyStyled>
            )}

            {isSubMenuOpen && !isMobile && Object.keys(subMenuProps).length != 0 && <Overlay />}
            {isMobile && (
                <>
                    <Menu $isOpen={isMenuOpen}>
                        <ContainerMobile>
                            <LeftIcon
                                src={HomeIcon}
                                alt='HomeIcon'
                            />
                            <MobileTitle>
                                <div dangerouslySetInnerHTML={{ __html: title }} />
                            </MobileTitle>
                            <RightIcon
                                src={closeIcon}
                                alt='closeIcon'
                                onClick={() => setIsMenuOpen(false)}
                            />
                        </ContainerMobile>
                        <UlMenu>
                            {activeSubMenu === null &&
                                links.map((link, index) => (
                                    <li
                                        key={index}
                                        onClick={() => handleMouseEnter(index, link.subMenu.length)}
                                    >
                                        <CustomLink
                                            $blue={true}
                                            to={link.to}
                                        >
                                            {link.label}
                                        </CustomLink>
                                        {link.arrow && (
                                            <ArrowIcon
                                                src={arrow}
                                                alt='arrow'
                                                $isSubMenu={false}
                                            />
                                        )}
                                    </li>
                                ))}
                        </UlMenu>
                        <SubMenuStyle>{renderSubMenu(subMenuProps as SubMenuDisplayitProps)}</SubMenuStyle>
                    </Menu>
                    <Overlay $isOpen={isMenuOpen} />
                </>
            )}
        </>
    );
}

export default MenuShared;
