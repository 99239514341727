import React from 'react';
import { useTheme } from 'styled-components';
import GuidLineDefinition from './GuidLineDefinition.tsx';
import GuidLineEllipse from './GuidLineEllipse.tsx';
import GuidlineButterfly from './GuidlineButterfly.tsx';
import GuidLineText from './GuidLineText.tsx';
import GuidlineNumber from './GuidlineNumber.tsx';
import GuidlineStep from './GuidlineStep.tsx';

const GuidlineDesktop: React.FC = () => {
    const theme = useTheme();

    return (
        <>
            <svg
                className='is-hidden-touch'
                width='98%'
                viewBox='0 0 1646 766'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                xmlnsXlink='http://www.w3.org/1999/xlink'
            >
                <GuidLineEllipse />
                <GuidlineButterfly />
                <GuidLineText />
                <GuidlineNumber />

                {Array.from({ length: 6 }, (_, index) => (
                    <GuidlineStep
                        stepNumber={index + 1}
                        key={index}
                    />
                ))}

                <path
                    d='M27.4996 603.552C125.731 511.688 154.263 430.001 285.573 400.5C412.51 371.981 510.821 522.592 637.884 495C772.009 465.875 774.86 297.125 908.986 268C1036.05 240.408 1123.88 388.503 1241.99 368C1360.09 347.497 1383.41 211 1427.9 154'
                    stroke={theme.colors.secondary}
                    strokeWidth='3'
                    strokeLinecap='round'
                    strokeDasharray='10 10'
                ></path>

                <GuidLineDefinition />
            </svg>
        </>
    );
};

export default GuidlineDesktop;
