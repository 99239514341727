import { AxiosInstance, AxiosError } from 'axios';
import AuthService from './AuthService';

const authService = new AuthService();

const setupAxiosInterceptors = (client: AxiosInstance) => {
    client.interceptors.response.use(
        response => response,
        (error: AxiosError) => {
            if (error.response && error.response.status === 401) {
                authService.logout();
            }
            return Promise.reject(error);
        },
    );
};

export default setupAxiosInterceptors;
