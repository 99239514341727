import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { EmrysThemeProviderProps } from '../Interfaces/EmrysThemeProviderInterface';
import { errorHandling } from './errorHandling';

const EmrysThemeProviderUi = React.lazy(() =>
    import('Ui/EmrysThemeProvider')
        .then(module => {
            return module;
        })
        .catch(errorHandling),
);

const EmrysThemeProvider: React.FC<EmrysThemeProviderProps> = ({ children, $usecustomcolors }) => (
    <ErrorBoundary fallback={<div></div>}>
        <Suspense fallback={<div></div>}>
            <EmrysThemeProviderUi $usecustomcolors={$usecustomcolors}>{children}</EmrysThemeProviderUi>
        </Suspense>
    </ErrorBoundary>
);

export default EmrysThemeProvider;
