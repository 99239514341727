import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';
import { CartIconProps } from '../Interfaces/CartIconInterface';

const CartIconUi = React.lazy(() => import('Cart/CartIcon').catch(errorHandling));

const CartIcon: React.FC<CartIconProps> = ({ displayBlueIcon }) => {
    return (
        <ErrorBoundary fallback={<div></div>}>
            <Suspense fallback={<div></div>}>
                <CartIconUi displayBlueIcon={displayBlueIcon} />
            </Suspense>
        </ErrorBoundary>
    );
};

export default CartIcon;
