import { SubMenuDisplayitProps } from '../../../../Interfaces/MenuInterface';
import SubMenu from './SubMenu';

const renderSubMenu = ({ subMenu, blue, isMobile, label, handleGoBackMenu, activeSubMenu, handleCloseMenu, accountMenu }: SubMenuDisplayitProps) => {
    if (!subMenu) return null;
    return (
        <SubMenu
            subMenu={subMenu}
            blue={blue}
            label={label}
            isMobile={isMobile}
            handleGoBackMenu={handleGoBackMenu}
            handleCloseMenu={handleCloseMenu}
            activeSubMenu={activeSubMenu}
            accountMenu={accountMenu}
        />
    );
};

export default renderSubMenu;
