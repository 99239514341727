import styled from 'styled-components';

export const OverlayStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, ${props => props.theme.colors.opacityMedium});
`;

export const OverlayGradiantStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 700px;
    background: linear-gradient(${props => props.theme.colors.secondary}, transparent);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
`;

export const ShopMenuOverlay = styled(OverlayGradiantStyled)`
    position: absolute;
    top: 333px;
`;

export const RouterStyle = styled.div`
    z-index: -2;
    position: relative;
`;

export const OutletContainer = styled.div`
    position: relative;
    padding-top: 10%;
    ${props => props.theme.devices.mobile} {
        padding-top: 30%;
    }
`;
