import React from 'react';
import { Container } from './HomePageNotLoggedStyle';
import EmrysIntro from '../EmrysIntro/EmrysIntro';
import Guidline from '../Guidline/Guidline';
import Figures from '../Figures/Figures';
import LanguageSwitcher from 'Translation/LanguageSwitcher';

const HomePageNotLogged: React.FC = () => {
    return (
        <Container>
            <div className='is-hidden'>
                <LanguageSwitcher />
            </div>
            <EmrysIntro />
            <Guidline />
            <Figures />
        </Container>
    );
};

export default HomePageNotLogged;
