import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import applePayImage from '@assets/Footer/payment/applePay.svg';
import googlePayImage from '@assets/Footer/payment/googlePay.svg';
import maestroImage from '@assets/Footer/payment/maestro.svg';
import VRImage from '@assets/Footer/payment/virementBancaire.svg';
import VisaImage from '@assets/Footer/payment/visa.svg';
import BancontactImage from '@assets/Footer/payment/Bancontact.svg';
import xMoneyImage from '@assets/Footer/payment/xMoney.svg';
import masterCardImage from '@assets/Footer/payment/mastercard.svg';
import fevadImage from '@assets/Footer/logos/fevad.svg';
import fnccImage from '@assets/Footer/logos/fncc.svg';
import GooglePlayImage from '@assets/Footer/applicationStores/GooglePlay.svg';
import AppStoreImage from '@assets/Footer/applicationStores/AppStore.svg';
import InstagramImage from '@assets/Footer/socialMedia/instagram.svg';
import FacebookImage from '@assets/Footer/socialMedia/facebook.svg';
import TikTokImage from '@assets/Footer/socialMedia/tiktok.svg';
import LinkedinImage from '@assets/Footer/socialMedia/linkedin.svg';
import YoutubeImage from '@assets/Footer/socialMedia/youtube.svg';
import GooglePlayMobileImage from '@assets/Footer/applicationStores/GooglePlayMobile.svg';
import AppStoreMobileImage from '@assets/Footer/applicationStores/AppStoreMobile.svg';
import { authService } from '../../../Services/Services.ts';
import { useRoutePrefix } from '../../../Context/RoutePrefixContext.tsx';

import { BigContainerProps, ImageMapperProps, LinkSectionProps } from './FooterInterface.tsx';
import {
    Arrow,
    CollapsibleContent,
    Container,
    ContainerLogo,
    FooterContainer,
    HeaderContainer,
    InnerPaymentContainer,
    InnerSocialContainer,
    LinkItem,
    ListContainer,
    MiddleContainer,
    StyledImage,
    Title,
} from './FooterStyle.tsx';
import { useDeviceDetect } from '../../../LoadModule/DeviceDetectLoader.tsx';
import React from 'react';
import { productPrefix } from 'Products/ProductPrefix';
import { useSharedTranslation } from 'Translation/useSharedTranslation';

const ImageMapper: React.FC<ImageMapperProps> = ({ images, type }) => (
    <>
        {images.map((image, index) =>
            image.href ?
                <a
                    href={image.href}
                    key={index}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='is-inline-block'
                >
                    <StyledImage
                        src={image.src}
                        alt={image.alt}
                        type={type}
                    />
                </a>
            :   <StyledImage
                    src={image.src}
                    alt={image.alt}
                    key={index}
                    type={type}
                />,
        )}
    </>
);

const LinkSection: React.FC<LinkSectionProps> = ({ links }) => (
    <ListContainer className='has-text-white  is-flex is-flex-direction-column is-justify-content-start'>
        {links.map((link, index) => (
            <LinkItem
                to={link.to}
                key={index}
                className='has-text-white'
            >
                {link.label}
            </LinkItem>
        ))}
    </ListContainer>
);

const BigContainer: React.FC<BigContainerProps> = ({ onClick, isOpen, showArrow, title, children, isSocialMedia = false, isAppStore = false }) => (
    <Container className='container column is-2 is-flex is-flex-direction-column is-justify-content-start'>
        <HeaderContainer onClick={onClick}>
            <Title
                $isSocialMedia={isSocialMedia}
                $isAppStore={isAppStore}
                className='title is-4 has-text-white is-flex is-justify-content-start is-flex-direction-column'
            >
                {title}
            </Title>
            {showArrow && <Arrow $isOpen={isOpen || false} />}
        </HeaderContainer>
        {children}
    </Container>
);

const Footer = (): React.ReactElement => {
    const { t } = useSharedTranslation();

    const prefix = useRoutePrefix();
    const { isMobile } = useDeviceDetect();

    const isLoggedIn = authService.isLogged();
    const [isQuickAccessOpen, setIsQuickAccessOpen] = useState(false);
    const [isHelpContactOpen, setIsHelpContactOpen] = useState(false);
    const [isPaymentMethodsOpen, setIsPaymentMethodsOpen] = useState(false);
    const [sectionOrder, setSectionOrder] = useState([1, 2, 3, 4, 5, 6]);

    const location = useLocation();
    const [footerPosition, setFooterPosition] = useState('relative');
    const observerRef = useRef<MutationObserver | null>(null);

    useEffect(() => {
        const checkContentHeight = () => {
            const bodyHeight = document.body.scrollHeight;
            const viewportHeight = window.innerHeight;
            if (bodyHeight >= viewportHeight) {
                setFooterPosition('relative');
            } else {
                setFooterPosition('fixed');
            }
        };

        observerRef.current = new MutationObserver(() => {
            checkContentHeight();
        });

        observerRef.current.observe(document.body, {
            childList: true,
            subtree: true,
            attributes: true,
            characterData: true,
        });

        window.addEventListener('resize', checkContentHeight);
        checkContentHeight();

        return () => {
            window.removeEventListener('resize', checkContentHeight);
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [location]);

    const quickAccessLinks =
        isLoggedIn ?
            [
                { to: `${prefix}${productPrefix}/shop`, label: t('base.footer.quickAccess.link.boutique') },
                { to: `${prefix}/myteams/sponsorship`, label: t('base.footer.quickAccess.link.parrainage') },
                { to: `${prefix}/associations`, label: t('base.footer.quickAccess.link.Associations') },
                { to: `${prefix}/trainings`, label: t('base.footer.quickAccess.link.Formations') },
                { to: `${prefix}`, label: t('base.footer.quickAccess.link.Carte') },
                { to: `${prefix}/emrys-brands`, label: t('base.footer.quickAccess.link.Marques') },
            ]
        :   [
                { to: '/public/Nos-enseignes-partenaires', label: t('base.footer.quickAccess.link.partenaires') },
                { to: '/public/Avantages', label: t('base.footer.quickAccess.link.avantages') },
                { to: '/public/Emrys-et Assos', label: t('base.footer.quickAccess.link.associations') },
                { to: '/public/Espace-presse', label: t('base.footer.quickAccess.link.presse') },
            ];

    const helpContactLinks =
        isLoggedIn ?
            [
                { to: '/documentation', label: t('base.footer.helpContact.link.documentation') },
                { to: '/contact', label: t('base.footer.helpContact.link.contact') },
                { to: '/Aide-Contact', label: t('base.footer.helpContact.link.aide') },
            ]
        :   [
                { to: '/contact', label: t('base.footer.helpContact.link.contact') },
                { to: '/Aide-Contact', label: t('base.footer.helpContact.link.aide') },
            ];

    const socialMediaLinks = [
        { href: 'https://www.instagram.com/emryslacarte_officiel/', src: InstagramImage, alt: 'Instagram' },
        { href: 'https://www.facebook.com/emryscard', src: FacebookImage, alt: 'Facebook' },
        { href: 'https://www.tiktok.com/@emryslacarte_officiel', src: TikTokImage, alt: 'TikTok' },
        { href: 'https://fr.linkedin.com/company/emrys-la-carte', src: LinkedinImage, alt: 'LinkedIn' },
        { href: 'https://www.youtube.com/channel/UCSprqy3g0fH9zBGcFcIWZwQ', src: YoutubeImage, alt: 'YouTube' },
    ];

    const paymentImages = [
        { src: masterCardImage, alt: 'MasterCard' },
        { src: maestroImage, alt: 'Maestro' },
        { src: VisaImage, alt: 'Visa' },
        { src: VRImage, alt: 'Virement Bancaire' },
        { src: BancontactImage, alt: 'Bancontact' },
        { src: googlePayImage, alt: 'Google Pay' },
        { src: applePayImage, alt: 'Apple Pay' },
        { src: xMoneyImage, alt: 'X Money' },
    ];

    const logos = [
        { src: fevadImage, alt: 'Fevad' },
        { src: fnccImage, alt: 'Fncc' },
    ];

    const appLinks = [
        {
            href: 'https://apps.apple.com/fr/app/emrys/id1671981200',
            src: isMobile ? AppStoreMobileImage : AppStoreImage,
            alt: 'App Store',
        },
        {
            href: 'https://play.google.com/store/apps/details?id=com.ogloba.gc.emrys.android',
            src: isMobile ? GooglePlayMobileImage : GooglePlayImage,
            alt: 'Google Play',
        },
    ];

    useEffect(() => {
        if (isMobile) {
            setSectionOrder(isLoggedIn ? [1, 2, 4, 5, 3, 6] : [1, 2, 3, 4, 6]);
        } else {
            setSectionOrder(isLoggedIn ? [1, 2, 3, 4, 5, 6] : [1, 2, 3, 4, 6]);
        }
    }, [isMobile, isLoggedIn]);

    const renderSection = (sectionId: number, isLoggedIn: boolean): React.ReactNode => {
        switch (sectionId) {
            case 1:
                return (
                    <BigContainer
                        key='quick-access'
                        title={t('base.footer.quickAccess.title')}
                        isOpen={isQuickAccessOpen}
                        onClick={() => setIsQuickAccessOpen(!isQuickAccessOpen)}
                        showArrow={isMobile ?? false}
                    >
                        <CollapsibleContent $isOpen={isMobile ? isQuickAccessOpen : true}>
                            <LinkSection links={quickAccessLinks} />
                        </CollapsibleContent>
                    </BigContainer>
                );
            case 2:
                return (
                    <BigContainer
                        key='help-contact'
                        title={t('base.footer.helpContact.title')}
                        isOpen={isHelpContactOpen}
                        onClick={() => setIsHelpContactOpen(!isHelpContactOpen)}
                        showArrow={isMobile ?? false}
                    >
                        <CollapsibleContent $isOpen={isMobile ? isHelpContactOpen : true}>
                            <LinkSection links={helpContactLinks} />
                        </CollapsibleContent>
                    </BigContainer>
                );
            case 3:
                return (
                    <BigContainer
                        key='social-media'
                        title={t('base.footer.socialMedia.title')}
                        isSocialMedia={true}
                    >
                        <MiddleContainer className='container column is-2 is-flex is-justify-content-start'>
                            <InnerSocialContainer
                                $isLoggedIn={isLoggedIn}
                                className='container column is-2 is-flex is-justify-content-center is-flex-wrap-wrap flex-gap'
                            >
                                <ImageMapper
                                    images={socialMediaLinks}
                                    type='socialMedia'
                                />
                            </InnerSocialContainer>
                        </MiddleContainer>
                    </BigContainer>
                );

            case 4:
                return (
                    <BigContainer
                        key='payment-methods'
                        title={t('base.footer.paymentMethods.title')}
                        isOpen={isPaymentMethodsOpen}
                        onClick={() => setIsPaymentMethodsOpen(!isPaymentMethodsOpen)}
                        showArrow={isMobile ?? false}
                    >
                        <CollapsibleContent $isOpen={isMobile ? isPaymentMethodsOpen : true}>
                            <MiddleContainer className='container column is-2 is-flex is-justify-content-start'>
                                <InnerPaymentContainer className='container column is-2 is-flex is-justify-content-start is-flex-wrap-wrap'>
                                    <ImageMapper
                                        images={paymentImages}
                                        type='payment'
                                    />
                                </InnerPaymentContainer>
                            </MiddleContainer>
                        </CollapsibleContent>
                    </BigContainer>
                );

            case 5:
                if (!isLoggedIn) return null;

                return (
                    <BigContainer
                        key='app-stores'
                        title={t('base.footer.appStores.title')}
                        isAppStore={true}
                    >
                        <MiddleContainer className='container column is-2 is-flex is-justify-content-start is-flex-direction-column flex-gap'>
                            <ImageMapper
                                images={appLinks}
                                type='appStores'
                            />
                        </MiddleContainer>
                    </BigContainer>
                );

            case 6:
                return (
                    <ContainerLogo
                        key='logos'
                        className='container column is-2 is-flex is-justify-content-center is-align-items-center is-flex-direction-column '
                    >
                        {logos.map((logo, index) => {
                            let style = {};
                            if (logo.alt === 'Fncc') {
                                style = { width: '145px', height: '81px', margin: '0 5px 0 0' };
                            } else if (logo.alt === 'Fevad') {
                                style = { width: '101px', height: '75px', margin: '0 5px 0 0' };
                            }

                            return (
                                <span key={index}>
                                    <img
                                        src={logo.src}
                                        alt={logo.alt}
                                        style={style}
                                    />
                                </span>
                            );
                        })}
                    </ContainerLogo>
                );
            default:
                return null;
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const bodyHeight = document.body.scrollHeight;
            const viewportHeight = window.innerHeight;

            if (bodyHeight >= viewportHeight) {
                setFooterPosition('relative');
            } else {
                setFooterPosition('fixed');
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [location]);

    return (
        <FooterContainer
            $position={footerPosition}
            className='footer has-background-primary'
        >
            <div className='content'>
                <div className='columns is-centered is-vcentered is-align-items-start'>
                    {sectionOrder.map(sectionId => renderSection(sectionId, isLoggedIn))}
                </div>
            </div>
        </FooterContainer>
    );
};

export default Footer;
