import React from 'react';
import { Description, GradientLayer, ImageContainer, SectionBloc, StyledImage, TextBloc, TitleSection } from '../../../../GlobalStyle/Section';
import { SectionProps } from '../../../../Interfaces/SectionInterface';

const Section: React.FC<SectionProps> = ({ imageUrl, title, description, to, isMobile }) => {
    return (
        <SectionBloc
            className='card ml-5 '
            to={to}
        >
            <div className='card-image pb-4'>
                <figure className='image '>
                    <ImageContainer>
                        <StyledImage
                            src={imageUrl}
                            alt='Placeholder image'
                        />
                        <GradientLayer />
                    </ImageContainer>
                </figure>
            </div>
            <TextBloc className='card-content '>
                <TitleSection className='mb-5'>{title}</TitleSection>
                <Description className={isMobile ? 'mt-4' : 'pb-5 mt-4'}>{description}</Description>
            </TextBloc>
        </SectionBloc>
    );
};

export default Section;
