import styled from 'styled-components';
import { ScrollButtonProps } from '../../../../Interfaces/EmrysIntroInterface';

export const IntroContainer = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 802px;
    margin: auto;
    ${props => props.theme.devices.mobile} {
        flex-direction: column;
        align-items: center;
        width: 80vw;
        height: 952px;
    }
`;

export const ImageSection = styled.div`
    position: absolute;
    height: 100%;
    width: 61vw;
    right: 0vw;
    ${props => props.theme.devices.mobile} {
        width: 431.5px;
        height: 223px;
        position: relative;
        margin-top: 200%;
        margin-bottom: 20px;
    }
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
`;

export const WhiteFade = styled.div`
    position: absolute;
    height: 100%;
    top: 10%;
    left: 0;
    width: 35%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
`;
export const ContentSection = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 20px;
    ${props => props.theme.devices.desktop} {
        padding: 50px 50px;
        gap: 64px;
        width: 42%;
    }
    ${props => props.theme.devices.mobile} {
        padding-top: 30px;
        align-items: center;
        gap: -4px;
        padding-left: 0px;
    }
`;

export const Title = styled.div`
    font-family: 'DM Sans', sans-serif;
    font-size: ${props => props.theme.globalSize.size1};
    font-weight: 500;
    line-height: 78px;
    letter-spacing: 0em;
    text-align: left;
    color: ${props => props.theme.colors.primary};
    ${props => props.theme.devices.desktop} {
        width: 661px;
        height: 156px;
        margin-bottom: 56px;
    }
    ${props => props.theme.devices.mobile} {
        width: 323px;
        height: 87px;
        padding: 3px, 28px, 0px, 0px;
        gap: 23px;
        font-family: ${props => props.theme.fonts.familySecondary};
        font-size: 32px;
        font-weight: 700;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
    }
`;
export const TitleContainer = styled.div`
    width: 45%;
    height: 21%;

    ${props => props.theme.devices.mobile} {
        height: 18%;
        width: 50%;
    }
`;

export const ScrollButton = styled.div<ScrollButtonProps>`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 91%;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 1000;
    width: 35px;
    height: 33.7px;
    border-radius: 50%;
    border: 1px solid #290180;
    img {
        width: 20px;
        height: 22.7px;
        max-width: fit-content !important;

        transition: filter 0.3s;

        filter: none;
    }
    &:hover img {
        filter: brightness(0) invert(1);
    }
    background-color: transparent;
    transition: background-color 0.3s;

    &:hover {
        background-color: #290180;
    }

    ${props => props.theme.devices.mobile} {
        width: 50%;
        display: none;
    }
`;

export const TextContainer = styled.div`
    width: 42.9vw;
    height: 352px;
    gap: 64px;
    ${props => props.theme.devices.mobile} {
        width: 80vw;
        height: 336px;
    }
    button {
        margin-top: 56px;
        ${props => props.theme.devices.mobile} {
            margin-top: -17px;
        }
    }
`;
export const Text = styled.p`
    margin-bottom: 30px;
    font-size: ${props => props.theme.globalSize.mobileSize2};
`;
