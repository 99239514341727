import moment from 'moment';
import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient();

type SessionToken = {
    customer: string;
    token: string;
    access_token: string;
    expiredAt: string;
};

export default class AuthService {
    async getToken(email: string, password: string): Promise<boolean> {
        const data = new URLSearchParams();
        data.append('grant_type', 'password');
        data.append('client_id', 'emrys');
        data.append('client_secret', import.meta.env.VITE_KEYCLOAK_CLIENT_SECRET);

        data.append('username', email);
        data.append('password', password);

        try {
            const response = await fetch(import.meta.env.VITE_URL_AUTHENTICATION, {
                method: 'POST',
                body: data,
                headers: new Headers({ 'content-type': 'application/x-www-form-urlencoded' }),
            });
            const result = await response.json();
            if (result?.access_token) {
                result.expiredAt = moment().add(1, 'h');
                result.token = result.access_token;
                sessionStorage.setItem('token', JSON.stringify(result));
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Authentication failed:', error);
            return false;
        }
    }

    isLogged() {
        const tokenStorage = sessionStorage.getItem('token');
        let token;

        if (tokenStorage) {
            token = JSON.parse(tokenStorage) as SessionToken;
        }

        return !!token && moment(token.expiredAt) > moment();
    }

    logout() {
        sessionStorage.clear();
        localStorage.clear();

        if (queryClient) {
            queryClient.clear();
            queryClient.invalidateQueries();
        }
    }
}
