import { ErrorBoundary } from 'react-error-boundary';
import { RoutePrefixProvider } from './Context/RoutePrefixContext';
import { RoutePrefixProviderCart } from './Context/RoutePrefixContextCart';
import Router from './Router/Router';

function App() {
    return (
        <ErrorBoundary fallback={<div></div>}>
            <ErrorBoundary fallback={<></>}>
                <RoutePrefixProvider>
                    <RoutePrefixProviderCart>
                        <Router />
                    </RoutePrefixProviderCart>
                </RoutePrefixProvider>
            </ErrorBoundary>
        </ErrorBoundary>
    );
}

export default App;
