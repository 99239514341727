import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useState, MouseEvent } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { OverlayStyled } from '../../../../GlobalStyle/Overlay.tsx';
import { NavbarStyled } from '../../../../GlobalStyle/Navbar.tsx';
import close from '@assets/icons/close.svg';
import hamburgerIcone from '@assets/icons/hamberger.svg';
import {
    Category,
    HeaderText,
    IconWrapper,
    MenuIcon,
    NavbarBrandStyled,
    NavItem,
    NavMenu,
    StyledLinkMobile,
    StyledLinkModified,
    SubCategory,
} from './NavbarNotLoggedStyle.tsx';
import Loader from '../../../../LoadModule/Loader.tsx';

function NavbarNotLoggedMobile() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [openDropdown, setOpenDropdown] = useState('');
    const [showAssociationsMenu, setShowAssociationsMenu] = useState(false);
    const navigate = useNavigate();

    const toggleMenuMobile = () => {
        setIsMenuOpen(!isMenuOpen);
        document.documentElement.classList.toggle('is-clipped');
    };

    const toggleTabMenu = (e: MouseEvent<HTMLLIElement>) => {
        const selector = e.currentTarget.getAttribute('data-open');
        const value = selector ?? '';
        setOpenDropdown(openDropdown === value ? '' : value);
    };

    const toggleAssociationsMenu = () => {
        setShowAssociationsMenu(!showAssociationsMenu);
    };

    const login = () => navigate(`public/login`);

    return (
        <>
            <NavbarStyled
                className='navbar has-shadow'
                role='navigation'
                aria-label='shop categories navigation'
            >
                <NavbarBrandStyled className='navbar-brand is-flex-direction-row-reverse'>
                    <span className='icon'>
                        <div className='navbar-item'>
                            <ErrorBoundary fallback={<Loader />}>
                                <figure
                                    className='image is-24x24'
                                    onClick={login}
                                >
                                    <img
                                        src='/UserCircleSingle.svg'
                                        alt='logo_user'
                                    />
                                </figure>
                            </ErrorBoundary>
                        </div>
                    </span>
                    <StyledLinkMobile
                        to='/'
                        className='navbar-item'
                    >
                        <img
                            className='navbar-brand-logo'
                            src='/logo.svg'
                            alt='logo_emrys'
                        />
                    </StyledLinkMobile>
                    <IconWrapper onClick={toggleMenuMobile}>
                        <MenuIcon
                            src={hamburgerIcone}
                            alt='Menu'
                        />
                    </IconWrapper>
                </NavbarBrandStyled>
                {isMenuOpen && (
                    <OverlayStyled
                        id='shop-menu-mobile-overlay'
                        className='overlay'
                        onClick={toggleMenuMobile}
                    />
                )}
                <NavMenu
                    id='shop-menu-mobile-container'
                    className={`navbar-menu nav-menu ${isMenuOpen ? 'is-active' : ''}`}
                >
                    <div className='header is-sticky has-shadow'>
                        <div className='is-flex is-align-items-flex-start'>
                            <div
                                className='icon'
                                onClick={() => toggleMenuMobile()}
                            >
                                <img
                                    src='/Home.svg'
                                    alt='House'
                                />
                            </div>
                            <HeaderText className='has-text-weight-bold'>
                                <StyledLinkMobile
                                    to={'/'}
                                    onClick={toggleMenuMobile}
                                >
                                    Accueil
                                </StyledLinkMobile>
                            </HeaderText>
                        </div>
                        <div
                            id='shop-menu-mobile-close'
                            className='icon-close'
                            onClick={toggleMenuMobile}
                        >
                            <img src={close} />
                        </div>
                    </div>
                    {showAssociationsMenu ?
                        <div className='body ml-4 mb-4 py-4 mr-4'>
                            <StyledLinkModified
                                to='#'
                                className='navbar-item py-4'
                                onClick={toggleAssociationsMenu}
                            >
                                <FontAwesomeIcon
                                    icon={'chevron-left'}
                                    className='mr-4'
                                />
                                Associations
                            </StyledLinkModified>
                            <div className='is-flex-direction-column columns'>
                                <Category className='py-2 ml-4   category'>Catégories</Category>
                                <div className='pb-4 ml-4 mt-4 mb-4 has-underlined'>
                                    <SubCategory>
                                        <StyledLinkMobile to='/EmrysetAssos'>Emrys et les assos</StyledLinkMobile>
                                    </SubCategory>
                                </div>
                                <div className='ml-4 mt-4'>
                                    <SubCategory>
                                        <StyledLinkMobile to='/JesuisAssociation'>Je suis une asso</StyledLinkMobile>
                                    </SubCategory>
                                </div>
                            </div>
                        </div>
                    :   <div className='body ml-4'>
                            <div className='block'>
                                <ul className='level-1'>
                                    <li
                                        className='drop-right is-flex is-justify-content-space-between is-align-items-center mb-6 mt-4'
                                        data-open='#boutique'
                                        onClick={toggleTabMenu}
                                    >
                                        <StyledLinkMobile
                                            to={'public/Nos-enseignes-partenaires'}
                                            onClick={toggleMenuMobile}
                                        >
                                            <NavItem className='bold '>Nos enseignes partenaires</NavItem>
                                        </StyledLinkMobile>
                                    </li>
                                    <li onClick={toggleMenuMobile}>
                                        <StyledLinkMobile to={'public/Avantages'}>
                                            <NavItem className='bold  mb-6'>Avantages</NavItem>
                                        </StyledLinkMobile>
                                    </li>
                                    <li
                                        className='drop-right is-flex is-justify-content-space-between is-align-items-center mb-6'
                                        data-open='#shop-menu-emrys-nutrition'
                                        onClick={toggleAssociationsMenu}
                                    >
                                        <StyledLinkMobile to={'#'}>
                                            <NavItem className='bold '>Associations</NavItem>
                                        </StyledLinkMobile>
                                        <FontAwesomeIcon
                                            className={'fa-icons mr-4'}
                                            icon={'chevron-right'}
                                        />
                                    </li>
                                    <li onClick={toggleMenuMobile}>
                                        <StyledLinkMobile to={'public/Espace-presse'}>
                                            <NavItem className='bold  mb-6'>Espace presse</NavItem>
                                        </StyledLinkMobile>
                                    </li>
                                    <li onClick={toggleMenuMobile}>
                                        <StyledLinkMobile to={'public/Aide-Contact'}>
                                            <NavItem className='bold  mb-6'>Aide & Contact</NavItem>
                                        </StyledLinkMobile>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }
                </NavMenu>
            </NavbarStyled>
        </>
    );
}

export default NavbarNotLoggedMobile;
