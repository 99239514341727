import { createContext, useContext, useState, FC } from 'react';
import { MenuContextType, MenuProviderProps } from '../Interfaces/MenuContextInterface';

const MenuContext = createContext<MenuContextType | null>(null);

export const useMenuContext = () => {
    const context = useContext(MenuContext);
    if (!context) {
        throw new Error('useMenuContext must be used within a MenuProvider');
    }
    return context;
};

export const MenuProvider: FC<MenuProviderProps> = ({ children }) => {
    const [isSubMenuOpen, setisSubMenuOpen] = useState<boolean>(false);

    return <MenuContext.Provider value={{ isSubMenuOpen, setisSubMenuOpen }}>{children}</MenuContext.Provider>;
};
