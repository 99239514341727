import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';
import { CartRoutePrefixContextProps } from '../Interfaces/CartRoutePrefixContextInterface';

const CartRoutePrefixContextUi = React.lazy(() => import('Cart/CartRoutePrefixContext').catch(errorHandling));

const CartRoutePrefixContext: React.FC<CartRoutePrefixContextProps> = ({ basePrefix, children }) => (
    <ErrorBoundary fallback={<div></div>}>
        <Suspense fallback={<div>.</div>}>
            <CartRoutePrefixContextUi basePrefix={basePrefix}>{children}</CartRoutePrefixContextUi>
        </Suspense>
    </ErrorBoundary>
);

export default CartRoutePrefixContext;
