import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    width: 358px;
    height: 480px;
    margin: 20px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    &:hover::before {
        background: linear-gradient(89.58deg, ${props => props.theme.colors.primary} 0.34%, ${props => props.theme.colors.secondary} 99.6%);
        box-shadow: inset 61px 36px 41px 50px rgb(177 177 177 / 17%);
    }

    &::before {
        content: '';
        position: absolute;
        top: 30px;
        left: 10px;
        width: 355px;
        height: 458px;
        border-radius: 7px;
        background: transparent;
        z-index: 0;
        box-shadow: none;
        transition: background 0.3s ease-in-out;
    }
    ${props => props.theme.devices.mobile} {
        &.visible::before {
            background: linear-gradient(89.58deg, ${props => props.theme.colors.primary} 0.34%, ${props => props.theme.colors.secondary} 99.6%);
            box-shadow: inset 61px 36px 41px 50px rgb(177 177 177 / 17%);
        }

        &:hover::before {
            background: linear-gradient(89.58deg, ${props => props.theme.colors.primary} 0.34%, ${props => props.theme.colors.secondary} 99.6%);
            box-shadow: inset 61px 36px 41px 50px rgb(177 177 177 / 17%);
        }

        &:hover {
            transform: translate(-5px, -5px);
        }
    }
`;

export const Bloc = styled.div`
    width: 358px;
    height: 480px;
    border-radius: 7px !important;
    display: flex !important;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 1;
    transition: transform 0.3s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    &:hover {
        transform: translate(-5px, -5px);
    }

    button {
        width: 116px;
        height: 29px;
        padding: unset;
        cursor: unset;
        align-self: center;
        &:focus {
            text-decoration: none !important;
        }
        &:hover {
            text-decoration: none !important;
        }
    }

    .giga {
        font-family: ${props => props.theme.fonts.familySecondary};
        font-size: ${props => props.theme.globalSize.size2};
        font-weight: 700;
        line-height: 36.46px;
        text-align: left;
        padding-top: 15px;
        padding-left: 20px;
        color: ${props => props.theme.colors.secondary};
        text-transform: uppercase;
    }

    .price {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px 0px 20px;
    }

    .packdesc {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-top: 10px;
        div {
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
                display: flex;
                flex-direction: column;
                margin-left: 15px;
                font-family: ${props => props.theme.fonts.familyPrimary};
                font-size: ${props => props.theme.globalSize.size6};
                font-weight: 400;
                line-height: 24px;
                text-align: left;

                strong {
                    font-family: ${props => props.theme.fonts.familyPrimary};
                    font-size: ${props => props.theme.globalSize.size6};
                    color: ${props => props.theme.colors.greyDark};
                    font-weight: 700;
                    line-height: 24px;
                    text-align: left;
                }
            }
        }
    }

    .priceoffre {
        display: flex;
        align-items: center;
        gap: 2px;

        span {
            font-family: ${props => props.theme.fonts.familySecondary};
            font-size: ${props => props.theme.globalSize.size1};
            font-weight: 500;
            line-height: 78px;
            text-align: left;
            color: ${props => props.theme.colors.primary};
        }

        div {
            .offre {
                font-family: ${props => props.theme.fonts.familySecondary};
                font-size: ${props => props.theme.globalSize.size6};
                font-weight: 700;
                line-height: 20.83px;
                text-align: left;
                color: ${props => props.theme.colors.primary};
            }
            .duration {
                font-family: ${props => props.theme.fonts.familyPrimary};
                font-size: ${props => props.theme.globalSize.size7};
                font-weight: 400;
                line-height: 18.2px;
                text-align: left;
                color: ${props => props.theme.colors.greyDark};
            }
        }
    }

    .iconoffre {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    hr {
        border: 1px solid;
        width: 286px;
        align-self: center;
        margin: 10px;
        border-width: 1px 0px 0px 0px;
        border-image-slice: 1;
        border-image-source: linear-gradient(270deg, ${props => props.theme.colors.primary} 12.59%, ${props => props.theme.colors.secondary} 100%);
    }

    .footerbutton {
        display: flex;
        position: absolute;
        flex-direction: row;
        bottom: 15px;
        gap: 40px;
        align-items: center;

        button {
            width: 138px;
            height: 40px;
            padding: unset;
            align-self: center;
            cursor: pointer;
            line-height: 0px;
        }
    }
`;
export const Blocpopup = styled.div`
    width: 803px;
    height: max-content;
    border-radius: 7px !important;
    display: flex !important;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;

    ${props => props.theme.devices.mobile} {
        width: 350px;
        height: max-content;
        padding: 10px 10px 10px 10px !important;
    }

    button {
        width: 116px;
        height: 29px;
        padding: unset;
        align-self: end;
        cursor: unset;
        margin-right: 22px;
    }

    div {
        display: flex;
        justify-content: center;
        ${props => props.theme.devices.mobile} {
            font-size: ${props => props.theme.globalSize.mobileSize1};
            font-weight: 300;
            margin-top: -10px;
            display: flex;
            justify-content: flex-end;
        }
        button {
            width: 116px;
            height: 40px;
            padding: unset;
            margin-right: 0;
            cursor: pointer;
            ${props => props.theme.devices.mobile} {
                width: 83px;
                height: 36px;
            }
        }
    }

    .priceoffre {
        display: flex;
        align-items: center;
        gap: 50px;
        height: 60px;
        padding: 10px;
        justify-content: flex-start;
        ${props => props.theme.devices.mobile} {
            justify-content: center;
            margin: -15px 0px 0px 0px;
        }
        .closebutton {
            background: transparent;
            border: none;
            font-size: ${props => props.theme.globalSize.size2};
            font-weight: 300;
            cursor: pointer;
            margin-left: auto;
            color: #333;
            align-self: center;

            &:hover {
                color: #000;
            }
        }
        div {
            .giga {
                font-family: ${props => props.theme.fonts.familySecondary};
                font-size: ${props => props.theme.globalSize.mobileSize1};
                font-weight: 700;
                line-height: 36.46px;
                text-align: left;

                color: ${props => props.theme.colors.greyLight};

                ${props => props.theme.devices.mobile} {
                    font-size: ${props => props.theme.globalSize.size3};
                }
            }

            .duration {
                font-family: ${props => props.theme.fonts.familyPrimary};
                font-size: ${props => props.theme.globalSize.size7};
                font-weight: 400;
                line-height: 18.2px;
                text-align: left;
                color: ${props => props.theme.colors.greyDark};
            }
            div {
                gap: 0;
            }
        }

        div {
            display: flex;
            align-items: center;
            gap: 50px;
            ${props => props.theme.devices.mobile} {
                gap: 100px;
                margin-top: 0px;
            }
            span {
                font-family: ${props => props.theme.fonts.familySecondary};
                font-size: ${props => props.theme.globalSize.mobileSize1};
                font-weight: 700;
                line-height: 78px;
                text-align: left;
                color: ${props => props.theme.colors.primary};
                ${props => props.theme.devices.mobile} {
                    font-size: ${props => props.theme.globalSize.size2};
                }
            }
        }
    }
    .packdesc {
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 10px 77px 30px 20px;
        align-items: flex-start;
        ${props => props.theme.devices.mobile} {
            padding: 30px 5px 0px 5px;
        }

        div {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            gap: 20px;

            img {
                width: 34px;
                height: 34px;
                flex-shrink: 0;
                ${props => props.theme.devices.mobile} {
                    width: 24px;
                    height: 24px;
                }
            }

            span {
                display: flex;
                flex-direction: column;
                font-family: ${props => props.theme.fonts.familyPrimary};
                font-size: ${props => props.theme.globalSize.size6};
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                ${props => props.theme.devices.mobile} {
                    font-family: ${props => props.theme.fonts.familyPrimary};
                    font-size: ${props => props.theme.globalSize.size7};
                    font-weight: 400;
                    line-height: 18.2px;
                    text-align: left;
                }

                strong {
                    font-family: ${props => props.theme.fonts.familyPrimary};
                    font-size: ${props => props.theme.globalSize.size6};
                    font-weight: 600;
                    line-height: 22.4px;
                    letter-spacing: 0.005em;
                    text-align: left;
                    ${props => props.theme.devices.mobile} {
                        font-family: ${props => props.theme.fonts.familyPrimary};
                        font-size: ${props => props.theme.globalSize.size7};
                        font-weight: 500;
                        line-height: 19.6px;
                        letter-spacing: 0.005em;
                        text-align: left;
                    }
                }
            }

            strong {
                font-family: ${props => props.theme.fonts.familySecondary};
                font-size: ${props => props.theme.globalSize.size8};
                font-weight: 700;
                line-height: 31.25px;
                text-align: left;
                color: #575756;
                ${props => props.theme.devices.mobile} {
                    font-size: ${props => props.theme.globalSize.size5};
                }
            }
        }
    }

    hr {
        border: 1px solid;
        width: 95%;
        align-self: center;
        border-width: 1px 0px 0px 0px;
        margin: 10px;
        border-image-slice: 1;
        border-image-source: linear-gradient(270deg, ${props => props.theme.colors.primary} 12.59%, ${props => props.theme.colors.secondary} 100%);
        ${props => props.theme.devices.mobile} {
            margin: 5px 0px 10px 0px;
        }
    }

    .footerbutton {
        display: flex;
        position: absolute;
        flex-direction: row;
        bottom: 15px;
        gap: 40px;
        align-items: center;

        button {
            width: 116px;
            height: 40px;
            padding: unset;
            align-self: center;
        }
    }
`;
export const SectionTitle = styled.div`
    .text {
        color: ${props => props.theme.colors.greyLight};
        font-family: ${props => props.theme.fonts.familySecondary};
        font-size: ${props => props.theme.globalSize.mobileSize1};
        font-weight: 700;
        line-height: 41.6px;
        text-align: left;
        padding-left: 40px;
        text-transform: uppercase;

        ${props => props.theme.devices.mobile} {
            font-size: ${props => props.theme.globalSize.size2};
            line-height: 36.46px;
            padding-left: 20px;
        }
    }

    .subtitle {
        color: ${props => props.theme.colors.secondary};
    }
`;

export const Pack = styled.div`
    display: flex;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-left: 20px;
    justify-content: space-evenly;
    cursor: grab;

    &::-webkit-scrollbar {
        display: none;
    }

    ${props => props.theme.devices.mobile} {
        flex-direction: column;
    }

    &.grabbing {
        cursor: grabbing;
    }
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(179.94deg, rgba(12, 192, 223, 0.6) 0.05%, rgba(178, 0, 128, 0.6) 94.56%);
    z-index: 1000;
`;

export const PopupContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;

    border-radius: 10px;
`;
