import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';

const LazyProductRouter = React.lazy(() => import('Products/ProductRouter').catch(errorHandling));

const ProductRouterLoader = () => (
    <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<div></div>}>
            <LazyProductRouter />
        </Suspense>
    </ErrorBoundary>
);

export default ProductRouterLoader;
