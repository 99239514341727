import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';
import { BannerProps, IncomingBanner } from '../Interfaces/BannerInterface';

const BannerUi = React.lazy(() => import('Ui/Banner').catch(errorHandling));

const Banner: React.FC<BannerProps> = ({ imageData }) => {
    const transformedBanners: IncomingBanner[] | undefined =
        imageData && imageData.length > 0 ?
            imageData.map(banner => ({
                id: banner.id,
                mediaUrl: banner.mediaUrl,
                path: banner.path,
                target: banner.target,
                type: banner.type,
                url: banner.url || null,
            }))
        :   undefined;

    return (
        <ErrorBoundary fallback={<div></div>}>
            <Suspense fallback={<div></div>}>
                {transformedBanners && transformedBanners.length > 0 ?
                    <BannerUi imageData={transformedBanners} />
                :   <div>No banners available.</div>}
            </Suspense>
        </ErrorBoundary>
    );
};

export default Banner;
