import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
    ArrowProps,
    BarHrProps,
    CommonSubMenuStylesProps,
    CustomContainerProps,
    CustomLinkProps,
    IconProps,
    LogoProps,
    MenuLineProps,
    MenuProps,
    MenuSharedStyleProps,
    SubMenuLabelProps,
    SubMenustyleProps,
    UlProps,
    UlSubLabelProps,
    UlSubMenuLiProps,
    UlSubMenuProps,
} from '../../../../Interfaces/MenuInterface';

export const Container = styled.div<CustomContainerProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    top: 50px;
    position: sticky;
    z-index: 15;
    width: 100%;
    box-shadow: 0px 9px 17px -9px ${props => props.theme.colors.greyDark};
    background: ${props => (props.$blue ? props.theme.colors.primary : props.theme.colors.white)};
    ${props => props.theme.devices.mobile} {
        box-shadow: none;
        top: 47px;
    }
`;

export const Overlay = styled.div<MenuProps>`
    ${props =>
        props.$isOpen &&
        `
        content: '';
        position: fixed;  
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props.theme.colors.greyDark};
        opacity: ${props.theme.colors.opacityMedium};
        z-index: -1;
   `}

    ${props => props.theme.devices.desktop} {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
        background-color: ${props => props.theme.colors.greyDark};
        opacity: ${props => props.theme.colors.opacityMedium};
        z-index: 2;
    }
`;
export const MainContainer = styled.div`
    width: 100%;
    z-index: 100;
    ${props => props.theme.devices.mobile} {
        position: unset;
    }
`;
export const CustomLink = styled(Link)<CustomLinkProps>`
    font-weight: 700;
    text-decoration: none;
    font-size: ${props => props.theme.globalSize.size4};
    line-height: 32.4px;
    ${props =>
        props.$issubmenuopen &&
        `
        ${Overlay} {
          display: block; 
        }
      `}

    font-family: ${props => props.theme.fonts.familySecondary};
    &:hover {
        color: ${props =>
            props.$activesubmenu && props.$blue ? props.theme.colors.primary
            : props.$blue ? props.theme.colors.greyDark
            : props.theme.colors.white};
    }
    ${props =>
        props.$activesubmenu &&
        `

        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 10px;
        outline: none;
        text-decoration-color: ${props.$blue ? props.theme.colors.primary : props.theme.colors.white};
    
    `}
    margin-right: ${props => props.theme.globalSize.mobileSize1};
    color: ${props =>
        props.$activesubmenu && props.$blue ? props.theme.colors.primary
        : props.$blue ? props.theme.colors.greyDark
        : props.theme.colors.white};
    cursor: pointer;

    ${props => props.theme.devices.tablet} {
        font-size: ${props => props.theme.globalSize.mobileSize6};
        margin-right: 11px;
    }

    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.size4};
        margin-right: 11px;
        &:hover {
            text-decoration: unset;
        }
    }
`;

export const CustomAccountLink = styled(Link)`
    display: inline;
    font-weight: 700;
    text-decoration: none;
    font-size: ${props => props.theme.globalSize.size5};
    line-height: 32.4px;

    font-family: ${props => props.theme.fonts.familySecondary};
    &:hover {
        color: ${props => props.theme.colors.greyDark};
    }

    margin-right: ${props => props.theme.globalSize.mobileSize1};
    color: ${props => props.theme.colors.greyDark};
    cursor: pointer;

    ${props => props.theme.devices.tablet} {
        font-size: ${props => props.theme.globalSize.mobileSize6};
        margin-right: 11px;
    }

    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.size5};
        margin-right: 11px;
        &:hover {
            text-decoration: unset;
        }
    }
`;

export const Menu = styled.nav<MenuProps>`
    display: ${props => (props.$isOpen ? 'block' : 'none')};
    z-index: 1000; 
    
    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    li {
            display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column ;
    }
    ${props => props.theme.devices.mobile} {
        display: block;
        position: fixed;
        top: 46px;
        left: 0;
        bottom: 0;
        width: 90%;
        transform: translateX(${props => (props.$isOpen ? '0' : '-100%')});
        transition: transform 0.3s ease; 
        background-color: ${props => props.theme.colors.white};
        margin-bottom: 0px; 
        padding-top: ${props => props.theme.globalSize.size1}; 
        overflow-y: auto; 
        max-height: 100%; 
        
        scrollbar-width: none; 
        
        -ms-overflow-style: none;
        
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            gap: 94px;
            margin-bottom: 30px;
          
    }
    }

    }
`;

export const MenuSharedStyle = styled.div<MenuSharedStyleProps>`
    display: ${props => (props.$isSubSubMenu ? 'none' : 'flex')};
    flex-direction: row;
    justify-content: center;
`;

export const Icon = styled.img<IconProps>`
    width: ${props => props.theme.globalSize.size5};
    height: ${props => props.theme.globalSize.size5};
    margin-right: 10px;
    margin-bottom: -4px;
`;

export const Logo = styled.img<LogoProps>`
    display: ${props => (props.$isMobile ? 'none' : 'block')};
    width: 75px;
    height: ${props => props.theme.globalSize.size1};
    cursor: pointer;
    ${props => props.theme.devices.mobile} {
        width: 53px;
        height: 42px;
    }
`;

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 13px;
    gap: ${props => props.theme.globalSize.mobileSize2};
    img {
        width: 32px;
        height: 32px;
        max-width: unset;
    }

    ${props => props.theme.devices.tablet} {
        img {
            width: ${props => props.theme.globalSize.size5};
            height: ${props => props.theme.globalSize.size5};
            margin-left: 0px;
        }
    }
    ${props => props.theme.devices.mobile} {
        img {
            width: ${props => props.theme.globalSize.mobileSize2};
            height: ${props => props.theme.globalSize.mobileSize2};
            margin-left: 0px;
        }
    }
`;
export const Nav = styled.nav`
    display: flex;

    ${props => props.theme.devices.mobile} {
        display: none;
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
    }

    li:last-child {
        margin-right: 0;
    }
`;

export const MobileTitle = styled.span`
    font-weight: 700;
    text-decoration: none;
    font-size: ${props => props.theme.globalSize.mobileSize2};
    font-family: ${props => props.theme.fonts.familySecondary};
    display: inline-table; 
    color: ${props => props.theme.colors.greyDark}
    margin-left: ${props => props.theme.globalSize.size5};
    inline-size: -webkit-fill-available;
    line-height: 1.2;
    
`;

export const LeftIcon = styled(Icon)`
    float: left;
    margin-left: ${props => props.theme.globalSize.size5};
    width: ${props => props.theme.globalSize.mobileSize2};
    height: ${props => props.theme.globalSize.mobileSize2};
`;

export const RightIcon = styled(Icon)`
    float: right;
    margin-right: ${props => props.theme.globalSize.size5};
`;

export const ArrowIcon = styled(Icon)<ArrowProps>`
    float: ${props => (props.$isSubMenu ? 'left' : 'right')};
    rotate: ${props => (props.$isSubMenu ? '180deg' : '0deg')};
    will-change: transform;
    margin-right: ${props => props.theme.globalSize.size5};
    width: ${props => props.theme.globalSize.mobileSize6} !important;
    height: ${props => props.theme.globalSize.mobileSize6} !important;
    margin-bottom: ${props => (props.$accountMenu ? '0' : '-4px')};
`;

export const ArrowAccountIcon = styled(Icon)<ArrowProps>`
    float: ${props => (props.$isSubMenu ? 'left' : 'right')};
    rotate: ${props => (props.$isSubMenu ? '180deg' : '0deg')};  $accountMenu
    will-change: transform;
    width: ${props => props.theme.globalSize.mobileSize6} !important;
    height: ${props => props.theme.globalSize.mobileSize6} !important;
    gap: 0px;
    opacity: 0px;
`;
export const ContainerMobile = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 4px ${props => props.theme.globalSize.size6} -6px ${props => props.theme.colors.greyDark};
    position: fixed;
    top: 0;
    width: 100%;
    background-color: ${props => props.theme.colors.white};
    padding: 10px;
    height: 60px;
    z-index: 1000;
`;

export const BoxContainer = styled.div`
    position: relative;
    display: flex;
    cursor: pointer;
`;

export const BoxMenu = styled.div`
    width: 340px;
    height: 457px;
    padding: 16px 24px 0px 24px;
    gap: 16px;
    border-radius: 6px 0px 0px 0px;
    opacity: 0px;
    background: ${props => props.theme.colors.white};
    box-shadow: 0px 6px 17px 0px #4d4d4d4d;
    position: absolute;
    top: 100%;
    right: 3%;
    margin-top: 43%;
    z-index: 3;
    ${props => props.theme.devices.mobile} {
        width: 95%;
        height: 100%;
        margin-top: 11%;
        position: fixed;
        padding: 85px 24px 85px 24px;
        top: 0;
        right: 0;
        z-index: 2;
    }
`;

export const BoxHeaderContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 18px;
    align-items: center;
`;
export const BoxHeaderMenu = styled.div`
    height: 60px;

    width: 95%;
    margin-top: 11%;
    position: fixed;
    display: flex !important;
    top: 0;
    right: 0;
    box-shadow: 0px 4px 20px 0px ${props => props.theme.colors.greyDark}40;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    img {
        width: 30px !important;
        height: 30px !important;
    }

    p {
        font-family: ${props => props.theme.fonts.familySecondary};
        font-size: ${props => props.theme.globalSize.size4};
        font-weight: 700;
        line-height: 26.04px;
        text-align: left;

        color: ${props => props.theme.colors.greyDark};
    }

    span {
        width: 16.89px;
        height: 0px;
        top: 2px;
        left: 2px;
        gap: 0px;
        border: 1.5px 0px 0px 0px;
        opacity: 0px;
        ${props => props.theme.devices.mobile} {
            img {
                width: 12px !important;
                height: 12px !important;
            }
        }
    }
`;

export const UlMenu = styled.div`
    line-height: 3;

    padding-top: 31px;
    list-style-type: none;

    ${props => props.theme.devices.mobile} {
        margin-left: 25px;
    }
`;

export const ProfileName = styled.div`
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.size6};
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    height: 33px;
    color: ${props => props.theme.colors.greyDark};
    width: max-content;
`;

export const RightColumn = styled.div`
    right: 13px;
    position: absolute;
`;

export const SectionInfo = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
`;

export const DegitInfo = styled.div`
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.mobileSize6};
    height: 25px;
    font-weight: 700;
    line-height: 14.06px;
    text-align: left;
    color: ${props => props.theme.colors.greyDark};
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
`;

export const Status = styled.div`
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-size: ${props => props.theme.globalSize.size7};
    font-weight: 400;
    line-height: 18.2px;
    text-align: left;
    color: ${props => props.theme.colors.greyDark};
    height: 23px;
`;

export const IconAccount = styled.img`
    width: ${props => props.theme.globalSize.size4} !important;
    height: ${props => props.theme.globalSize.size4} !important;
    gap: 10px;
    opacity: 0px;
    margin-right: 4px;
`;
export const Footer = styled.div`
    position: absolute;
    bottom: 17px;
    width: 88%;
    ${props => props.theme.devices.mobile} {
        position: fixed;
        bottom: 45px;
        width: 85%;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
`;
export const FooterSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    p {
        font-family: ${props => props.theme.fonts.familyPrimary};
        font-size: ${props => props.theme.globalSize.size7};
        font-weight: 500;
        line-height: 19.6px;
        letter-spacing: 0.005em;
        text-align: center;
        color: ${props => props.theme.colors.greyDark};
    }
`;

export const BarHr = styled.hr<BarHrProps>`
    width: 100%;
    height: 1px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    background-color: ${props => props.theme.colors.secondary};
    margin: ${props => (props.$down ? '0px 0px 14px 0px' : '-18px 0px 0px 0px')};
    ${props => props.theme.devices.mobile} {
        margin: -5px 0px 10px 0px;
    }
`;
export const HeaderSection = styled.div``;

export const UlAccountMenu = styled.div`
    line-height: 3;
    list-style-type: none;

    li {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
            background-color: ${props => `${props.theme.colors.secondaryLighter}30`};
            margin: 0px -24px;
            padding-left: 24px;
        }

        ${props => props.theme.devices.mobile} {
            height: 80px;
        }
    }
    li:hover > img {
        right: 24px;
        position: absolute;
    }
`;

export const UlSubMenu = styled.div<UlSubMenuProps>`
    display: ${props => (props.$accountMenu ? '' : 'flex')};
    flex-direction: row;
    line-height: 3;
    justify-content: ${props => (props.$accountMenu ? 'flex-start' : 'space-evenly')};
    list-style-type: none;
    padding: ${props => (props.$accountMenu ? 'unset' : '10px 160px 30px 160px')};
    ${props => props.theme.devices.mobile} {
        display: flex;
        flex-direction: column;
        line-height: 3;
        margin-left: ${props => (props.$accountMenu ? '0px' : '25px')};
        gap: 0px;
        padding: 0px;
        justify-content: center;

        list-style-type: none;

        li {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            gap: 0px;
        }
    }
`;

export const MenuImage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30%;
    gap: ${props => props.theme.globalSize.size4};
`;

export const MenuLine = styled.hr<MenuLineProps>`
    background-color: ${props =>
        !props.$accountMenu ?
            props.$isSubMenu ?
                props.theme.colors.secondary
            :   props.theme.colors.greyLight
        :   props.theme.colors.whiteLight};
    height: 1px;

    width: ${props => (props.$accountMenu ? '100%' : '88%')};

    margin: ${props =>
        !props.$accountMenu ?
            props.$isSubMenu ?
                `10px 0px ${props.theme.globalSize.size6} 25px`
            :   '10px 0px 10px 0px'
        :   '15px 0px 0px 0px'};
`;

export const CommonSubMenuStyles = styled.div<CommonSubMenuStylesProps>`
    margin-top: ${props => (props.$accountMenu ? '0px' : '5px')};
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: ${props => (props.$accountMenu ? props.theme.globalSize.mobileSize4 : props.theme.globalSize.mobileSize6)};
    line-height: ${props => (props.$accountMenu ? '56px' : '30px')};
    width: 100%;
    font-family: ${props => props.theme.fonts.familyPrimary};

    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.size7};

        gap: ${props => (props.$accountMenu ? '36px' : 'unset')};
        padding-top: ${props => (props.$accountMenu ? '15px' : 'unset')};
    }
`;

export const UlSubMenuItem = styled(CommonSubMenuStyles)``;

export const UlSubMenuLi = styled(Link)<UlSubMenuLiProps>`
    ${CommonSubMenuStyles};
    color: ${props => props.theme.colors.greyDark};
    &:hover {
        background-color: ${props => (props.$accountMenu ? `${props.theme.colors.secondaryLighter}30` : '')};
        margin: ${props => (props.$accountMenu ? '0px -24px' : '0')};
        padding-left: ${props => (props.$accountMenu ? '24px' : '0')};
    }
`;

export const DescStyle = styled.div`
    width: 21%;
`;

export const MobileMenuButton = styled.button`
    display: block;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 20px;
`;

export const SubMenuLabel = styled.div<SubMenuLabelProps>`
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    padding-top: ${props => (props.$accountMenu ? '13px' : 'unset')};
    margin-left: ${props => (props.$accountMenu ? 'unset' : '24px')};

    ${props => props.theme.devices.mobile} {
        padding-top: ${props => (props.$accountMenu ? '10px' : 'unset')};
    }
`;

export const PrimaryMenuStyle = styled.div`
    padding: 10px ${props => props.theme.globalSize.mobileSize2};
`;
export const SubMenuStyle = styled.div`
    ul {
        display: flex;
        list-style: none;
        padding: 0px;

        margin-bottom: 35px;
        flex-direction: row;
        gap: 63px;
        flex-wrap: nowrap;
        align-content: stretch;
        justify-content: center;
        align-items: baseline;
    }

    li {
        margin-right: 10px;
    }

    ${props => props.theme.devices.mobile} {
        ul {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            padding-top: 0px;
        }
    }

    ${props => props.theme.devices.desktop} {
        position: absolute;
        z-index: 10;
        width: 100%;
    }
`;

export const SubMenuStickyStyled = styled.div`
    ${props => props.theme.devices.desktop} {
        position: sticky;
        top: 130px;
        z-index: 10;
    }
`;

export const UlSubSubMenu = styled.div`
    ${props => props.theme.devices.mobile} {
        ul {
            display: flex;
            flex-flow: wrap;
            flex-direction: column;
            gap: 0px;
            margin-left: 26px;
            align-content: space-between;
            align-items: stretch;
        }
    }
`;

export const UlSubLabel = styled.div<UlSubLabelProps>`
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-weight: 700;
    font-size: ${props => props.theme.globalSize.size7};
    line-height: 18.2px;
    padding-bottom: ${props => (props.$accountMenu ? '1px' : '22px')};
`;

export const SubMenustyle = styled.div<SubMenustyleProps>`
    background-color: ${props => props.theme.colors.white};
`;

export const SubMenuPosition = styled.div`
    position: absolute;
    z-index: 3;
    box-shadow: 0px 0px ${props => props.theme.globalSize.size6} ${props => props.theme.colors.greyDark};
    background-color: ${props => props.theme.colors.white};
    width: 100%;

    ${props => props.theme.devices.mobile} {
        z-index: 0;
        box-shadow: 0px 8px 10px ${props => props.theme.colors.greyDark};
        box-sizing: border-box;
    }
`;

export const UlSubDesc = styled.p`
    font-family: ${props => props.theme.fonts.familyPrimary};
    font-weight: 400;
    font-size: ${props => props.theme.globalSize.mobileSize6};
    margin-top: ${props => props.theme.globalSize.mobileSize2};
    line-height: ${props => props.theme.globalSize.size6};
`;

export const UlLabel = styled.div<UlProps>`
    font-family: ${props => props.theme.fonts.familySecondary};
    font-weight: 700;
    font-size: ${props =>
        !props.$accountMenu ?
            props.$isMobile ?
                props.$isSubMenu && props.$isSubSubMenu ?
                    props.theme.globalSize.size5
                :   props.theme.globalSize.size4
            :   props.theme.globalSize.size5
        :   props.theme.globalSize.size5};

    line-height: 34px;

    color: ${props => (props.$activesubmenu ? props.theme.colors.primary : props.theme.colors.greyDark)};

    ${props =>
        props.$activesubmenu &&
        !props.$isMobile &&
        `
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 10px;
    outline: none;
    text-decoration-color: ${props.theme.colors.primary};

`}
`;

export const CustomLinkSubMenu = styled(Link)<CustomLinkProps>`
    font-weight: 700;
    text-decoration: none;
    font-size: ${props => props.theme.globalSize.size5};
    font-family: ${props => props.theme.familySecondary};

    &:focus {
        outline: none;
        text-decoration: none !important;
    }
    margin-right: ${props => props.theme.globalSize.mobileSize1};
    color: ${props => (props.$blue ? props.theme.colors.greyDark : props.theme.colors.white)};
    text-decoration: none;
    cursor: pointer;
    &:hover {
        color: ${props => (props.$blue ? props.theme.colors.greyDark : props.theme.colors.white)};
    }

    ${props => props.theme.devices.tablet} {
        font-size: ${props => props.theme.globalSize.mobileSize6};
        margin-right: 11px;
    }

    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.size4};
        margin-right: 11px;
    }
`;
