import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';

const LazyGiftCardSubMenu = React.lazy(() => import('Products/GiftCardSubMenu').catch(errorHandling));

const GiftCardSubMenuLoader = ({ basePrefix }: { basePrefix: string }) => (
    <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<div></div>}>
            <LazyGiftCardSubMenu basePrefix={basePrefix} />
        </Suspense>
    </ErrorBoundary>
);

export default GiftCardSubMenuLoader;
