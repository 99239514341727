import React from 'react';

import banner1 from '@assets/banner1.png';
import banner2 from '@assets/banner2.png';
import banner3 from '@assets/banner3.png';

import bannermobile1 from '@assets/bannermobile1.png';
import bannermobile2 from '@assets/bannermobile2.png';
import bannermobile3 from '@assets/bannermobile3.png';
import PackMobile from '../PackMobile/PackMobile';
import { Pack, SectionTitle } from '../PackMobile/PackMobileStyle';
import { useRef, useState } from 'react';
import { Guarantee } from '../Guarantee/Guarantee';
import { useMenuParams } from '../../../../Hooks/Constant';
import EmrysThemeProvider from '../../../../LoadModule/EmrysThemeProvider';
import Banner from '../../../../LoadModule/Banner';
import { useSharedTranslation } from 'Translation/useSharedTranslation';

const EmrysMobile = () => {
    const { t } = useSharedTranslation();
    const { CARDSMOBILEDATA } = useMenuParams();

    // const { banners, isLoading: bannerIsLoading } = useBanners('brand');  TO Do change bannersDemo with banners and change useBanners('brand')  with the correct locationName
    const bannersDemo = [
        {
            id: 1,
            mediaUrl: {
                sylius_large: banner1,
                sylius_medium: banner1,
                sylius_shop_banner_desktop: banner1,
                sylius_shop_banner_mobile: bannermobile1,
                sylius_small: bannermobile1,
            },
            path: '/banner/test-banner-666031c7cd673.png',
            target: '_blank',
            type: 'home',
            url: null,
        },
        {
            id: 2,
            mediaUrl: {
                sylius_large: banner2,
                sylius_medium: banner2,
                sylius_shop_banner_desktop: banner2,
                sylius_shop_banner_mobile: bannermobile2,
                sylius_small: bannermobile2,
            },
            path: '/banner/Group-1004-666c03b97ac0c.png',
            target: '_blank',
            type: 'home',
            url: null,
        },
        {
            id: 3,
            mediaUrl: {
                sylius_large: banner3,
                sylius_medium: banner3,
                sylius_shop_banner_desktop: banner3,
                sylius_shop_banner_mobile: bannermobile3,
                sylius_small: bannermobile3,
            },
            path: '/banner/Group-1004-666c03b97ac0c.png',
            target: '_blank',
            type: 'home',
            url: null,
        },
    ];

    const packMobile = [
        { pack: '100Go', subPack: '20Go', price: 17.99, credit: 1.26 },
        { pack: '150Go', subPack: '30Go', price: 22.99, credit: 1.61 },
        { pack: '20Go', subPack: '4Go', price: 10.99, credit: 0.77 },
        { pack: '50Go', subPack: '10Go', price: 12.99, credit: 0.91 },
    ];

    const scrollRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e: React.MouseEvent) => {
        if (!scrollRef.current) return;
        setIsDragging(true);
        setStartX(e.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging || !scrollRef.current) return;
        e.preventDefault();
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 3;
        scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const displayBanner = () => {
        // TO Do change bannersDemo with banners and change useBanners('brand')  with the correct locationName
        //if (bannerIsLoading) {
        //     return <Loader />;
        // }

        return (
            <>
                <div className='banner'>
                    <Banner imageData={bannersDemo} />
                </div>
                <EmrysThemeProvider $usecustomcolors='emrysmobile'>
                    <SectionTitle className='mt-6'>
                        <div className='text title'> {t('base.emrysmobile.firstTitle')}</div>
                        <div className='text subtitle'>{t('base.emrysmobile.secondTitle')}</div>
                    </SectionTitle>

                    <Pack
                        ref={scrollRef}
                        onMouseDown={handleMouseDown}
                        onMouseLeave={handleMouseLeave}
                        onMouseUp={handleMouseUp}
                        onMouseMove={handleMouseMove}
                        className={isDragging ? 'grabbing' : ''}
                    >
                        {packMobile.map((item, index) => (
                            <PackMobile
                                key={index}
                                pack={item.pack}
                                subPack={item.subPack}
                                price={item.price}
                                credit={item.credit}
                            />
                        ))}
                    </Pack>
                    <Guarantee object={CARDSMOBILEDATA} />
                </EmrysThemeProvider>
            </>
        );
    };

    return <div>{displayBanner()}</div>;
};

export default EmrysMobile;
