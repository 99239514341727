import React, { useState, useEffect } from 'react';

import arrow from '@assets/Menu/arrow.svg';
import {
    SubMenustyle,
    UlSubSubMenu,
    SubMenuLabel,
    ArrowIcon,
    UlLabel,
    MenuLine,
    MenuSharedStyle,
    UlSubMenu,
    UlSubLabel,
    UlSubMenuItem,
    UlSubMenuLi,
    MenuImage,
    DescStyle,
    UlSubDesc,
} from './MenuStyle';
import { SubMenuItem, SubMenuProps } from '../../../../Interfaces/MenuInterface';

const SubMenu: React.FC<SubMenuProps> = ({ subMenu, isMobile, label, handleGoBackMenu, handleCloseMenu, accountMenu }) => {
    const [activeSubSubMenu, setActiveSubSubMenu] = useState<string | null>(null);
    const [activeSubMenu, setActiveSubMenu] = useState<number | null>(isMobile ? null : 0);
    const [missingLabel, setMissingLabel] = useState<boolean>(false);

    const handleSubMenuClick = (index: number) => {
        setActiveSubMenu(activeSubMenu === index ? 0 : index);
    };

    const handleSubSubMenuClick = (label: string) => {
        setActiveSubSubMenu(activeSubSubMenu === label ? null : label);
    };

    const handleGoBack = () => {
        setActiveSubMenu(null);
    };

    useEffect(() => {
        if (subMenu && subMenu.length > 0 && typeof subMenu[0] !== 'string' && !subMenu[0]?.label) {
            setActiveSubMenu(0);
        }
    }, [subMenu]);

    useEffect(() => {
        if (subMenu && subMenu.length > 0) {
            const missingLabel = subMenu.some(item => typeof item !== 'string' && !item.label);
            if (missingLabel) {
                setMissingLabel(true);
            } else setMissingLabel(false);
        }
    }, [subMenu]);

    return (
        <SubMenustyle
            $accountMenu={accountMenu}
            className={'pt-4'}
        >
            {(isMobile && activeSubMenu === null) || !isMobile ?
                <UlSubSubMenu>
                    {isMobile && (
                        <>
                            <SubMenuLabel
                                onClick={() => {
                                    handleGoBackMenu();
                                }}
                            >
                                <ArrowIcon
                                    $isSubMenu={true}
                                    src={arrow}
                                    alt='arrow'
                                />
                                <UlLabel
                                    $isSubMenu={true}
                                    $isSubSubMenu={false}
                                    $isMobile={isMobile}
                                >
                                    {label}
                                </UlLabel>
                            </SubMenuLabel>

                            <MenuLine $isSubMenu={true} />
                        </>
                    )}
                    <MenuSharedStyle $isSubSubMenu={missingLabel}>
                        <ul>
                            {subMenu &&
                                subMenu.map((item, index) => (
                                    <li
                                        key={index}
                                        onMouseEnter={() => handleSubMenuClick(index)}
                                    >
                                        {typeof item === 'string' ?
                                            <span>{item}</span>
                                        :   <>
                                                <UlLabel
                                                    $isSubMenu={true}
                                                    $isSubSubMenu={false}
                                                    $isMobile={isMobile}
                                                    $activesubmenu={activeSubMenu === index}
                                                >
                                                    {item.label}
                                                </UlLabel>
                                                {isMobile && (
                                                    <ArrowIcon
                                                        $isSubMenu={false}
                                                        src={arrow}
                                                        alt='arrow'
                                                    />
                                                )}
                                            </>
                                        }
                                    </li>
                                ))}
                        </ul>
                    </MenuSharedStyle>
                </UlSubSubMenu>
            :   null}

            {subMenu &&
                subMenu.map((item, index) => (
                    <React.Fragment key={index}>
                        {activeSubMenu === index && typeof item !== 'string' && item.subSubMenu && (
                            <>
                                <SubMenuLabel
                                    $accountMenu={accountMenu}
                                    onClick={() => {
                                        if (item.label) {
                                            setActiveSubMenu(index);
                                            handleGoBack();
                                        } else {
                                            setActiveSubMenu(null);
                                            handleGoBackMenu();
                                        }
                                    }}
                                >
                                    {isMobile && (
                                        <ArrowIcon
                                            $isSubMenu={true}
                                            src={arrow}
                                            alt='arrow'
                                        />
                                    )}
                                    {item.label && isMobile ?
                                        <UlLabel
                                            $isSubMenu={true}
                                            $isSubSubMenu={true}
                                            $isMobile={isMobile}
                                        >
                                            {item.label}
                                        </UlLabel>
                                    :   <UlLabel
                                            $isSubMenu={true}
                                            $isSubSubMenu={false}
                                            $isMobile={isMobile}
                                            $accountMenu={accountMenu}
                                        >
                                            {label}
                                        </UlLabel>
                                    }
                                </SubMenuLabel>
                                {isMobile && (
                                    <MenuLine
                                        $isSubMenu={true}
                                        $accountMenu={accountMenu}
                                    />
                                )}
                                <UlSubMenu $accountMenu={accountMenu}>
                                    {item.subSubMenu.map((subItem, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <li>
                                                {typeof subItem === 'string' ?
                                                    <UlSubLabel>
                                                        <span>{subItem}</span>
                                                    </UlSubLabel>
                                                :   <UlSubLabel $accountMenu={accountMenu}>
                                                        <span onClick={() => handleSubSubMenuClick((subItem as SubMenuItem).label)}>
                                                            {(subItem as SubMenuItem).label}
                                                        </span>
                                                    </UlSubLabel>
                                                }
                                                {typeof subItem !== 'string' && (subItem as SubMenuItem).subsubSubMenu && (
                                                    <UlSubMenuItem $accountMenu={accountMenu}>
                                                        {(subItem as SubMenuItem).subsubSubMenu?.map(
                                                            (subsubItem: string | SubMenuItem, subsubIndex: number) => (
                                                                <React.Fragment key={subsubIndex}>
                                                                    {(subsubItem as SubMenuItem).to ?
                                                                        <UlSubMenuLi
                                                                            $accountMenu={accountMenu}
                                                                            to={(subsubItem as SubMenuItem).to}
                                                                            onClick={() => handleCloseMenu()}
                                                                        >
                                                                            <div>
                                                                                {typeof (subsubItem as SubMenuItem).name === 'string' ?
                                                                                    (subsubItem as SubMenuItem).name
                                                                                :   (subsubItem as React.ReactNode)}
                                                                            </div>
                                                                        </UlSubMenuLi>
                                                                    :   <div>
                                                                            {typeof (subsubItem as SubMenuItem).name === 'string' ?
                                                                                (subsubItem as SubMenuItem).name
                                                                            :   (subsubItem as React.ReactNode)}
                                                                        </div>
                                                                    }
                                                                    {isMobile && (subsubItem as SubMenuItem).name && !accountMenu && (
                                                                        <MenuLine $isSubMenu={false} />
                                                                    )}
                                                                </React.Fragment>
                                                            ),
                                                        )}
                                                    </UlSubMenuItem>
                                                )}
                                            </li>
                                        </React.Fragment>
                                    ))}

                                    {item.image && !isMobile && (
                                        <MenuImage>
                                            <img
                                                src={item.image}
                                                alt='image'
                                                width={253}
                                                height={149}
                                            />
                                            {!item.desc && <UlSubLabel>{item.text}</UlSubLabel>}
                                        </MenuImage>
                                    )}
                                    {item.desc && !isMobile && (
                                        <DescStyle>
                                            <UlSubLabel>{item.text}</UlSubLabel>
                                            <UlSubDesc>{item.desc}</UlSubDesc>
                                        </DescStyle>
                                    )}
                                </UlSubMenu>
                            </>
                        )}
                    </React.Fragment>
                ))}
        </SubMenustyle>
    );
};

export default SubMenu;
