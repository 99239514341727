import styled from 'styled-components';

export const IntroContainer = styled.div`
    padding: 50px 0;
    text-align: center;
    height: 1130px;
`;

export const MainTitle = styled.div`
    font-family: ${props => props.theme.fonts.familySecondary}, sans-serif;
    font-size: ${props => props.theme.globalSize.size2};
    font-weight: 700;
    line-height: 78px;
    color: ${props => props.theme.colors.primary};
    margin-bottom: 150px;
    ${props => props.theme.devices.mobile} {
        line-height: 36.4px;
        letter-spacing: 0em;
        margin-bottom: 30px;
    }
`;

export const CardsContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 220px;
    ${props => props.theme.devices.mobile} {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
`;

export const Card = styled.div`
    width: 258px;
    height: 258px;
    border-radius: 7.5px;
    background-color: white;
    color: black;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow:
            0 4px 5px rgba(0, 0, 0, 0.05),
            0 4px 25px rgba(0, 0, 0, 0.05),
            4px 0 4px rgba(0, 0, 0, 0.05),
            -4px 0 4px rgba(0, 0, 0, 0.05);
    }

    a {
        text-decoration: none;
        color: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const CardNumber = styled.div`
    font-family: ${props => props.theme.fonts.familySecondary}, sans-serif;
    font-size: ${props => props.theme.globalSize.size2};
    font-weight: 700;
    line-height: 36px;
    color: ${props => props.theme.colors.secondary};
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.mobileSize1};
        line-height: 41.6px;
    }
`;

export const CardText = styled.div`
    color: ${props => props.theme.colors.primary};
    font-family: ${props => props.theme.fonts.familySecondary}, sans-serif;
    font-size: ${props => props.theme.globalSize.mobileSize2};
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    ${props => props.theme.devices.mobile} {
        font-size: ${props => props.theme.globalSize.size5};
        line-height: 20.8px;
        font-weight: 700;
    }
`;

export const CardSmallText = styled.div`
    font-family: ${props => props.theme.fonts.familyPrimary}, sans-serif;
    font-weight: 400;
    text-align: center;
    font-size: 12px;
    line-height: 15.62px;
    text-decoration: underline;
    color: ${props => props.theme.colors.primary};
`;
