import React from 'react';
import styled from 'styled-components';
import { useSharedTranslation } from 'Translation/useSharedTranslation';
import { useDeviceDetect } from '../../../../../LoadModule/DeviceDetectLoader';

const Text = styled.text`
    fill: ${props => props.theme.colors.white};
    font-family: ${props => props.theme.fonts.familyPrimary}, sans-serif;
    font-size: ${props => props.theme.globalSize.size5};
    font-weight: 400;
    line-height: 24px;
`;

const GuidLineText: React.FC = () => {
    const { t } = useSharedTranslation();
    const { isMobile } = useDeviceDetect();

    const pathLineHeight = 20;
    const texts = [
        {
            x: 52,
            y: 705,
            content: t('base.menuNotLogged.emrysGuidline.titleCardOne'),
            isMobile: false,
        },
        {
            x: 328,
            y: 348,
            content: t('base.menuNotLogged.emrysGuidline.titleCardTwo'),
            isMobile: false,
        },
        {
            x: 945,
            y: 215,
            content: t('base.menuNotLogged.emrysGuidline.titleCardFour'),
            isMobile: false,
        },
        {
            x: 658,
            y: 631,
            content: t('base.menuNotLogged.emrysGuidline.titleCardThree'),
            isMobile: false,
        },
        {
            x: 1270,
            y: 467,
            content: t('base.menuNotLogged.emrysGuidline.titleCardFive'),
            isMobile: false,
        },
        {
            x: 1488,
            y: 135,
            content: t('base.menuNotLogged.emrysGuidline.titleCardSix'),
            isMobile: false,
        },
        {
            x: 42,
            y: 65,
            content: t('base.menuNotLogged.emrysGuidline.titleCardOne'),
            isMobile: true,
        },
        {
            x: 252,
            y: 243,
            content: t('base.menuNotLogged.emrysGuidline.titleCardTwoMobile'),
            isMobile: true,
        },
        {
            x: 252,
            y: 541,
            content: t('base.menuNotLogged.emrysGuidline.titleCardFourMobile'),
            isMobile: true,
        },
        {
            x: 42,
            y: 360,
            content: t('base.menuNotLogged.emrysGuidline.titleCardThreeMobile'),
            isMobile: true,
        },
        {
            x: 42,
            y: 707,
            content: t('base.menuNotLogged.emrysGuidline.titleCardFiveMobile'),
            isMobile: true,
        },
        {
            x: 115,
            y: 884,
            content: t('base.menuNotLogged.emrysGuidline.titleCardSixMobile'),
            isMobile: true,
        },
    ];

    return (
        <>
            {texts
                .filter(text => text.isMobile === isMobile)
                .map(text => {
                    return text.content.split('|').map((split: string, index: number) => (
                        <Text
                            x={text.x}
                            y={text.y + index * pathLineHeight}
                            key={index}
                        >
                            {split}
                        </Text>
                    ));
                })}
        </>
    );
};

export default GuidLineText;
