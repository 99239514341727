import { useCallback, useState } from 'react';
import arrow from '@assets/Menu/arrow.svg';
import emeryscoin from '@assets/icons/emeryscoin.svg';
import euro from '@assets/icons/euro.svg';
import langicon from '@assets/icons/langicon.svg';
import { authService } from '../../../../../Services/Services.ts';

import {
    ArrowAccountIcon,
    BarHr,
    CustomAccountLink,
    DegitInfo,
    Footer,
    FooterSection,
    HeaderSection,
    IconAccount,
    ProfileName,
    RightColumn,
    SectionInfo,
    Status,
    UlAccountMenu,
} from '../MenuStyle.tsx';
import { useMenuParams } from '../../../../../Hooks/Constant.ts';
import { useNavigate } from 'react-router-dom';
import { AccountMenuProps, EmptyObject, SubMenuDisplayitProps, SubMenuItem } from '../../../../../Interfaces/MenuInterface.tsx';
import renderSubMenu from '../RenderSubMenu.tsx';
import { useSharedTranslation } from 'Translation/useSharedTranslation';

const AccountMenu = ({ setIsVisible }: AccountMenuProps) => {
    const { ACCOUNT_MENU_PARAMS } = useMenuParams();
    const navigate = useNavigate();

    const menuParams = ACCOUNT_MENU_PARAMS;
    const [activeSubMenu, setActiveSubMenu] = useState<number | null>(null);
    const [isSubMenuOpen, setisSubMenuOpen] = useState(false);
    const { t } = useSharedTranslation();

    const handleMouseEnter = useCallback((index: number, length?: number) => {
        setActiveSubMenu(index);
        setisSubMenuOpen(length !== undefined && length !== 0);
        if (length === 0) {
            setIsVisible(false);
        }
    }, []);

    const handleGoBackMenu = useCallback(() => {
        setisSubMenuOpen(false);
    }, []);

    const handleCloseMenu = useCallback(() => {
        setisSubMenuOpen(true);
        setIsVisible(false);
    }, []);

    const handleDisconnection = useCallback(() => {
        authService.logout();
        navigate(``);
    }, []);

    let subMenuProps: SubMenuDisplayitProps | EmptyObject = {};

    if (activeSubMenu !== null) {
        subMenuProps = {
            subMenu: menuParams.links[activeSubMenu]?.subMenu as (string | SubMenuItem)[],
            label: menuParams.links[activeSubMenu]?.label,
            isMobile: true,
            handleGoBackMenu: handleGoBackMenu,
            handleCloseMenu: handleCloseMenu,
            activeSubMenu: activeSubMenu,
            accountMenu: true,
        };
    }

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
    };

    return (
        <>
            <HeaderSection>
                <SectionInfo className='columns'>
                    <div className='column is-three-fifths'>
                        <ProfileName>Isabelle Montmasson</ProfileName>
                        <Status>{t('base.menu.member')}: 1387</Status>
                        <Status> {t('base.menu.status')} : ACTIF</Status>
                    </div>
                    <RightColumn className='column '>
                        <DegitInfo>
                            <IconAccount src={emeryscoin} /> 5.56
                        </DegitInfo>
                        <DegitInfo>
                            <IconAccount src={euro} /> 100000,40 €
                        </DegitInfo>
                    </RightColumn>
                </SectionInfo>
                <BarHr />
            </HeaderSection>

            {isSubMenuOpen ? renderSubMenu(subMenuProps as SubMenuDisplayitProps) : null}

            {!isSubMenuOpen && (
                <UlAccountMenu>
                    {menuParams.links.map((link, index) => (
                        <li
                            key={index}
                            onClick={() => handleMouseEnter(index, link.subMenu.length)}
                        >
                            <CustomAccountLink
                                to={link.to}
                                onClick={link.arrow ? handleClick : undefined}
                            >
                                {link.label}
                            </CustomAccountLink>
                            {link.arrow && (
                                <ArrowAccountIcon
                                    src={arrow}
                                    alt='arrow'
                                    $isSubMenu={false}
                                />
                            )}
                        </li>
                    ))}
                </UlAccountMenu>
            )}

            <Footer>
                <BarHr $down />
                <FooterSection>
                    <IconAccount src={langicon} />
                    <p onClick={handleDisconnection}>{t('base.menu.disconnection')}</p>
                </FooterSection>
            </Footer>
        </>
    );
};

export default AccountMenu;
