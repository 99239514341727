import styled from 'styled-components';
import bigButterfly from '@assets/HomeNotLogged/butterfly_30.svg';

export const GuidlineStyled = styled.div`
    position: relative;
    padding-bottom: 50px;

    background-color: ${props => props.theme.colors.primary};
    background-image: url(${bigButterfly});
    background-repeat: no-repeat;
    background-position-x: -175px;
    background-position-y: 50%;

    ${props => props.theme.devices.mobile} {
        background-position-x: -160px;
        background-position-y: 375px;
        background-size: 445px;
    }

    ${props => props.theme.devices.tablet} {
        text-align: center;
    }

    h2 {
        font-family: ${props => props.theme.fonts.familySecondary}, sans-serif;
        font-size: ${props => props.theme.globalSize.size2} !important;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0;
        text-align: left;
        color: ${props => props.theme.colors.white};
        padding-top: 40px;
        z-index: 1;
        ${props => props.theme.devices.mobile} {
            font-family: ${props => props.theme.fonts.familySecondary};
            padding-top: 30px;
            letter-spacing: 0;
            text-align: center;
        }
    }
`;

export const HelpStar = styled.span`
    position: absolute;
    right: 25px;
    bottom: 25px;
    color: ${props => props.theme.colors.white};
    font-size: 14px;
    font-style: italic;
`;
