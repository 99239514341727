import React, { useRef, useState, useEffect } from 'react';
import { ScrollContainer } from '../../../../GlobalStyle/Section';
import { MapSectionProps } from '../../../../Interfaces/CooperativeInterface';

export const MapSection: React.FC<MapSectionProps> = ({ children }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            if (!isMouseDown || !containerRef.current) return;
            event.preventDefault();
            const x = (event as MouseEvent).pageX - containerRef.current.offsetLeft;
            const distance = x - startX;
            if (containerRef.current) {
                containerRef.current.scrollLeft = scrollLeft - distance;
            }
        };

        const handleMouseUp = () => {
            setIsMouseDown(false);
        };

        if (isMouseDown) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isMouseDown, startX, scrollLeft]);

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        setIsMouseDown(true);
        if (containerRef.current) {
            setStartX(event.pageX - containerRef.current.offsetLeft);
            setScrollLeft(containerRef.current.scrollLeft);
        }
    };

    return (
        <ScrollContainer
            ref={containerRef}
            onMouseDown={handleMouseDown}
            onMouseUp={() => setIsMouseDown(false)}
            onMouseLeave={() => setIsMouseDown(false)}
        >
            {children}
        </ScrollContainer>
    );
};
