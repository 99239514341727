import React from 'react';

const GuidlineButterfly: React.FC = () => {
    const butterflies = [
        {
            x: 625.984,
            y: 430,
            url: '#pattern0',
        },
        {
            x: 298.844,
            y: 423,
            url: '#pattern1',
        },
        {
            x: 11.4766,
            y: 530,
            url: '#pattern2',
        },
        {
            x: 918.984,
            y: 282,
            url: '#pattern3',
        },
        {
            x: 1239.79,
            y: 310,
            url: '#pattern4',
        },
        {
            x: 1430.96,
            y: 177,
            url: '#pattern5',
        },
    ];

    return (
        <>
            {butterflies.map((butterfly, index) => (
                <rect
                    x={butterfly.x}
                    y={butterfly.y}
                    width='40'
                    height='39.1133'
                    fill={`url(${butterfly.url})`}
                    key={index}
                />
            ))}
        </>
    );
};

export default GuidlineButterfly;
