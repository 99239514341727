import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';

const LazyEmrysNutritionMenu = React.lazy(() => import('Products/EmrysNutritionMenu').catch(errorHandling));

const EmrysNutritionMenuLoader = () => (
    <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<div></div>}>
            <LazyEmrysNutritionMenu />
        </Suspense>
    </ErrorBoundary>
);

export default EmrysNutritionMenuLoader;
