import { PageTitle, Container } from '../../../../GlobalStyle/Pages';
import OurPartnerBrands from 'Products/OurPartnerBrands';

const BrandsPartner = () => {
    return (
        <Container className='mt-6'>
            <PageTitle className='ml-5 px-5'>NOS ENSEIGNES PARTENAIRES</PageTitle>
            <OurPartnerBrands />
        </Container>
    );
};

export default BrandsPartner;
