import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';

const LazyEmrysEnergyMenu = React.lazy(() => import('Products/EmrysEnergyMenu').catch(errorHandling));

const EmrysEnergyMenuLoader = () => (
    <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<div></div>}>
            <LazyEmrysEnergyMenu />
        </Suspense>
    </ErrorBoundary>
);

export default EmrysEnergyMenuLoader;
