import { useMatch } from 'react-router-dom';
import { useRoutePrefix } from '../Context/RoutePrefixContext';

export const useExcludeRoute = () => {
    const prefix = useRoutePrefix();

    const excludePaymentRoute = !useMatch(`${prefix}/cart/payment`);

    return { excludePaymentRoute };
};
