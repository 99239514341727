import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { errorHandling } from './errorHandling';
import { CartStateProviderLoaderProps } from '../Interfaces/CartStateProviderLoaderInterface';

const LazyCartStateProvider = React.lazy(() => import('Cart/CartStateProvider').catch(errorHandling));

const CartStateProviderLoader: React.FC<CartStateProviderLoaderProps> = ({ children }) => (
    <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<div></div>}>
            <LazyCartStateProvider>{children}</LazyCartStateProvider>
        </Suspense>
    </ErrorBoundary>
);

export default CartStateProviderLoader;
