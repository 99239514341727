import React from 'react';
import { useTheme } from 'styled-components';

const GuidLineEllipse: React.FC = () => {
    const theme = useTheme();

    const ellipses = [
        {
            x: 15.3359,
            y: 590.295,
        },
        {
            x: 1421.74,
            y: 136,
        },
        {
            x: 1230.86,
            y: 357.312,
        },
        {
            x: 907.984,
            y: 254,
        },
        {
            x: 617.984,
            y: 485,
        },
        {
            x: 284.984,
            y: 388,
        },
    ];

    return (
        <>
            {ellipses.map((ellipse, index) => (
                <ellipse
                    cx='10.7109'
                    cy='11.0779'
                    rx='10.7109'
                    ry='11.0779'
                    transform={`matrix(1 0.000228114 0.000217867 1 ${ellipse.x} ${ellipse.y})`}
                    fill={theme.colors.secondary}
                    key={index}
                />
            ))}
        </>
    );
};

export default GuidLineEllipse;
