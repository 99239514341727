import styled from 'styled-components';
import { ContainerProps } from '../../Interfaces/LoggedRouteInterface';

export const Container = styled.div<ContainerProps>`
    background-color: ${props => props.color || 'initial'};
    background-image: url(${props => props.image || 'none'});
    background-repeat: no-repeat;
    background-position: right;
    ${props => props.theme.devices.mobile} {
        background-image: none;
    }
`;
