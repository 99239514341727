import React, { useState, useEffect, useRef } from 'react';
import EC from '@assets/icons/EC.svg';
import iconemrys from '@assets/icons/iconemrys.svg';
import iconphone from '@assets/icons/iconphone.svg';
import iconworld from '@assets/icons/iconworld.svg';
import { Wrapper, Bloc } from './PackMobileStyle';
import PopupPackMobile from './PopupPackMobile';
import { PackMobileProps } from '../../../../Interfaces/PackMobileInterface';
import Button from '../../../../LoadModule/Button';
import { useSharedTranslation } from 'Translation/useSharedTranslation';

const PackMobile: React.FC<PackMobileProps> = ({ pack, subPack, price, credit }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const cardRef = useRef<HTMLDivElement>(null);
    const { t } = useSharedTranslation();

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                const observer = new IntersectionObserver(
                    entries => {
                        entries.forEach(entry => {
                            if (entry.intersectionRatio === 1) {
                                cardRef.current?.classList.add('visible');
                            } else {
                                cardRef.current?.classList.remove('visible');
                            }
                        });
                    },
                    {
                        threshold: 1.0,
                    },
                );

                if (cardRef.current) {
                    observer.observe(cardRef.current);
                }

                return () => {
                    if (cardRef.current) {
                        observer.unobserve(cardRef.current);
                    }
                };
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePopupOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const scrollY = window.scrollY;
        setScrollPosition(scrollY);

        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollY}px`;
        document.body.style.width = '100%';

        setIsPopupOpen(true);
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);

        const scrollY = scrollPosition;
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        window.scrollTo(0, scrollY);
    };

    return (
        <>
            <Wrapper ref={cardRef}>
                <Bloc className='box'>
                    <Button
                        className='button'
                        hover={true}
                        active={true}
                        color={'White'}
                        isFocused={true}
                        hasBorder={true}
                        label={t('base.emrysmobile.secondTitle')}
                        size={'Small'}
                    />
                    <div className='giga'>{pack}</div>
                    <div className='price'>
                        <div className='priceoffre'>
                            <span>{Math.floor(price)}</span>
                            <div>
                                <div className='offre'>€ {price.toString().split('.')[1]}</div>
                                <div className='duration'> /{t('base.emrysmobile.month')}</div>
                            </div>
                        </div>

                        <div className='iconoffre'>
                            <img
                                src={EC}
                                alt='ec'
                            />
                            <div>{credit.toString().replace('.', ',')}</div>
                        </div>
                    </div>

                    <hr />

                    <div className='packdesc'>
                        <div>
                            <img
                                alt='iconworld'
                                src={iconworld}
                            />
                            <span>
                                {' '}
                                <strong>
                                    {pack} {t('cart.emrysmobile.fromfrance')}
                                </strong>{' '}
                                {subPack} {t('cart.emrysmobile.fromeurope')}
                            </span>
                        </div>
                        <div>
                            <img
                                alt='iconphone'
                                src={iconphone}
                            />
                            <span>
                                {' '}
                                <strong>{t('cart.emrysmobile.unlimitedcalls')} </strong> {t('cart.emrysmobile.metropolitanfrance')}
                            </span>
                        </div>
                        <div>
                            <img
                                alt='iconemrys'
                                src={iconemrys}
                            />
                            <span>
                                <strong>{t('cart.emrysmobile.emryscoinspaying')} </strong>
                                {t('cart.emrysmobile.availableemrysstore')}
                            </span>
                        </div>
                    </div>

                    <div className='footerbutton'>
                        <Button
                            className='button'
                            hover={true}
                            active={true}
                            chevron={'After'}
                            color={'White'}
                            isFocused={true}
                            hasBorder={false}
                            label={t('base.emrysmobile.moreDetails')}
                            size={'Medium'}
                            onClick={handlePopupOpen}
                        />

                        <Button
                            className='button '
                            hover={true}
                            active={true}
                            color={'Blue'}
                            isFocused={true}
                            hasBorder={false}
                            label={t('base.emrysmobile.subscribe')}
                            size={'Medium'}
                        />
                    </div>
                </Bloc>
            </Wrapper>

            {isPopupOpen && (
                <PopupPackMobile
                    pack={pack}
                    subPack={subPack}
                    price={price}
                    onClose={handlePopupClose}
                />
            )}
        </>
    );
};

export default PackMobile;
